import React from "react";
import "./Task.css";
import TaskBarContainer from "./TaskBarContainer";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import { B2PROSPECT_URL, B2_CDPAPI } from "../Constant";
import { useAuthState } from "@bamboobox/b2logincheck";



export function Task (props)  {
  const userData = useAuthState();
  const [loading,setLoading] = React.useState(true);
let tenantId = userData.user.tenantId;
  const [TaskData, setTaskData] = React.useState([]);
  const getCountApi = async () => {
    var temporaryTaskData = [
      {
        heading: "Tasks",
        tasks: [
          {
            label: "Pending Campaign Authorizations",
            value: "",
          },
          {
            label: "New Accounts with Intent",
            value: "",
          },
          
        ],
      },
    ];    
      await axios
      .get(
        `${B2_CDPAPI}/intent/new/accounts/count/BOMBORA/${tenantId}/${props.icpSelected}`,
        { withCredentials: true }
      )
      .then((intentNewAccount) => {
        // console.log(`%c${JSON.stringify(enrichment.data)}`, 'color: blue')
        temporaryTaskData[0].tasks[1].value = intentNewAccount.data.count;
        
      });
      await axios
      .get(
        `${B2_CDPAPI}/campaignmanager/count/all/${tenantId}`,
        { withCredentials: true }
      )
      .then((pendingRequest) => {
        // console.log(`%c${JSON.stringify(enrichment.data)}`, 'color: blue')
        temporaryTaskData[0].tasks[0].value = pendingRequest.data.count;
        
      });
    setLoading(false)
    setTaskData(temporaryTaskData);
  };

  React.useEffect(() => {
    getCountApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
    {loading? (<div style={{textAlign:"center",padding:"30px"}}><CircularProgress style={{color:'#21DA8C'}} size="20px"/></div>):
      ( 
      
      <div
      
        
        style={{
          backgroundColor: "rgba(0, 0, 0, 0)",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          paddingBottom:"18px"
        }}
      >
        {Array.isArray(TaskData) && !TaskData ? (
          <React.Fragment>Loading...</React.Fragment>
        ) : (
          
          TaskData.map((TaskMapper, index) => {
            console.log("task", TaskMapper);
            return <TaskBarContainer TaskMapper={TaskMapper} reqBody={props.requestBody} icpSelected={props.icpSelected} setIntentNewAccount={props.setIntentNewAccount} intentNewAccount={props.intentNewAccount} />;
          })
        )}
      </div>
      )
  }
    </div>
  );
};


