


// export const LOGIN_URL = 'http://login.bamboobox.in';
// export const AUTH_URL = 'http://loginapi.bamboobox.in/authorize/cookie';
// export const ATTRIBUTE_URL="http://b2metadataapi.bamboobox.in/attribute-setup";
// export const FILTER_URL="http://b2filtersaapi.bamboobox.in/filter";
// export const ICP_URL="http://b2scoringapi.bamboobox.in";
// export const B2PROSPECT_URL="http://b2prospectapi.bamboobox.in/b2pm";
// export const UNIFIED_URL="http://b2unifiedprofileapi.bamboobox.in";



export const LOGIN_URL = window._env_.LOGIN_URL;
export const AUTH_URL = window._env_.AUTH_URL;
export const ATTRIBUTE_URL=window._env_.ATTRIBUTE_URL;
export const FILTER_URL=window._env_.FILTER_URL;
export const ICP_URL=window._env_.ICP_URL;
export const CDP_URL=window._env_.CDP_URL;
export const B2PROSPECT_URL=window._env_.B2PROSPECT_URL;
export const UNIFIED_URL=window._env_.UNIFIED_URL;
export const UNIFIED_FRONTEND_URL=window._env_.UNIFIED_FRONTEND_URL;
export const LOGIN_API_URL= window._env_.LOGIN_API_URL;
export const B2ENRICHMENT_URL=window._env_.B2ENRICHMENT_URL;
export const B2CAMPAIGN_URL=window._env_.B2CAMPAIGN_URL;
export const B2FRONTEND_PROSPECT_URL=window._env_.B2FRONTEND_PROSPECT_URL;
export const META_BASE_URL = window._env_.META_BASE_URL;
export const B2_WEBFORMFILL_FRONTEND = window._env_.B2_WEBFORMFILL_FRONTEND;
export const B2_CDPAPI = window._env_.B2_CDPAPI
export const ENG_SCR_BASE_URL=window._env_.ENG_SCR_BASE_URL;
export const INTENT_TOPICS_URL=window._env_.INTENT_BACKEND;
export const LICENSE_STRING= window._env_.LICENSE_STRING;
export const B2_INTENT_SETTING=window._env_.B2_INTENT_SETTING;
export const TENANT_CONFIG=window._env_.TENANT_CONFIG;
export const AGGREGATOR_API= window._env_.AGGREGATOR_API;

