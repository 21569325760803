import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { ATTRIBUTE_URL } from "./Constant";
import { useAuthState } from "@bamboobox/b2logincheck";
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import { useEffect } from "react";
import { useState } from "react";
import { set } from "date-fns";
// import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root:{
        color:"#8A8A8A"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    icon: {
        fill: '#21DA8C',
        fontSize: '28px'
    },
    select: {
        outline: "none",
        height: "42px !important",
        marginBottom:"2px !important"
    },
  selected: {
    borderRadius:"16px",
    background:"#d2f8d2 !important" 
},
listItem:{
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    /* identical to box height */
    color: '#8A8A8A',
    fontFamily:'Nunito Sans'
    }
}));





const Attribute = (props) => {
    const classes = useStyles();
    // console.log("stageValue",props.stageValue)
    const [depts,setDepts] = useState()
    const [department, setDepartment] = React.useState([]);
    const [attribute, setAttribute] = React.useState();
    const userDetails = useAuthState();
    const tenantId = userDetails.user.tenantId;
    const handleChange = (event) => {
        // let AttributeLabel = props.department.find(o => o === event.target.value);
        setAttribute(department[event.target.value])
        props.setStageValue(department[event.target.value])
        console.log("props",props.stageValue,event);
    };

    const fetchAttributeValues = async() => {
        try {
    
          const tenantConfigResponse = await fetch(`${window._env_.TENANT_CONFIG}/tenant/field/mapper/get/${tenantId}/Journey-Analytics`,{credentials:"include"});
          const mappings_data = await tenantConfigResponse.json();
          return mappings_data.AllDepartments;
    
        }
        catch (err) {
          console.log("error",err);
        }
      }

    //   useEffect(() => {
    //     fetchAttributeValues();
    //   },[depts]);

  
    const Department = async ()=>{
        let customAllDeps = await fetchAttributeValues();
        fetch(`${ATTRIBUTE_URL}/getattribute?parentattribute=Department&&tenantId=${tenantId}`,{credentials :"include"})
        .then((res)=>res.json())
        .then((data)=>{
            if(data.length>0){
            const departmentList = data.map(item => item.parent_value)
            props.setAlldepartment(departmentList);
            let allDepts = [customAllDeps];
            allDepts = allDepts.concat(departmentList)
            setDepartment(allDepts);
            props.setStageValue(allDepts[0])
            }
            else{
                setDepartment([]);
                props.setStageValue('');
            }
        })
    }
    React.useEffect(()=>{
        if(department.length==0){
    Department()}
    },[depts])
    return (
        <FormControl variant="outlined" className={classes.formControl}>
            {/* <InputLabel id="demo-simple-select-outlined-label"></InputLabel> */}
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={attribute}
                className={classes.select}
                inputProps={{
                    classes: {
                        icon: classes.icon,
                        root: classes.root,
                    },
                }}
                IconComponent ={ExpandMoreOutlinedIcon}
                onChange={handleChange}
                defaultValue={0}
                style={{width:"180px",borderRadius:"4px"}}
                MenuProps={
                    
                       {
                        PaperProps: {
                            style: {
                              borderRadius: "16px"
                            }
                          },
                         getContentAnchorEl: () => null,
                         anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                          transformOrigin: { vertical: 'top', horizontal: 'center' },

                           }
                           
                        } 
            >
                {department.map((attrList,key) => (
                    <MenuItem value={key} className={classes.listItem} classes={{ selected: classes.selected }}>{attrList}</MenuItem>
                ))
                }
            </Select>
            
        </FormControl>
    );
};

export default Attribute;
