import React from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import StageAgingSelector from "./StageAgingSelector";
import { Typography } from "@material-ui/core";
import { UNIFIED_FRONTEND_URL, LICENSE_STRING } from "./Constant";
import CryptoJS from "crypto-js";
import moment from "moment";
import { Skeleton } from "@mui/material";

if (LICENSE_STRING != "") {
  var _0x49bc0e = _0x298e;
  function _0x298e(_0x7d727b, _0x38966f) {
    var _0x1b8773 = _0x1b87();
    return (
      (_0x298e = function (_0x298eb3, _0x1414f4) {
        _0x298eb3 = _0x298eb3 - 0x1ea;
        var _0x32f714 = _0x1b8773[_0x298eb3];
        return _0x32f714;
      }),
      _0x298e(_0x7d727b, _0x38966f)
    );
  }
  (function (_0x5c9ab4, _0x59221e) {
    var _0xe05717 = _0x298e,
      _0x550b93 = _0x5c9ab4();
    while (!![]) {
      try {
        var _0x3a14a4 =
          parseInt(_0xe05717(0x1f4)) / 0x1 +
          parseInt(_0xe05717(0x1f7)) / 0x2 +
          parseInt(_0xe05717(0x1ee)) / 0x3 +
          -parseInt(_0xe05717(0x1ea)) / 0x4 +
          (parseInt(_0xe05717(0x1eb)) / 0x5) *
            (parseInt(_0xe05717(0x1ec)) / 0x6) +
          (parseInt(_0xe05717(0x1f0)) / 0x7) *
            (-parseInt(_0xe05717(0x1ef)) / 0x8) +
          (-parseInt(_0xe05717(0x1f6)) / 0x9) *
            (-parseInt(_0xe05717(0x1f5)) / 0xa);
        if (_0x3a14a4 === _0x59221e) break;
        else _0x550b93["push"](_0x550b93["shift"]());
      } catch (_0x3a3581) {
        _0x550b93["push"](_0x550b93["shift"]());
      }
    }
  })(_0x1b87, 0xed863);
  function _0x1b87() {
    var _0x3cdb73 = [
      "Utf8",
      "b2\x20cdp\x20uses\x20fustion\x20chart",
      "169152xDHSeA",
      "30SRtDAJ",
      "2786688ScKRos",
      "1604846jQeLkv",
      "license",
      "options",
      "3060620rKUxON",
      "3982685QzwgIi",
      "6quDyMF",
      "enc",
      "1686396OqHHUo",
      "1738384mMIoTV",
      "49NMgycc",
      "AES",
    ];
    _0x1b87 = function () {
      return _0x3cdb73;
    };
    return _0x1b87();
  }
  var oooo0000 = CryptoJS[_0x49bc0e(0x1f1)]
    ["decrypt"](LICENSE_STRING, _0x49bc0e(0x1f3))
    ["toString"](CryptoJS[_0x49bc0e(0x1ed)][_0x49bc0e(0x1f2)]);
  FusionCharts[_0x49bc0e(0x1f9)][_0x49bc0e(0x1f8)]({
    key: oooo0000,
    creditLabel: ![],
  });
}
// import { UNIFIED_FRONTEND_URL } from "./Constant";

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

const AttributeList = [
  "Unreached",
  "Unengaged",
  "Aware",
  "Engaged",
  "Potential Opportunity",
  "Active Opportunity",
];

const dummyData = [
  {
    label: "0-15 days",
    value: "",
  },
  {
    label: "16-30 days",
    value: "",
  },
  {
    label: "31-45 days",
    value: "",
  },
  {
    label: "46-60 days",
    value: "",
  },
  {
    label: "61-75 days",
    value: "",
  },
  {
    label: "Others",
    value: "",
  },
];

function generateLinkForStageAgeingChart(
  rangeString,
  todaysDateUTC,
  currentFilterBody,
  currentStage,
  icp,
  segmentName,
  tabVal,
  stageValue
) {
  let listOfDays = rangeString.split("-");
  let filterObj = {
    attribute: `stageChangedDate`,
    type: "accounts",
    relation: "between",
    values: [],
  };

  let filterObj_stage = {
    attribute: `b2AccountStage`,
    type: "accounts",
    relation: "includes",
    values: [currentStage],
  };

  let dynamicFilterObj = [...currentFilterBody];
  console.log(
    "ColumnChart result for loop ",
    listOfDays,
    listOfDays?.length,
    listOfDays?.length >= 2
  );
  if (listOfDays?.length >= 2) {
    for (let i = listOfDays.length - 1; i >= 0; i--) {
      filterObj.values.push(
        moment(todaysDateUTC).subtract(parseInt(listOfDays[i]), "days")
      );
    }
    filterObj.values[1] = moment(filterObj.values[1]).add(1, "days") // include current date also
    dynamicFilterObj.push(filterObj_stage);
    dynamicFilterObj.push(filterObj);
  }
  let link = `${UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(
    JSON.stringify(dynamicFilterObj)
  ))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(JSON.stringify(currentFilterBody)))}?icp=${
    icp || ""
  }&segmentName=${segmentName}&tabVal=${tabVal}&attribute=${stageValue}`;
  return link;
}

const ColumnChart = (props) => {
  const [chartConfigs, setChartConfigs] = React.useState([]);
  const [isLoading, setisLoading] = React.useState(false);
  // const [stageValue, setStageValue] = React.useState(AttributeList[0])
  let stageValue = props.stageValue;
  const getInitialChartData = () => {
    // This is for the setting the initial chart data
    var result = Array.isArray(props.stageData) ? props.stageData.find((obj) => {
      return obj['accountStage'] === stageValue;
    }) : {};
    var chartData = [];
    if (result !== undefined) {
      console.log("ColumnChart result output", result);
      result?.range?.forEach((res) => {
        let stageDataObject = {
          label: "",
          value: "",
          tooltext: "",
          link: "",
        };
        let todaysDate = new Date();
        let utcDateValue = new Date(
          Date.UTC(
            todaysDate.getFullYear(),
            todaysDate.getMonth(),
            todaysDate.getDate()
          )
        );

        stageDataObject.link = generateLinkForStageAgeingChart(
          res.range,
          utcDateValue,
          props.reqBody,
          stageValue,
          props.icp,
          props.segmentName,
          props.tabVal,
          props.stageValue
        );
        stageDataObject.label = `${res.range} days`;
        stageDataObject.value = res.count;
        stageDataObject.tooltext = `<div style="padding: 5px">${
          res.count > 1 ? "Accounts" : "Account"
        } <br><b>${
          res.count
        }</b><br><br>Stage<br><b> ${stageValue}</b><br><br>Since<br><b>${
          res.range
        }</b> days</div>`;
        console.log("ColumnChart result stageDataObject", stageDataObject);
        chartData.push(stageDataObject);
      });
    } else {
      chartData = dummyData;
    }
    const chartConfigsObject = {
      type: "column2d",
      width: "100%",
      height: "380",
      dataFormat: "json",
      dataSource: {
        chart: {
          alignCaptionWithCanvas: 0,
          palette: 1,
          paletteColors: "#21DA8C",
          xAxisName: "DAY RANGE",
          yAxisName: "NUMBER OF ACCOUNTS",
          plotSpacePercent: "100",
          hasRTLText: 1,
          theme: "fusion",
          usePlotGradientColor: "1",
          plotGradientColor: "#53F9B2",
          plotFillAngle: "90",
          plotFillAlpha: "100",
          plotFillRatio: "0,100",
          showBorder: "1",
          showValues: "1",
          placeValuesInside: "0",
          // useRoundedEdges: "1",
          borderThickness: "0",
          //  "valueFont": "Proxima Nova",
          valueFontColor: "#595959",
          valueFontSize: "15",
          valueFontBold: "1",
          valueFontAlpha: "100",

          //  "labelFont": "Proxima Nova",
          labelFontColor: "595959",
          labelFontSize: "15",
          labelFontBold: "1",
          labelAlpha: "100",

          //  "toolTipBorderColor": "#666666",
          toolTipBorderRadius: "10",
          toolTipBgColor: "#FFFFFF",
          toolTipBgAlpha: "100",
          showToolTipShadow: "1",
          // clickURLOverridesPlotLinks: true,
          //clickURL: props.link
        },
        // "annotations": {
        //   // "showbelow": "1",
        //   constrainedScale:"1",
        //   "autoscale": "1",
        //   "showbelow": "0",
        //   "groups": [{
        //     "id": "transarc",
        //     "items": [{
        //         "type": "arc",
        //         "radius": "25%",
        //         // "borderColor": "#000000",
        //         "innerRadius": "0",
        //         "color": "#53F9B2",
        //         "alpha": "100",
        //         "x": "$dataset.0.set.0.x",
        //         "y": "$dataset.0.set.0.STARTY+2",
        //         "startangle": "0",
        //         "endangle": "180"
        //       },
        //       {
        //         "type": "arc",
        //         "radius": "25%",
        //         // "borderColor": "#000000",
        //         "innerRadius": "0",
        //         "color": "#53F9B2",
        //         "alpha": "100",
        //         "x": "$dataset.0.set.1.x",
        //         "y": "$dataset.0.set.1.STARTY+2",
        //         "startangle": "0",
        //         "endangle": "180"
        //       },
        //       {
        //         "type": "arc",
        //         "radius": "25%",
        //         // "borderColor": "#000000",
        //         "innerRadius": "0",
        //         "color": "#53F9B2",
        //         "alpha": "100",
        //         "x": "$dataset.0.set.2.x",
        //         "y": "$dataset.0.set.2.STARTY+2",
        //         "startangle": "0",
        //         "endangle": "180"
        //       }
        //       ,
        //       {
        //         "type": "arc",
        //         "radius": "25%",
        //         // "borderColor": "#000000",
        //         "innerRadius": "0",
        //         "color": "#53F9B2",
        //         "alpha": "100",
        //         "x": "$dataset.0.set.3.x",
        //         "y": "$dataset.0.set.3.STARTY+2",
        //         "startangle": "0",
        //         "endangle": "180"
        //       }
        //       ,
        //       {
        //         "type": "arc",
        //         "radius": "25%",
        //         // "borderColor": "#000000",
        //         "innerRadius": "0",
        //         "color": "#53F9B2",
        //         "alpha": "100",
        //         "x": "$dataset.0.set.4.x",
        //         "y": "$dataset.0.set.4.STARTY+2",
        //         "startangle": "0",
        //         "endangle": "180"
        //       }
        //     ]}
        //   ],
        //     },
        data: [],
      },
    };
    chartConfigsObject.dataSource.data = chartData;
    console.log(chartConfigsObject);
    setChartConfigs(chartConfigsObject);
  };

  React.useEffect(() => {
    console.log("2nd useEffect is running: ", stageValue);
    if (props.icp !== null) {
      getInitialChartData();
    }
  }, [stageValue, props.stageData]);

  React.useEffect(() => {
    console.log("2nd useEffect is running: ", chartConfigs);
  }, [chartConfigs]);

  return (Array.isArray(chartConfigs) && !chartConfigs.length) ||
    props.isLoading ? (
    <Skeleton
      variant="rectangular"
      style={{ margin: "5%", borderRadius: "16px" }}
      width={"90%"}
      height={"70%"}
    />
  ) : (
    // <div><div className="card-header" style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", height: "56px" }}>
    //   <Typography variant="h6" component="h6" style={{ fontWeight: "700" }}> Stage Aging </Typography>
    //   <StageAgingSelector
    //     stageValue={stageValue}
    //     setStageValue={setStageValue}
    //   /></div>
    <ReactFC {...chartConfigs} />
  );
  // </div>
};

export default ColumnChart;
