import React from 'react'
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Bar2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Attribute from "./Attribute";

ReactFC.fcRoot(FusionCharts, Bar2D, FusionTheme);

export const BarChart = (props) => {
  console.log(props)

  const [chartConfigs, setChartConfigs] = React.useState([])
  const setAttributeData = () => {
    setChartConfigs(
      {
        type: "scrollbar2d",
        // type: "bar2d",
        width: "100%",
        height: "408",
        dataFormat: "json",
        dataSource: {
          chart: {
            caption: props.xAxisLabel,
            captionAlignment: "left",
            alignCaptionWithCanvas: 0,
            plottooltext: "<div style='padding: 3px'> $label <br><b style='font-size: 14px'>$dataValue</b></div>",
            showValues: "1",
            scrollColor: "#FFFFFF",
            scrollPosition: "left",
            scrollWidth: "5",
            scrolltoend: "1",
            placeValuesInside: "0",
            theme: "fusion",
            palettecolors: "#75E3EA",
            usePlotGradientColor: "1",
            plotGradientColor: "#37CAD3",
            chartTopMargin: "20",
            "toolTipBorderRadius": "10",
            "toolTipBgColor": "#FFFFFF",
            "toolTipBgAlpha": "100",
            "showToolTipShadow": "1",
            "xAxisNameFontSize": "12",
            "yAxisNameFontSize": "12",
            "valueFontSize": "12",
            "outCnvBaseFontSize": "12",
            "xAxisNameFontColor": "#A0A0A0",
            "yAxisNameFontColor": "#A0A0A0",
            "xAxisNameFontBold": "1",
          },
          categories: [{category:props.categoryData}],
          dataSet: [{data:props.chartData}]
        }
      }
    )
    console.log(chartConfigs)
    // console.log(`%c${JSON.stringify(chartConfigs)}`,'background:yellow;')
  }

  React.useEffect(() => {
    setAttributeData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chartData, props.xAxisLabel, props.categoryData ])

  return (
    <div style={{ dispaly: 'flex', flexDirection: 'column', width: "100%" }}>
      <Attribute setAttribute={props.setAttribute} attributeValue={props.attributeValue} />
      {Array.isArray(chartConfigs) && !chartConfigs.length ? <React.Fragment>Loading...</React.Fragment> :
        <ReactFC {...chartConfigs} />}
    </div>
  )
}
