import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "@bamboobox/b2logincheck";
import { LOGIN_URL, AUTH_URL, ATTRIBUTE_URL, FUNNEL_REPORT } from "./Constant";
import {ResourceCheck} from '@bamboobox/b2resource-check/dist'

const resources=["dashboard_dataquality",
"dashboard_insights",
"dashboard_keysegments",
"dashboard_journeyanalytics",
"dashboard_intent",
"dashboard_trends",
"account_dashboard"
]


ReactDOM.render(
    <AuthProvider loginURL={LOGIN_URL} authcheckURL={AUTH_URL}>
      <ResourceCheck component={<App />} resources={resources} default={true}/>
      {/* <App /> */}
    </AuthProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function.
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
