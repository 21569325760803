import React, { useState, useRef, useEffect } from "react";
import { FilterContext } from "../index";
import AttributeItem from "../attributeitem";
import { Button, Typography, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Skeleton from "@mui/material/Skeleton";

import "./index.css";

function AttributeList(props) {
  const divRef = useRef(null);

  const {
    attributeList,
    getFilterData,
    icp,
    onAddFilterItem,
    onOrFilterItem,
    onFilterChange,
    tenantConfigMapper,
    contTenantConfigMapper,
    subfiltersConfig,
    screen,
  } = React.useContext(FilterContext);
  console.log("props is in attributelist ", props);
  console.log("attributelist1223", attributeList);
  console.log("cheacked 2", attributeList[0]?.isValid);

  const isSubfilterAvailable = (index) => {
    const restSubfilterArr = fetchAttributeArr(`${index}-`, true)

    if(restSubfilterArr?.length > 0){
      return true;
    }
    return false;
  }
  const isAnyFilterInvalid = attributeList.some((itr) => {
    if (itr?.subfilter?.length > 0) {
      return itr.subfilter.some((subItr) => subItr.isValid == false);
    } else {
      return itr.isValid == false;
    }
  });

  if (
    !tenantConfigMapper ||
    tenantConfigMapper?.size === 0
  ) {
    return (
      <div style={{ display: "grid", gap: "10px" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "180px 180px 1fr",
            gap: "10px",
          }}
        >
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
        </div>
        <Skeleton
          variant="rounded"
          sx={{ height: "32px", width: "64px", borderRadius: "18px" }}
        />
      </div>
    );
  }

  function calculateSubfilterAttributeArray(
    values,
    source = false,
    activityType = false
  ) {
    try {
      const finalSubfilterAttribute = Array.isArray(values) ? values : [];

      if (screen === "isAccountHub" || screen === "isOpportunityHub") {
        return finalSubfilterAttribute
          .map((key) => {
            if (source) {
              if (tenantConfigMapper.get(key)) {
                const obj = {
                  ...tenantConfigMapper.get(key),
                  attribute: key,
                  logo: source,
                  activityType: activityType,
                };
                return obj;
              }
              return false;
            } else if (tenantConfigMapper.get(key)) {
              return { ...tenantConfigMapper.get(key), attribute: key };
            }
          })
          .filter((obj) => obj);
      } else if (props.exportType === "CONTACT") {
        return finalSubfilterAttribute
          .map((key) => {
            if (source) {
              if (contTenantConfigMapper.get(key)) {
                const obj = {
                  ...contTenantConfigMapper.get(key),
                  attribute: key,
                  logo: source,
                  activityType: activityType,
                };
                return obj;
              }
              return false;
            } else if (contTenantConfigMapper.get(key)) {
              return { ...contTenantConfigMapper.get(key), attribute: key };
            }
          })
          .filter((obj) => obj);
      }

      return [];
    } catch (e) {
      console.error("Error occured while calculating subfilter array", e);
      return [];
    }
  }

  const fetchAttributeArr = (elementIndex, subfilter) => {
    if (subfilter) {
      const parentFilter = attributeList[+elementIndex.split("-")[0]] ?? [];
      const subfilterArr = parentFilter?.subfilter;

      const { attribute, source, values: parentFilterValues } = parentFilter;

      const configObject = subfiltersConfig?.[attribute] || [];

      const resultArray = [];
      /* if extra field added */
      if (configObject?.extrafields) {
        const subfilterAttrArray = configObject.extrafields;
        resultArray.push(
          ...calculateSubfilterAttributeArray(subfilterAttrArray)
        );
      }
      parentFilterValues.map((currentValue) => {
        const subfilterAttrArray =
          configObject?.[currentValue?.source]?.[currentValue?.activityType];
        resultArray.push(
          ...calculateSubfilterAttributeArray(
            subfilterAttrArray,
            currentValue?.source,
            currentValue?.activityType
          )
        );
      });

      return resultArray.filter(
        (obj) =>
          !subfilterArr?.find(
            (currObj) =>
              currObj.attribute === obj.attribute && currObj.logo === obj.logo
          )
      );
    }

    if (screen === "isAccountHub" || screen === "isOpportunityHub") {
      return Array.from(tenantConfigMapper, ([key, value]) => ({
        ...value,
        attribute: key,
      }))
        .filter((obj) => obj?.isFilter)
        .sort((a, b) => {
          if (a.groupLabel === b.groupLabel) {
            return a.label < b.label ? -1 : 1;
          } else {
            return a.groupLabel < b.groupLabel ? -1 : 1;
          }
        });
    } else if (screen === "isContactHub") {
      return Array.from(contTenantConfigMapper, ([key, value]) => ({
        ...value,
        attribute: key,
      }))
        .filter((obj) => obj?.isFilter)
        .sort((a, b) => {
          if (a.groupLabel === b.groupLabel) {
            return a.label < b.label ? -1 : 1;
          } else {
            return a.groupLabel < b.groupLabel ? -1 : 1;
          }
        });
    }

    return [];
  }

  function isSubfilterTrue(filterAttribute) {
    let isTrue = false;
    if (filterAttribute.values?.length > 0) {
      for (const itr of filterAttribute.values) {
        const { source, activityType } = itr;
        if (
          subfiltersConfig?.[filterAttribute?.attribute]?.[source]?.[
            activityType
          ]
        ) {
          isTrue = true;
          break;
        }
      }
    }
    if (
      !isTrue &&
      ["includes", "excludes"].includes(filterAttribute?.relation) &&
      subfiltersConfig?.[filterAttribute?.attribute]?.extrafields
    ) {
      isTrue = true;
    }
    return (
      filterAttribute?.isValid &&
      (filterAttribute.subfilter === undefined ||
        (filterAttribute.subfilter?.length === 0
          ? true
          : filterAttribute.subfilter?.[filterAttribute.subfilter?.length - 1]
              ?.isValid)) &&
      isTrue
    );
  }

  return (
    <div>
      <div
        className="scroll"
        style={{ overflowX: "hidden", maxHeight: "50vh" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "12px",
            marginBottom: "8px",
            color: "#595959",
            gap: "12px",
          }}
        >
          <Typography
            variant="subtitle2"
            style={{
              width: "180px",
              height: "24px",
              fontFamily: "Nunito Sans",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "24px",
              display: "flex",
              alignItems: "center",
              letterSpacing: "0.15px",
              textTransform: "uppercase",
              color: "#000000",
              flex: "none",
              order: "0",
              flexGrow: "0",
            }}
          >
            Attribute
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              width: "180px",
              height: "24px",
              fontFamily: "Nunito Sans",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "24px",
              display: "flex",
              alignItems: "center",
              letterSpacing: "0.15px",
              textTransform: "uppercase",
              color: "#000000",
              flex: "none",
              order: 1,
              flexGrow: 0,
            }}
          >
            Relation
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              width: "280px",
              height: "24px",
              fontFamily: "Nunito Sans",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "24px",
              display: "flex",
              alignItems: "center",
              letterSpacing: "0.15px",
              textTransform: "uppercase",
              color: "#000000",
              flex: "none",
              order: 2,
              flexGrow: 1,
            }}
          >
            Values
          </Typography>
        </div>
        <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
          {attributeList?.map((attribute, index) => (
            <>
              <div
                key={attribute?.id}
                style={{ marginBottom: "0", display: "flex", }}
              >
                <AttributeItem
                  attributeData={attribute}
                  elementIndex={index}
                  key={index}
                  icp={icp}
                  fetchAttributeArr={fetchAttributeArr}
                />
              </div>
              {attribute?.subfilter &&
                attribute.subfilter?.length > 0 &&
                attribute.subfilter.map(
                  (subfilterAttribute, subfilterIndex) => (
                    <div
                      key={subfilterAttribute?.id}
                      style={{ marginBottom: "8px", display: "flex" }}
                    >
                      <AttributeItem
                        attributeData={subfilterAttribute}
                        elementIndex={`${index}-${subfilterIndex}`}
                        key={`${index}-${subfilterIndex}`}
                        icp={icp}
                        subfilter={true}
                        fetchAttributeArr={fetchAttributeArr}
                      />
                    </div>
                  )
                )}
              {subfiltersConfig?.[attribute?.attribute] &&
              isSubfilterAvailable(index) ? (
                <>
                  {isSubfilterTrue(attribute) ? (
                    <button
                      onClick={() => {
                        onAddFilterItem(attribute, index, true);
                      }}
                      className="subfilterButton"
                    >
                      <AddIcon />
                      <div>Add Sub-Attribute</div>
                    </button>
                  ) : (
                    <Tooltip
                      title={
                        "You can't add sub-filter yet. Fill the values above and try again."
                      }
                    >
                      <button disabled className="subfilterButton">
                        <AddIcon />
                        <div>Add Sub-Attribute</div>
                      </button>
                    </Tooltip>
                  )}
                </>
              ) : (
                <>
                  <div className="subfilterButton"></div>
                </>
              )}
              {attribute?.isValid &&
              index === attributeList.length - 1 &&
              (attribute.subfilter === undefined ||
              attribute.subfilter?.length === 0
                ? true
                : attribute.subfilter?.[attribute.subfilter?.length - 1]
                    ?.isValid) ? (
                <Button
                  color="primary"
                  variant="contained"
                  className="and-button"
                  sx={{
                    backgroundColor: "white !important",
                    height: "32px !important",
                    width: "55px !important",
                    fontSize: "13px",
                    fontWeight: "800",
                    borderRadius: "24px",
                    color: "rgba(33, 218, 140, 1) !important",
                    border:
                      "1px solid var(--primary-border, rgba(55, 234, 158, 0.50)) !important",
                    boxShadow: "none !important",
                  }}
                  ref={divRef}
                  onClick={() => {
                    onAddFilterItem(
                      attributeList[attributeList.length - 1],
                      attributeList.length - 1
                    );
                  }}
                >
                  AND
                </Button>
              ) : (
                <>
                  {index < attributeList.length - 1 ? (
                    <Button
                      color="primary"
                      variant="contained"
                      ref={divRef}
                      disabled
                      style={{
                        background: "rgba(0, 0, 0, 0.12)",
                        color: "rgba(0, 0, 0, 0.38)",
                        border: "1px solid var(--action-disabledBg, #FFF)",
                        height: "32px",
                        width: "55px",
                        fontSize: "13px",
                        fontWeight: "800",
                        borderRadius: "24px",
                      }}
                    >
                      AND
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      ref={divRef}
                      disabled
                      style={{
                        background: "white",
                        color: "rgba(0, 0, 0, 0.38)",
                        border: "1px solid rgba(0, 0, 0, 0.38)",
                        height: "32px",
                        width: "55px",
                        fontSize: "13px",
                        fontWeight: "800",
                        borderRadius: "24px",
                      }}
                    >
                      AND
                    </Button>
                  )}
                </>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

export default React.memo(AttributeList);
