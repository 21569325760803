import React from 'react'
import './TotalAccount.css';
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Bar2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { useAuthState } from '@bamboobox/b2logincheck';
import { UNIFIED_URL, UNIFIED_FRONTEND_URL } from "../Constant";
import { BarChart } from "./BarChart";
import { AttributeList } from "./AttributeList";
import { MuiThemeProvider, Paper } from '@material-ui/core';
import UItheme from '@bamboobox/b2-theme';

ReactFC.fcRoot(FusionCharts, Bar2D, FusionTheme);

const TotalAccount = (props) => {
  const controllerRef = React.useRef();
  // const [totalAccountValue, setTotalAccountValue] = React.useState(null)
  const [attributeChart1, setAttributeChart1] = React.useState(null)
  // const [attributeChart2, setAttributeChart2] = React.useState(null)
  // const [attributeChart3, setAttributeChart3] = React.useState(null)
  // const [attributeSelectedList, setAttributeSelectedList] = React.useState([AttributeList[0], AttributeList[1], AttributeList[2]])
  const [attributeValue1, setAttributeValue1] = React.useState(AttributeList[0])
  // const [attributeValue2, setAttributeValue2] = React.useState(AttributeList[1])
  // const [attributeValue3, setAttributeValue3] = React.useState(AttributeList[2])
  const [categoryChart1, setCategoryChart1] = React.useState(null)
  // const [categoryChart2, setCategoryChart2] = React.useState(null)
  // const [categoryChart3, setCategoryChart3] = React.useState(null)

  //need to check devops pipeline flow

  
  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;
  const getTotalAccountChartData = () => {
    getAttributeChart1()
    // getAttributeChart2()
    // getAttributeChart3()
  }

  const getAttributeChart1 = () => {
    console.log("getAttributeChart1", props.requestBody)
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;

    fetch(
      `${UNIFIED_URL}/analytics/totalAccounts/${tenantId}?attr1=${attributeValue1.value}&icp=${props.icpSelected}&filterName=${props.segmentName}`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({"filters": props.requestBody}),
        signal:controllerRef.current?.signal,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((data) => {

        var chartDataArray = []
        let categoryDataArray = []
        var attributeKey = Object.keys(data.listAttributes[0])

        for (let attributeRowValue = 0; attributeRowValue < data.listAttributes[0][attributeKey].length; attributeRowValue++) {
          let dynamicFilterObject = [{
            "attribute": "",
            'type':'ACCOUNT',
            "relation": "includes",
            "values": []
          }]
          let AttributeLabel = AttributeList.find(o => o.value === attributeKey[0]);
         
          dynamicFilterObject[0].attribute = AttributeLabel.label
          if(data.listAttributes[0][attributeKey][attributeRowValue].value.toString().toLowerCase() === 'unassigned'){
            dynamicFilterObject[0].relation = 'is empty';
            dynamicFilterObject[0].values = []
          }else{
            dynamicFilterObject[0].values = [data.listAttributes[0][attributeKey][attributeRowValue].value]
          }


          //changed here
        //  dynamicFilterObject[0].values = [data.listAttributes[0][attributeKey][attributeRowValue].value]


          // console.log(`%c${JSON.stringify(props.requestBody)}`, 'color: yellow;')
          // alert(JSON.stringify(props.requestBody))

          if (!(Array.isArray(props.requestBody) && !props.requestBody.length)) {
            props.requestBody.forEach(reqBody => {
              dynamicFilterObject.push(reqBody)
            })
          }
          var attributeRowValueObject;
          if (data.listAttributes[0][attributeKey][attributeRowValue].value.toString().toLowerCase() !== 'unassigned') {
             attributeRowValueObject = {
              "label": "",
              "value": "",
              "link": `${UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(JSON.stringify(dynamicFilterObject)))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(JSON.stringify(props.requestBody)))}?icp=${props.icpSelected}&segmentName=${props.segmentName}&tabVal=${props.tabVal}`
               //"link": `http://test.bamboobox.in:9000/#/filter/${escape(JSON.stringify(dynamicFilterObject))}/analytics_dashboard/${escape(JSON.stringify(props.requestBody))}?icp=${props.icpSelected}&segmentName=${props.segmentName}&tabVal=${props.tabVal}`
            }
          }
          else {
            attributeRowValueObject = {
              "label": "",
              "value": "",
              "link": `${UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(JSON.stringify(dynamicFilterObject)))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(JSON.stringify(props.requestBody)))}?icp=${props.icpSelected}&segmentName=${props.segmentName}&tabVal=${props.tabVal}`
            }
          }

          let categoryValObj = {
            "label": "",
            "value": ""
          }

          attributeRowValueObject.label = data.listAttributes[0][attributeKey][attributeRowValue].value
          attributeRowValueObject.value = data.listAttributes[0][attributeKey][attributeRowValue].count
          categoryValObj.label = data.listAttributes[0][attributeKey][attributeRowValue].value
          categoryValObj.value = data.listAttributes[0][attributeKey][attributeRowValue].count

          
          categoryDataArray.push(categoryValObj)
          chartDataArray.push(attributeRowValueObject)
        }

        chartDataArray.sort((a, b) => {
          return a.value - b.value;
        });
        
        categoryDataArray.sort((a, b) => {
          return a.value - b.value;
        })
       
        setAttributeChart1(chartDataArray)
        setCategoryChart1(categoryDataArray)
        
      }).catch((error)=>{
        if(error?.message === "The user aborted a request.") return;
      })
  }

  // const getAttributeChart2 = () => {
  //   fetch(
  //     `${UNIFIED_URL}/analytics/totalAccounts/${tenantId}?attr1=${attributeValue2.value}&icp=${props.icpSelected}`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(props.requestBody),
  //       credentials: "include",
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {

  //       var chartDataArray = []
  //       let categoryDataArray = []
  //       var attributeKey = Object.keys(data.listAttributes[0])

  //       for (let attributeRowValue = 0; attributeRowValue < data.listAttributes[0][attributeKey].length; attributeRowValue++) {
  //         let dynamicFilterObject = [{
  //           "attribute": "",
  //           "relation": "includes",
  //           "values": []
  //         }]
  //         let AttributeLabel = AttributeList.find(o => o.value === attributeKey[0]);
  //         dynamicFilterObject[0].attribute = AttributeLabel.label
  //         dynamicFilterObject[0].values = [data.listAttributes[0][attributeKey][attributeRowValue].value]

  //         if (!(Array.isArray(props.requestBody) && !props.requestBody.length)) {
  //           props.requestBody.forEach(reqBody => {
  //             dynamicFilterObject.push(reqBody)
  //           })
  //         }

  //         if (data.listAttributes[0][attributeKey][attributeRowValue].value.toString().toLowerCase() !== 'unassigned') {
  //           var attributeRowValueObject = {
  //             "label": "",
  //             "value": "",
  //             "link": `${UNIFIED_FRONTEND_URL}/#/filter/${escape(JSON.stringify(dynamicFilterObject))}/analytics_dashboard/${escape(JSON.stringify(props.requestBody))}?icp=${props.icpSelected}`
  //           }
  //         }
  //         else {
  //           var attributeRowValueObject = {
  //             "label": "",
  //             "value": "",
  //             "link": ``
  //           }
  //         }
  //         let categoryValObj = {
  //           "label": "",
  //           "value": ""
  //         }
  //         attributeRowValueObject.label = data.listAttributes[0][attributeKey][attributeRowValue].value
  //         attributeRowValueObject.value = data.listAttributes[0][attributeKey][attributeRowValue].count
  //         categoryValObj.label = data.listAttributes[0][attributeKey][attributeRowValue].value
  //         categoryValObj.value = data.listAttributes[0][attributeKey][attributeRowValue].count

  //         categoryDataArray.push(categoryValObj)
  //         chartDataArray.push(attributeRowValueObject)
  //       }

  //       chartDataArray.sort((a, b) => {
  //         return a.value - b.value;
  //       });
  //       categoryDataArray.sort((a, b) => {
  //         return a.value - b.value;
  //       })
  //       setAttributeChart2(chartDataArray)
  //       setCategoryChart2(categoryDataArray)
  //     })
  // }
  // useEffect(() => {
  //   props.AccountCount(totalAccountValue)
    
  // },[totalAccountValue])
  
  // const getAttributeChart3 = () => {
  //   fetch(
  //     `${UNIFIED_URL}/analytics/totalAccounts/${tenantId}?attr1=${attributeValue3.value}&icp=${props.icpSelected}`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(props.requestBody),
  //       credentials: "include",
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setTotalAccountValue(data.totalCount)
  //       var chartDataArray = []
  //       let categoryDataArray = []
  //       var attributeKey = Object.keys(data.listAttributes[0])

  //       for (let attributeRowValue = 0; attributeRowValue < data.listAttributes[0][attributeKey].length; attributeRowValue++) {
  //         let dynamicFilterObject = [{
  //           "attribute": "",
  //           "relation": "includes",
  //           "values": []
  //         }]
  //         let AttributeLabel = AttributeList.find(o => o.value === attributeKey[0]);
  //         dynamicFilterObject[0].attribute = AttributeLabel.label
  //         dynamicFilterObject[0].values = [data.listAttributes[0][attributeKey][attributeRowValue].value]

  //         if (!(Array.isArray(props.requestBody) && !props.requestBody.length)) {
  //           props.requestBody.forEach(reqBody => {
  //             dynamicFilterObject.push(reqBody)
  //           })
  //         }


  //         if (data.listAttributes[0][attributeKey][attributeRowValue].value.toString().toLowerCase() !== 'unassigned') {
  //           var attributeRowValueObject = {
  //             "label": "",
  //             "value": "",
  //             "link": `${UNIFIED_FRONTEND_URL}/#/filter/${escape(JSON.stringify(dynamicFilterObject))}/analytics_dashboard/${escape(JSON.stringify(props.requestBody))}?icp=${props.icpSelected}`
  //           }
  //         }
  //         else {
  //           var attributeRowValueObject = {
  //             "label": "",
  //             "value": "",
  //             "link": ``
  //           }
  //         }
  //         let categoryValObj = {
  //           "label": "",
  //           "value": ""
  //         }
  //         attributeRowValueObject.label = data.listAttributes[0][attributeKey][attributeRowValue].value
  //         attributeRowValueObject.value = data.listAttributes[0][attributeKey][attributeRowValue].count
  //         categoryValObj.label = data.listAttributes[0][attributeKey][attributeRowValue].value
  //         categoryValObj.value = data.listAttributes[0][attributeKey][attributeRowValue].count

  //         categoryDataArray.push(categoryValObj)
  //         chartDataArray.push(attributeRowValueObject)
  //       }

  //       chartDataArray.sort((a, b) => {
  //         return a.value - b.value;
  //       });
  //       categoryDataArray.sort((a, b) => {
  //         return a.value - b.value;
  //       })
        
  //       setAttributeChart3(chartDataArray)
  //       setCategoryChart3(categoryDataArray)
  //     })
  // }

  React.useEffect(() => {
    if (props.icpSelected !== null && props.segmentName !== '') {
      getTotalAccountChartData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.requestBody, props.icpSelected,attributeValue1,props.segmentName])


//////////888888888888888888888/////////
  ////////////////////////////////////////
  // React.useEffect(() => {
  //   // if (!(Array.isArray(props.requestBody) && !props.requestBody.length))
  //   getAttributeChart1()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [attributeValue1])
  ///////////////////////////////////////////
  /////////////////////888888888888888888///

  // React.useEffect(() => {
  //   // if (!(Array.isArray(props.requestBody) && !props.requestBody.length))
  //   getAttributeChart2()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [attributeValue2])

  // React.useEffect(() => {
  //   // if (!(Array.isArray(props.requestBody) && !props.requestBody.length))
  //   getAttributeChart3()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [attributeValue3])
  
  return (
    <MuiThemeProvider theme={UItheme}>
      <div className='container-align' >
      <Paper elevation={3} className='chart-container'  style={{ borderRadius:"18px", minHeight: "400px", height:"480px", background: "#FFFFFF", boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",}}>
          {/* {attributeChart1 === null || attributeChart2 === null || attributeChart3 === null || categoryChart1 === null || categoryChart2 === null || categoryChart3 === null ? <React.Fragment>Loading...</React.Fragment> : */}
          {attributeChart1 === null || categoryChart1 === null ? <React.Fragment>Loading...</React.Fragment> :
            // <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              {/* <Attribute setAttributeSelectedList={setAttributeSelectedList} /> */}
              {/* <div className='funnel-ageing-container' style={{ display: 'flex', flexBasis: "25%", minWidth: '50px'}}> */}
                {/* <TotalAccountCount totalAccountValue={totalAccountValue} />
                <Divider variant="middle" orientation="vertical" flexItem /> */}
                <div>
                <BarChart chartData={attributeChart1} setAttribute={setAttributeValue1} attributeValue={attributeValue1} categoryData={categoryChart1} />
                {/* <Divider variant="middle" orientation="vertical" flexItem /> */}
                {/* <BarChart chartData={attributeChart2} setAttribute={setAttributeValue2} attributeValue={attributeValue2} categoryData={categoryChart2}/>
                <Divider variant="middle" orientation="vertical" flexItem />
                <BarChart chartData={attributeChart3} setAttribute={setAttributeValue3} attributeValue={attributeValue3} categoryData={categoryChart3}/> */}
              </div>
            </div>}
        </Paper>
      </div>
    </MuiThemeProvider>
  )
}

export default TotalAccount
