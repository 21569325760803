import React, { useState, useEffect } from "react";
import { useAuthState } from '@bamboobox/b2logincheck';
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import { Button, MenuItem, Select, FormControl, Typography, IconButton} from "@material-ui/core/";
import Chip from "@material-ui/core/Chip";
// import Snackbar from "@material-ui/core/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import MuiAlert from "@material-ui/lab/Alert";
import FunnelChart from "./FunnelChart";
import Filters from "./new-filers/index";
import { Add, PieChart, Refresh, Settings} from "@material-ui/icons";
import { ATTRIBUTE_URL } from './Constant';
import Divider from '@material-ui/core/Divider';
import CreateTab from './CreateTab';
import { withStyles } from "@material-ui/core/styles";
import {UNIFIED_URL} from "./Constant";
import Tooltip from "@material-ui/core/Tooltip";
import {DateDifference} from './cards/DateDiiference'

import {
    saveFilters,
    getFilterNames,
    getFilterDataByName,
    deleteFilters,
    getIcpListName,
    Segments
} from "./api";
import Header from "./Header";
import TotalAccount from "./totalAccount/TotalAccount";
import ColumnChart from "./newAccount/ColumnChart";
import { useParams } from 'react-router-dom';
import UItheme from "@bamboobox/b2-theme";
import { DATE_FIELDS,CATEGORIES } from './filters/attributeitem/constants'
import {  useLocation } from "react-router-dom";

import { toast } from "react-toastify";
import Alert from '@mui/material/Alert';
import "./toast.css"
import axios from "axios";
import { ThemeProvider } from "@mui/styles";
// import { makeStyles } from "@mui/styles";
import {  SettingPop } from "./cards/Settingpop";
import _ from "lodash";

const ui = "unified_profile";

const useStyles = makeStyles((theme) => ({
    
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    headerDiv: {
        // Group154
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px",
        
        position: "static",
        width: "100%",
        height: "234px",
        left: "0%",
        top: "5%",
    },
    headerTabs:{
        /* tabs */
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",

        position: "static",
        width: "146px",
        height: "32px",
        left: "0px",
        top: "0px",
        /* Inside auto layout */
        flex: "none",
        order: "0",
        flexGrow: "0",
        margin: "0px 24px",
    },
    filterDiv: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        padding: "0px",
        gap:"1rem",
        position: "absolute",
        minWidth: "100px",
        height: "160px",
        // left: "75%",
        right:'5%',
        top: "5px",
        /* Inside auto layout */
        flex: "none",
        order: "1",
        flexGrow: "0",
        // margin: "0% 0% 0% 8.5%",
    },
    filterButton: {
        /* ButtonGroup/wMenu */
        /* Auto layout */

        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "0px",

        position: "absolute",
        width: "100px",
        height: "160px",
        left: "50%",
        top: "0px",
    },
    headTabs: {
        /* Head_tabs */
        position: "absolute",
        width: "100%",
        height: "160px",
        left: "0%",
        top: "0%",
    },
    frame12: {
        /* Frame 12 */
        /* Auto layout */

        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        padding: "0px",

        position: "absolute",
        width: "97%",
        height: "160px",
        left: "3%",
        top: "55%",
    },
    tabs: {
        /* tabs */
        position: "absolute",
        width: "900px",
        height: "36px",
        left: "0px",
        top: "0px",
        /* Inside auto layout */
        flex: "none",
        order: "0",
        flexGrow: "0",
        margin: "0px 72px",
    }
}));

const FunnelReportPage = () => {
    toast.configure({
        autoClose: 6000,
        draggable: true,
        hideProgressBar: false,
        position: toast.POSITION.BOTTOM_LEFT,
        icon: false,
    
      });

    let location = useLocation();
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    let GetIcp = () => {
        return useQuery().get("icp") || '';
    }

    let GetSegment = () => {
        return useQuery().get("segmentName");
    }

    let GetTabVal = () => {
        return useQuery().get("tabVal");
    }
    
    let { id } = useParams();
    let query = useQuery();
    
    let params_url = query.get("icp") || "";
    const [doRefresh,setDoRefresh] = useState(1);
    const [softRefresh,setSoftRefresh] = useState(0);
    const [tabVal,setTabVal] = useState(0);
    const [intentNewAccount, setIntentNewAccount] = useState(false);
    const [attributeData, setAttributeData] = useState([]);
    const [body, setBody] = useState(id ? JSON.parse(decodeURIComponent(id)) : []);
    const [icp, setIcp] = useState(params_url);
    const [icpList, setIcpList] = useState([]);
    const [chipData, setChipData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [filterOptions, setFilterOptions] = useState([]);
    const [filterName, setFilterName] = useState("");
    const [action, setAction] = useState("");
    const [question, setQuestion] = useState("");
    const [openConfirmation, setOpenConfirmation] = useState(false);
    
    const [filterNamesArr, setFilterNamesArr] = useState([]);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    // const [openSnackbar, setOpenSnackbar] = useState(false);
    const[accSegment,setAccSegment]=useState("");
    const userDetails = useAuthState();
    const tenantId = userDetails.user.tenantId;
    const [totalAccount,SetTotalAccount]= useState();
    const userUuid = useAuthState().user.userUuid;
    const [value, setValue] = React.useState(0);
    // const [dateDiif, setDateDiif] = useState('')
    const permissionsOfUser = userDetails?.permissions.map((obj)=>obj.resourceName.toLowerCase());
    const [accountCount,SetAccountCount] = useState(null);
    const [loglist,setLoglist] = useState([{id:0 ,listTitle:"All Accounts",type:'account'}])
    const [chipToFocus, setChipToFocus] = useState({})
    const [appliedFilterAttr, setAppliedFilterAttr] = useState([]);
    const [chipClicked, setChipClicked] = useState(false);
    const [tenantConfigMapper, setTenantConfigMapper] = useState();
    const [contTenantConfigMapper, setContTenantConfigMapper] = useState();
    const [downldRequiredFieldsAcc, setDownldRequiredFieldsAcc] = useState([]);
    const [downldRequiredFieldsCont, setDownldRequiredFieldsCont] = useState([]);
    const [showCreateTab,setShowCreateTab] = useState(true)
    const [createCardPopup,setCreateCardPopup] = useState(false)
    const [settingpopUp,setSettingpopUp] = useState(false);
    
    console.log("cookie ", userDetails?.user?.department, userDetails?.user?.superUser)

    const classes = useStyles();
    // useEffect(()=>{
        console.log("doRefresh",doRefresh);
    // },[doRefresh])
    useEffect(() => {
        if(intentNewAccount){
            console.log("intentNewAccount tab = 3")
            if(showCreateTab)
            {
            setTabVal(3)
        }
        else{
            setTabVal(2)
        }
        }
        console.log("intentNewAccount", intentNewAccount)
    },[intentNewAccount])

    useEffect(() => {
        console.log("intentNewAccount tabVal", tabVal)
    },[tabVal])

    async function getTenantConfigMapper() {
        let response = await fetch(`${window._env_.TENANT_CONFIG}/tenant/field/mapper/get/${tenantId}/unified-profile-ui`, { credentials: "include" })
        let mapper = await response.json()
    
        Object.keys(mapper).forEach(key => {
          if (mapper[key] === undefined || mapper[key] === null) {
            delete mapper[key];
          }
        })
        setTenantConfigMapper(mapper);

        // let finalFieldsVar = await fetch(`${window._env_.UNIFIED_URL}/account/${tenantId}/filter?page=1&limit=1&icp=${icp}&filterName=All Accounts&sortby=&sortType=`,{
        //     method: "POST",
        //     headers: {
        //       "Access-Control-Allow-Origin": "*",
        //       "Content-Type": "application/json",
              
        //     },
        //     body: JSON.stringify([]),
        //     credentials: "include",
        //   }).then(resp => resp.json()).then(data => {
        //                 let firstRow = data?.results[0];
        
        //                 const firstRowFields = Object.keys(firstRow).filter(
        //                   (e) => e !== "rnum2" && e !== "rnum"
        //                 );
        
        //                 let othersIndex = firstRowFields.indexOf("others")
        //                 console.log('others index ', othersIndex, firstRow.others);
        //                 let othersobj = { others: "{\"fieldSalesExec\":\"\",\"ISExec\":\"\",\"businessFormat\":\"\",\"numOfStores\":\"\"}" }
        
        //                 const othersFieldsSet = new Set(Object.keys(JSON.parse(othersobj.others)));
        //                 console.log('othersFieldsSet ', othersFieldsSet);
        //                 let othersObjInData = []
        //                 if (firstRow?.others !== null) {
        //                   othersObjInData = Object.keys(JSON.parse(data.results[0]?.others))
        //                   othersObjInData.forEach(item => othersFieldsSet.add(item))
        
        //                 }
        
        
        
        
        //                 firstRowFields.splice(othersIndex, 1, ...Array.from(othersFieldsSet));
        
        //                 let uniqueColArray = firstRowFields.filter(function (item, pos, self) {
        //                   return self.indexOf(item) === pos;
        //                 })
        
        //                 let finalFields = uniqueColArray.filter(function (item) {
        //                   return mapper[item] !== undefined && mapper[item] !== null
        //                 })
    
        //                 finalFields = finalFields.filter(item => mapper[item]?.label !== '' && mapper[item]?.label !== null && mapper[item]?.label !== undefined )
        //                 return finalFields;
        //             }
        //   ).catch(e => console.log('error in fetching ',e));
        //   console.log('finalFieldVar',finalFieldsVar);
        //   setDownldRequiredFieldsAcc(finalFieldsVar);



        let contResponse = await fetch(`${window._env_.TENANT_CONFIG}/tenant/field/mapper/get/${tenantId}/ContactHub-creation`, { credentials: "include" })
        let contMapper = await contResponse.json()
    
        Object.keys(contMapper).forEach(key => {
          if (contMapper[key] === undefined || contMapper[key] === null) {
            delete contMapper[key];
          }
        })
        console.log(contMapper)
        setContTenantConfigMapper(contMapper);

      }

    
    useEffect(() => {
        accountFilterCount()  
    },[body,icp,accSegment])
    const accountFilterCount = async()=>{
        // let clonedBody = _.cloneDeep(body)
        // let pastObj = {Week: 7, Month: 30, Quarter: 90, Year: 365}
        // for(let i = 0; i < clonedBody.length; i++){
          
        // if(clonedBody[i]?.relation.toLowerCase() === 'in the past'){
        //   clonedBody[i].relation = 'after'

        //   let fromDate = pastObj[clonedBody[i]?.values[0]]
        //   let dateTime = (new Date(Date.now() - fromDate * 24 * 60 * 60 * 1000)).toISOString()
        //   clonedBody[i].values[0] = dateTime.substring(0, 11) + `00:00:00.000Z`; 

        // }
        // }
        await fetch(
            `${UNIFIED_URL}/analytics/totalAccounts/${tenantId}?attr1=industry&icp=${icp}&filterName=${accSegment}`,
            {
              method: "POST",
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({"filters": body}),
              credentials: "include",
            }
          )
            .then((response) => response.json())
            .then((data) => {
                SetTotalAccount(data.totalCount)})
                console.log("totalcount",totalAccount);
    }
    useEffect(() => {
        // setIcp(params_url)
        getIcpListName(tenantId).then((json) => {
            const icpKeys = json.map((icpObject) => {
                return icpObject?.id?.icpName;
            });
            setIcpList(icpKeys);
            // setIcp(icpKeys[0]);
            // setIcp(params_url)
        });

        getFilterNames(tenantId, ui).then((json) => {
            setFilterOptions(json.data);
        }).catch((e)=>{
            console.error("getFilterNames error occur",e);
        })

        if (!tenantConfigMapper) {
            getTenantConfigMapper();
          }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        
        totalCount();
    }, [])


    useEffect(()=>{
        if(tenantConfigMapper)
        applyFilters(body);
    },[tenantConfigMapper])

    useEffect(() => {
        console.log("test icp query changed")
        setIcp(GetIcp())
    }, [useQuery().get("icp")])


    useEffect(() => {
        let segName = GetSegment();
          
    if(segName&&Array.isArray(loglist)&&loglist?.filter(fil => fil?.listTitle===segName&&fil?.type==='account').length){
      setAccSegment(segName)
    }
    }, [useQuery().get("segmentName"),loglist])

    useEffect(() => {
    
      setTabVal(Number(GetTabVal()))
    
    }, [useQuery().get("tabVal"),loglist])



    useEffect(() => {
        if(filterOptions){
        const filtersArr = filterOptions.map((option) => {
            return option?.name;
        });
        setFilterNamesArr(filtersArr);
    }
    }, [filterOptions]);

    // const BlueTextTypography = withStyles({
    //     root: {
    //       color: "#109CF1"
    //     }
    //   })(Typography);
    function applyFilters(attrList) {
        setAppliedFilterAttr(attrList) 
        const attributeArray = attrList.map((object) => {
            const obj = { ...object };
            delete obj.isValid;
            delete obj.index;
            return obj;
        });
        
        setBody(attributeArray);
        setShowModal(false);
        const chipArray = attrList.map((element, index) => {
            let fieldName = ''
            for(const category of CATEGORIES){
                if(element.attribute === category.title){
                    fieldName = category.fieldName;
                    break;
                }
            }

            let chipLabel = tenantConfigMapper[fieldName]?.label;

            if (DATE_FIELDS.includes(element.attribute)) {
                let fromDate = new Date(element.values[0])
                let toDate = new Date(element.values[1])
                if(element.relation.toLowerCase() === 'is empty' || element.relation.toLowerCase() === 'is not empty'){
                    return {
                        key: element.attrId,
                        // label: `${chipLabel} (${fromDate.getDate()}-${(fromDate.getMonth() + 1)}-${fromDate.getFullYear()})`,
                        label: `${chipLabel}`,
                        value: element.relation,
                    };
                }

                if (element.relation.toLowerCase() !== "between".toLowerCase()) {

                    return {
                        key: element.attrId,
                        // label: `${chipLabel} (${fromDate.getDate()}-${(fromDate.getMonth() + 1)}-${fromDate.getFullYear()})`,
                        label: `${chipLabel}`,
                        value: `${element.relation.toLowerCase()} ${fromDate.getDate()}-${(fromDate.getMonth() + 1)}-${fromDate.getFullYear()}`
                    };

                } else {


                    return {
                        key: element.attrId,
                        // label: `${chipLabel}(${fromDate.getDate()}-${(fromDate.getMonth() + 1)}-${fromDate.getFullYear()} to ${toDate.getDate()}-${(toDate.getMonth() + 1)}-${toDate.getFullYear()})`,
                        label: `${chipLabel}`,
                        value:`${element.relation.toLowerCase()} ${fromDate.getDate()}-${(fromDate.getMonth() + 1)}-${fromDate.getFullYear()} to ${toDate.getDate()}-${(toDate.getMonth() + 1)}-${toDate.getFullYear()}`
                    };
                }
            } else if (element.relation.toLowerCase() === "Is Empty".toLowerCase() || element.relation.toLowerCase()=== 'is not empty'.toLowerCase()) {
                const valuesArr =element.relation.toLowerCase();
                return {
                    key: element.attrId,
                    // label: `${chipLabel}(${valuesArr})`,
                    label: `${chipLabel}`,
                    value: `${valuesArr}`
                };
            }
            var objArray = []
            if(typeof element.values[0] === 'object'){
                if(element.attribute.includes("campaignName")){
                  element.values.forEach(obj => {
                    objArray.push(`${obj.programName}-${obj.source==null||obj.source===''?'':obj.source}`)
                  })
                }else if(element.attribute === "Buyer Group"){
                    let depts = element.values[0].department.join(',');
                    let personas = element.values[0].personalevel.join(',');
                    let count = element.values[0].value.join(',')
                    objArray.push(`${count} in verticals of ${depts} and for ${personas} roles`);
                } else if (element.attribute.includes('label')) {
                    objArray.push(...element.values.map(item => item?.labelName))
                } else {
                  element.values.forEach(obj => {
                    objArray.push(`${obj.activityType}-${obj.source==null||obj.source===''?'':obj.source}`)
                  })
                }
               console.log("element", objArray)
              }

            console.log("element", JSON.stringify(element.values), objArray)
            // element.values !== 'object'?element.values.join(","):element.values;
            const valuesArr = typeof element.values[0] !== 'object'?element.values.join(","):objArray.join(",");
            return {
                key: element.attrId,
                // label: `${chipLabel}(${valuesArr})`,
                label: `${chipLabel}`,
                value:`${element.relation.toLowerCase()} ${valuesArr}`,
            };
        });
        setChipData(chipArray);
        setFilterName("");
    }
    const handleSetting = ()=>{
        setSettingpopUp(!settingpopUp)
    }

    const handleSaveSetting = ()=>{
        setSettingpopUp(false)
        window.location.reload();
    }
    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) =>
            chips.filter((chip) => chip.key !== chipToDelete.key)
        );
        if (chipToDelete.key === chipToFocus?.key) {
            setChipToFocus('');
          }
        setBody((body) => body.filter((e) => e.attrId !== chipToDelete.key));
        setAppliedFilterAttr((attrs) => attrs.filter(attr => attr.attrId !== chipToDelete.key));
    };

    const handleChipClick = (chipToEdit) => () => {
        setShowModal(true);
        setChipToFocus(chipToEdit);
        setAttributeData(appliedFilterAttr);
        setChipClicked(true);
      }

    //   useEffect(async() => {
    //     let date = await DateDifference(localStorage.getItem(`'refreshTimeStamp'${icp}+${tenantId}+${userUuid}`),'cards were',`'refreshTimeStamp'${icp}+${tenantId}+${userUuid}`,tenantId);
    //     setDateDiif(date)
    //   }, [icp,doRefresh,tabVal])

      
    // function Alert(props) {
    //     return <MuiAlert elevation={6} variant="filled" {...props} />;
    // }
    // const closeSnackbar = (event, reason) => {
    //     if (reason === "clickaway") {
    //         return;
    //     }
    //     setOpenSnackbar(false);
    // };
    const [toasted, setToasted] = useState(false)
    //   const LocalStorageName = 'refreshTimeStamp'+icp+tenantId+userUuid;
    async function handleRefresh (){
        try{
            const response = await axios.post(`${window._env_.CARD_SERVICE}/card/refreshall/${tenantId}?icp=${icp}`,{},{withCredentials:true})
            
                // localStorage.setItem(`'refreshTimeStamp'${icp}+${tenantId}+${userUuid}`,new Date());
                setDoRefresh(doRefresh+1)
               
            
                // toast.error(<Alert severity="error">{'Error while loading card'}</Alert>,{
                //     closeOnClick: true,
                //     autoClose: true,
                //     closeButton: true,
                //     newestOnTop: true
                //   });
            
        }
        catch(e){
            console.log("error while refresh all",e);
            // toast.error(<Alert severity="error">{'Error while loading cards please Try again in some time'}</Alert>,{
            //     closeOnClick: true,
            //     autoClose: true,
            //     closeButton: true,
            //     newestOnTop: true
            //   });
            setDoRefresh(doRefresh+1)
        }
      }
    function saveData(mode = "save", attrList, filterName) {
        saveFilters(mode, attrList, filterName, tenantId, ui).then((json) => {
            getFilterNames(tenantId, ui).then((res) => {
                setFilterOptions(res?.data||[]);
            });
            if (json?.success) {
                // setSeverity("success");
                toast.success(<Alert severity="success">{json?.message}</Alert>,{
                    closeOnClick: true,
                    autoClose: true,
                    closeButton: true,
                    newestOnTop: true
                  });
                if (mode === "save") {
                    applyFilters(attrList);
                } else {
                    setShowModal(false);
                    setFilterName("");
                }
            } else {
                // setSeverity("error");
                toast.error(<Alert severity="error">{json?.message}</Alert>,{
                    closeOnClick: true,
                    autoClose: true,
                    closeButton: true,
                    newestOnTop: true
                  });
            }
            // setMessage(json?.message);
            // setOpenSnackbar(true);
        })
        .catch((e)=>{
            console.error("savedata error",e)
        })
    }
    useEffect(async () => {
        console.log("calling....")
        setLoglist(await Segments(tenantId, userUuid));

    },[]);

      const totalCount=()=>{
          console.log("inside")
          const requestOptions = {
            credentials: 'include',
            method: 'GET',
        };
          fetch(`${UNIFIED_URL}/account/hub/${tenantId}/role-based/count?icp=${icp}`,requestOptions)
          .then((result) => result.json())
          .then((data)=> SetAccountCount(data.totalCount))
          .catch(SetAccountCount(0))
        console.log("totalcount",accountCount)

      }
    return (
        
        // <MuiThemeProvider theme={UItheme}>
        <ThemeProvider theme={UItheme}>
            <div className="page" style={{ backgroundColor:"#FBFBFB"}}>
                <div className={classes.headerDiv} >
                <Header reqBody={body} icpSelected={icp} segmentName={accSegment} setIntentNewAccount={setIntentNewAccount} intentNewAccount={intentNewAccount}
                    loglist={loglist} accSegment={accSegment} setAccSegment={setAccSegment} tabVal={tabVal}/>
                
                
                {/* <Divider/> */}
                
                <div  className={classes.headTabs}>
                    <div className={classes.frame12}>
                        <div className={classes.filterDiv}>

                      {(showCreateTab && tabVal==0 && (permissionsOfUser.includes("dashboard_insights") || permissionsOfUser.includes("account_dashboard")))?
                      <div style={{display:"flex",alignItem:"center",gap:"16px"}}>
                      
                      {/* <span className="label">  {dateDiif}</span> */}
                        <Tooltip title={'Refresh All Card'}>
                      {/* <Button className="createButton"  size="small" color="primary" variant="text"> */}
                      <IconButton  onClick={()=>handleRefresh()} >
                        <Refresh  />
                        </IconButton>
                        {/* </Button> */}
                        </Tooltip>
                        <Tooltip title={'Settings'} >
                        <IconButton onClick={()=>handleSetting()}>
                            <Settings/>
                        </IconButton>
                        </Tooltip>
                        <Tooltip title={'Create New Card'}>
                      <Button className="createButton1"   size="small" color="primary" onClick={()=>setCreateCardPopup(true)} variant="outlined"><Add fontSize="small"/> New Card</Button>
                      </Tooltip>
                      </div>
                      :
                      (
                        <Filters
                            className={classes.filterButton}
                            getFilterData={(data) => {
                                
                                setAction(data.actionType);
                                if (data.isValid) {
                                    setAttributeData(data.attrList);
                                    setFilterName(data.filterName);

                                    if (data.actionType === "APPLY" || data.actionType === "ONLY_APPLY") {
                                            applyFilters(data.attrList);
                                    } else if (data.actionType === "SAVE") {
                                        saveData("edit", data.attrList, data.filterName);
                                    } else {
                                        if (data.actionType === "SAVE_APPLY") {
                                            if (filterNamesArr.includes(data.filterName)) {
                                                // setMessage("FilterName already exists!");
                                                toast.error(<Alert severity="error">FilterName already exists!</Alert>,{
                                                    closeOnClick: true,
                                                    autoClose: true,
                                                    closeButton: true,
                                                    newestOnTop: true
                                                  });
                                                // setSeverity("error");
                                                // setOpenSnackbar(true);
                                            } else{
                                                saveData("save", data.attrList, data.filterName);
                                            }
                                        } 
                                    }
                                } 
                            }}
                            
                            customStyle={{ width: 150, background: "#fff" }}
                            onClickFilter={(filter, type) => {
                                if (filter !== "none") {
                                    if (type === "edit") {
                                        console.log(type);
                                        getFilterDataByName(filter, tenantId, ui).then((json) => {
                                            setAttributeData(json);
                                            setShowModal(true);
                                        });
                                    } else if (type === "delete") {
                                        console.log("delete");
                                        deleteFilters(filter, tenantId, ui)
                                            .then((res) => {
                                                getFilterNames(tenantId, ui)
                                                    .then((json) => {
                                                        setFilterOptions(json.data);
                                                    })
                                                    .catch((err) => {
                                                        console.error(err);
                                                    });
                                            })
                                            .catch((err) => {
                                                console.error(err);
                                            });
                                    } else if (type === "add") {
                                        setShowModal(true);
                                    } else {
                                        getFilterDataByName(filter, tenantId, ui).then((json) => {
                                            applyFilters(json);
                                        });
                                    }
                                } else {
                                    setBody([]);
                                    setChipData([]);
                                }
                            }}
                            onModalClose={(e) => {
                                setShowModal(false);
                                setAttributeData([]);
                                setFilterName("");
                                setChipClicked(false);
                            }}
                            typoLabel="Account Hub"
                            showModal={showModal}
                            attributeData={attributeData}
                            options={filterOptions}
                            attributesValueURL={`${ATTRIBUTE_URL}/getattribute?tenantId=${tenantId}`}
                            icp={icp}
                            chipClicked={chipClicked}
                            setChipClicked={setChipClicked}
                            chipToFocus={chipToFocus}
                            accChipArray={chipData}
                            tenantConfigMapper={tenantConfigMapper}
                            body={body}
                            screen="isAccountHub"
                        />)
                        }   
                        </div>                
                <CreateTab tabVal={tabVal} setTabVal={setTabVal} segmentName={accSegment}  tenantId={tenantId} 
                requestBody={body} icpSelected={icp} setIntentNewAccount={setIntentNewAccount} intentNewAccount={intentNewAccount} 
                chipData={chipData} setChipData={setChipData} setFilterName={setFilterName}  setBody={setBody} 
                appliedFilterAttr={appliedFilterAttr} setAppliedFilterAttr={setAppliedFilterAttr} setChipToFocus={setChipToFocus} 
                setShowModal={setShowModal} setChipClicked={setChipClicked} setAttributeData={setAttributeData} tenantConfigMapper={tenantConfigMapper}
                 contTenantConfigMapper={contTenantConfigMapper} downldRequiredFieldsAcc={downldRequiredFieldsAcc} downldRequiredFieldsCont={downldRequiredFieldsCont}
                  setShowCreateTab={setShowCreateTab} showCreateTab={showCreateTab} createCardPopup={createCardPopup} setCreateCardPopup={setCreateCardPopup} 
                  doRefresh={doRefresh} setDoRefresh={setDoRefresh} softRefresh={softRefresh}
                  />

                    </div>
                    
             <div style={{ display: "flex", flexWrap: "wrap", width: "inherit", alignItems: "center" }}>
                    {/* {chipData.map((data, index) => {
                        return (
                            <div key={data.key} style={{ margin: "1px" }}>
                                <Tooltip title={data.value}>
                                    <Chip
                                        style={{ margin: "10px" }}
                                        variant="outlined"
                                        color="primary"
                                        size="medium"
                                        label={data.label}
                                        onDelete={handleDelete(data)}
                                        onClick={handleChipClick(data)}
                                    />
                                </Tooltip>
                            </div>
                        );
                    })}
                     */}
                    {/* <Dialog
                        open={openConfirmation}
                        onClose={openConfirmation}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{question}</DialogTitle>
                        <DialogContent>
                            <div style={{ display: "flex" }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{ margin: "10%" }}
                                    onClick={(e) => {
                                        setOpenConfirmation(false);
                                        setShowModal(false);
                                        setFilterName("");
                                    }}
                                >
                                    No
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ margin: "10%" }}
                                    onClick={(e) => {
                                        setOpenConfirmation(false);
                                        if (action === "SAVE") {
                                            saveData("edit", attributeData, filterName);
                                        } else if (action === "SAVE_APPLY") {
                                            saveData("save", attributeData, filterName);
                                        } else {
                                            applyFilters(attributeData);
                                        }
                                        // setShowModal(false);
                                    }}
                                >
                                    Yes
                                </Button>
                            </div>
                        </DialogContent>
                    </Dialog> */}
                    {/* <Snackbar
                        open={openSnackbar}
                        autoHideDuration={2000}
                        onClose={closeSnackbar}
                    >
                        <Alert onClose={closeSnackbar} severity={severity}>
                            {message}
                        </Alert>
                    </Snackbar> */}
                </div>

                    
                
                
                </div>
                    
                    {/* <FormControl
                        size="small"
                        variant="outlined"
                        className={classes.formControl}
                    > */}
                        {/* <InputLabel
                        htmlFor="icp-select"
                        shrink
                        style={{ background: "white", padding: "0  4px" }}
                    >
                        ICP
                    </InputLabel> */}
                        {/* <Select
                            id="icp-select"
                            variant="outlined"
                            style={{ width: 177, height: 42, backgroundColor: "white" }}
                            value={icp}
                            //defaultValue={icp}
                            onChange={(event, data) => {
                                setIcp(event.target.value);
                            }}
                            renderValue={(value) => <label>{value}</label>}
                        >
                            {icpList.map((data, index) => (
                                <MenuItem value={data}>{data}</MenuItem>
                            ))}
                        </Select> */}

                    {/* </FormControl> */}
                    
                </div>
               {/* <FunnelChart requestBody={body} icpSelected={icp} /> */}
                {/* <TotalAccount AccountCount={total =>SetTotalAccount(total)} requestBody={body} icpSelected={icp} /> */}

                {/* {(!userDetails?.user?.superUser && (userDetails?.user?.department && (userDetails?.user?.department.trim().toLowerCase() === "IS Executive".trim().toLowerCase() || userDetails?.user?.department.trim().toLowerCase() === "FS Executive".trim().toLowerCase()))) ? null : <ColumnChart requestBody={body} icpSelected={icp} />} */}

            
            </div>
            
            <SettingPop settingpopUp={settingpopUp} setSettingpopUp={settingpopUp} handleSetting={handleSetting} handleSaveSetting={handleSaveSetting} tenantId={tenantId}/>
            </ThemeProvider>
        // </MuiThemeProvider>
    );
}

export default FunnelReportPage
