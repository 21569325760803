import React from 'react';
import {
    MenuItem,
    Select
} from '@material-ui/core/'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

const SegmentSelect = (props) => {
    console.log("props in SegmentSelect");
    console.log(props)

    const classes = props.classes;

    return (
        <Select
            className = {[classes.Rect5, classes.rect5Text]}
            id = {props.id}
            variant = "outlined"
            style = {props.style}
            value = {props.value}
            IconComponent={KeyboardArrowDownRoundedIcon}
            onChange = {props.onChange}
            MenuProps = {
                {
                    PaperProps: {
                        style: {
                            borderRadius: "16px",
                            background: "#ffffff"
                        }
                    },
                    getContentAnchorEl: () => null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "center"
                    },
                }
            }
            inputProps = {
                {
                    classes: {
                        icon: classes.vector,
                        value: classes.grp29text,
                    },
                }
            }
            renderValue={
                (value) => <label className = {classes.grp29text}> {value} </label>
            }
        >
            {
                props.loglist.reverse()
                    .filter(data => data.type === props.dataType)
                    .map(
                        (data) => (
                            <MenuItem
                                key = {data.listTitle}
                                value = {data.listTitle}
                                classes = {
                                    {
                                        selected: classes.selected
                                    }
                                }
                            >
                                {data.listTitle}
                            </MenuItem>
                        )
                    )
            }
        </Select>
    )
}

export default SegmentSelect;