import React, { useState, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Autocomplete as Autocomplete2 } from "@material-ui/lab";
import { Popper } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import ClearIcon from "@mui/icons-material/Clear";
import _ from "lodash";

import { FilterContext } from "./../index";
import { B2_INTENT_SETTING } from "./../../Constant";

const PopperMy = function (props) {
  return (
    <Popper
      {...props}
      style={{ maxWidth: "1500px", minWidth: "180px" }}
      placement="bottom-start"
    />
  );
};

const AttributeComponent = (props) => {
  const {
    subfilter,
    attributeOptions,
    attributeData,
    typedValue,
    setTypedValue,
    onClickCheck,
    elementIndex,
  } = props;
  const [attributeDropdownOpen, setAttributeDropdownOpen] = useState(false);

  const { onClearAttributes } = useContext(FilterContext);

  const handleClearClick = (val) => {
    setTypedValue("");
    onClearAttributes(elementIndex, true);
  };

  return (
    <>
      {subfilter ? (
        <Autocomplete2
          id="grouped-demo1"
          options={attributeOptions}
          color="primary"
          getOptionLabel={(option) => {
            return option.label ?? option.attribute ?? "";
          }}
          style={{
            minWidth: "180px",
            height: "36px",
            display: "flex",
            "flex-direction": "column",
            "align-items": "flex-start",
            padding: "0px",
          }}
          size="small"
          value={typedValue || attributeData}
          // disableClearable={typedValue === "" || typedValue?.attribute === ""}
          disableClearable={true}
          popupIcon={() => (
            <KeyboardArrowDownRoundedIcon
              style={{ color: "rgba(3, 3, 3, 0.6)" }}
            />
          )}
          renderInput={(params) => (
            <div
              style={{
                display: "flex",
                position: "relative",
                width: "100%",
              }}
            >
              {(typedValue || attributeData)?.logo && (
                <img
                  style={{
                    position: "absolute",
                    transform: "translateY(60%)",
                    marginLeft: "5px",
                    marginTop: "2px",
                    height: "24px",
                    width: "24px",
                    borderRadius: "50%",
                  }}
                  src={`${B2_INTENT_SETTING}/logos/${
                    (typedValue || attributeData)?.logo?.toLowerCase() ?? ""
                  }.png`}
                  alt=""
                />
              )}
              <TextField
                {...params}
                style={{
                  fontFamily: "Nunito Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "12px",
                  display: "flex",
                  alignItems: "center",
                  letterSpacing: "0.15px",
                  color: "rgba(3, 3, 3, 0.6)",
                  flex: "none",
                  order: 0,
                  alignSelf: "stretch",
                  paddingTop: "0.8px",
                  flexGrow: 0,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                placeholder="Sub-Attributes"
                variant="outlined"
                className="input"
                InputProps={{
                  ...params.InputProps,
                  style: {
                    paddingLeft: (typedValue || attributeData)?.logo
                      ? "30px"
                      : "6px",
                  },
                  endAdornment: (
                    <div
                      style={{
                        marginRight: "-30px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ClearIcon
                        className="clear-icon"
                        style={{
                          color: "rgba(3, 3, 3, 0.6)",
                          height: "20px",
                          cursor: "pointer",
                          display:
                            typedValue === "" || typedValue?.attribute === ""
                              ? "none"
                              : "",
                        }}
                        onClick={handleClearClick}
                      />
                      <KeyboardArrowDownRoundedIcon
                        style={{
                          color: "rgba(3, 3, 3, 0.6)",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  ),
                }}
              />
            </div>
          )}
          renderOption={(option) => (
            <div
              // key={index}
              style={{
                display: "flex",
                marginLeft: "-10px",
                width: "-webkit-fill-available",
                // gap: "10px",
              }}
              onClick={() => {
                onClickCheck(option.label);
              }}
            >
              {option.logo && (
                <img
                  style={{
                    height: "24px",
                    width: "24px",
                    padding: "2px",
                    backgroundColor: "white",
                    borderRadius: "4px",
                    marginRight: "8px",
                  }}
                  src={`${B2_INTENT_SETTING}/logos/${
                    option?.logo ? option.logo.toLowerCase() : ""
                  }.png`}
                />
              )}
              <div
                style={{
                  // maxWidth: "130px",
                  // overflow: "hidden",
                  whiteSpace: "nowrap",
                  // textOverflow: "ellipsis",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontStyle: "normal",
                  fontWeight: "400",
                }}
              >
                {option.label}
              </div>
            </div>
          )}
        />
      ) : (
        <Autocomplete
          id="grouped-demo1"
          options={attributeOptions}
          groupBy={(option) => option.groupLabel}
          PopperComponent={PopperMy}
          color="primary"
          getOptionLabel={(option) => {
            return option.label ?? option.attribute ?? "";
          }}
          style={{
            minWidth: "180px",
            height: "36px",
            display: "flex",
            "flex-direction": "column",
            "align-items": "flex-start",
            padding: "0px",
          }}
          size="small"
          value={typedValue}
          disableClearable={typedValue === "" || typedValue?.attribute === ""}
          popupIcon={
            <KeyboardArrowDownRoundedIcon
              style={{ color: "rgba(3, 3, 3, 0.6)" }}
            />
          }
          onChange={handleClearClick}
          open={attributeDropdownOpen}
          onOpen={() => setAttributeDropdownOpen(true)}
          onBlur={() => setAttributeDropdownOpen(false)}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{
                fontFamily: "Nunito Sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "12px",
                display: "flex",
                alignItems: "center",
                letterSpacing: "0.15px",
                color: "rgba(3, 3, 3, 0.6)",
                flex: "none",
                order: 0,
                alignSelf: "stretch",
                paddingTop: "0.8px",
                flexGrow: 0,
              }}
              placeholder="Attributes"
              variant="outlined"
              className="input"
            />
          )}
          renderGroup={(option) => (
            <div className="render-group-container">
              <Typography
                variant="overline"
                component="Ag"
                className="groupcss"
                style={{ fontSize: "12px" }}
              >
                {option.group}
              </Typography>

              <div className="render-group-body">
                {option.children.map((topic, index) => (
                  <div
                    key={index}
                    className="render-group-list"
                    onClick={() => {
                      onClickCheck(topic.props.children); // topic.props.children => lable
                      setAttributeDropdownOpen(false);
                    }}
                  >
                    <div>
                      <p>{topic?.props.children}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        />
      )}
    </>
  );
};

export default AttributeComponent;
