import React from "react";
import "./ColumnChart.css";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
import { UNIFIED_URL, UNIFIED_FRONTEND_URL } from "../Constant";
import { useAuthState } from "@bamboobox/b2logincheck";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import FrequencyToggler from "./FrequencyToggler";
import AccountContactToggler from "./AccountContactToggler";
import TimeSpanComponent from "./TimeSpanComponent";
import { MuiThemeProvider, Typography } from "@material-ui/core";
import UItheme from "@bamboobox/b2-theme";
import { changeFromToDate } from "./../dateTimeFunctionRepository"
import { Paper, withStyles, Tooltip } from "@material-ui/core"
import moment from "moment";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useLocation } from "react-router-dom";
import _ from "lodash";

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);




const ColumnChart = (props) => {
    const controllerRef = React.useRef()
    var threeWeeksOldDate = new Date();
    threeWeeksOldDate.setDate(threeWeeksOldDate.getDate() - 21);

    const userDetails = useAuthState();
    const tenantId = userDetails.user.tenantId;
    const [chartConfigs, setChartConfigs] = React.useState([]);
    const [selectedFrequency, setSelectedFrequency] = React.useState("Weekly");
    const [selectedType, setSelectedType] = React.useState("Account");
    const [ChartData, setChartData] = React.useState([]);
    const [fromDate, setFromDate] = React.useState(threeWeeksOldDate);
    const [toDate, setToDate] = React.useState(new Date());
    const [tabVal,setTabVal] = React.useState(0);
    const [tabVal2,setTabVal2] = React.useState(0);
    let location = useLocation();
    function useQuery() {
        return new URLSearchParams(location.search);
    }
    let GetTabVal = () => {
        return useQuery().get("tabVal");
    }
    let sumOfNewAccount = 0;

    // const cloneFilter = (fitlerBody) => {
    //     let clonedBody = _.cloneDeep(fitlerBody)
    //     let pastObj = {Week: 7, Month: 30, Quarter: 90, Year: 365}
    //     for(let i = 0; i < clonedBody.length; i++){
          
    //       if(clonedBody[i]?.relation.toLowerCase() === 'in the past'){
    //         clonedBody[i].relation = 'after'
    //         let fromDate = pastObj[clonedBody[i]?.values[0]]
    //         let dateTime = (new Date(Date.now() - fromDate * 24 * 60 * 60 * 1000)).toISOString()
    //         clonedBody[i].values[0] = dateTime.substring(0, 11) + `00:00:00.000Z`;

    //       }
    //     }
    //     return clonedBody;
    //   }

    const setAttributeData = (fromDateProcessed, toDateProcessed) => {
        let newChartData = [];
        if (controllerRef.current) {
            controllerRef.current.abort();
          }
          const controller = new AbortController();
          controllerRef.current = controller;
      
        fetch(
            `${UNIFIED_URL}/analytics/newrecords/${selectedType}/${tenantId}/${props.icpSelected
            }?fromdate=${fromDateProcessed}&todate=${toDateProcessed}&filterName=${props.segmentName}`,
            {
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({"filters": props.requestBody}),
                signal:controllerRef.current?.signal,
                credentials: "include",
            }
        )
            .then((response) => response.json())
            .then((typeData) => {
                console.log("columns jsx response from api", typeData);
                if(!typeData.msg){
                    typeData?.forEach((obj) => {
                        let typeDataObject = {
                            label: "",
                            value: "",
                        };
                        typeDataObject.label = obj?.date;
                        typeDataObject.value = obj?.count;
                        sumOfNewAccount += parseInt(obj?.count)
                        newChartData.push(typeDataObject);
                    });
                }
                

                console.log("columns jsx setting chart Data", newChartData);
                setChartData(newChartData);
                processData(newChartData);
            }).catch((error)=>{
                if(error?.message === "The user aborted a request.") return;
              });
    };

    const processData = (newAccChartData) => {
        let chartTempData;

        function calOfWeekDuration(obj) {
            let initialDate = obj
            let finalDate = moment(obj).add(6, 'days');
            finalDate = moment(finalDate).format("DD MMM, YY")
            initialDate = moment(initialDate).format("DD MMM, YY")
            return `${initialDate} - ${finalDate}`
        }

        const addLinkToDataPlot = (chartTempData) => {
            Object.values(chartTempData).forEach(obj => {
                let filterObjNewRecord = [{
                    "attribute": "",
                    'type':'accounts',
                    "relation": "between",
                    "values": []
                }]
                let normalizedDate = {}
                if (!(Array.isArray(props.requestBody) && !props.requestBody.length)) {
                    props.requestBody.forEach(reqBody => {
                        filterObjNewRecord.push(reqBody)
                    })
                }
                filterObjNewRecord[0].attribute = "accCreatedAt"

                if (selectedFrequency === "Weekly") {

                    let newFromDate = new Date(obj.label)
                    var fromDateValue = new Date(Date.UTC(newFromDate.getFullYear(), newFromDate.getMonth(), newFromDate.getDate()))
                    console.log(`%c${fromDateValue}`, 'background:yellow;color:green;')
                    normalizedDate = changeFromToDate(fromDateValue, fromDateValue, selectedFrequency)
                    console.log(`%c${normalizedDate[0]} ${normalizedDate[1]}`, 'background:yellow;color:green;')
                    filterObjNewRecord[0].values = normalizedDate

                } else if (selectedFrequency === "Monthly") {

                    var monthStart = "01-" + obj.label.substring(0, 3) + "-" + (parseInt(obj.label.substring(5, 7)) + 2000)
                    monthStart = new Date(monthStart)
                    console.log(`%c ${monthStart}`, 'background:yellow;color:green;')
                    const fromDateValue = new Date(Date.UTC(monthStart.getFullYear(), monthStart.getMonth(), monthStart.getDate()))
                    normalizedDate = changeFromToDate(fromDateValue, fromDateValue, selectedFrequency)
                    console.log(`%c${normalizedDate[0]} ${normalizedDate[1]}`, 'background:yellow;color:green;')
                    filterObjNewRecord[0].values = normalizedDate

                } else {

                    var quaterStart = "01-" + obj.label.substring(0, 3) + "-" + (parseInt(obj.label.substring(8, 10)) + 2000)
                    quaterStart = new Date(quaterStart)
                    const fromDateValue = new Date(Date.UTC(quaterStart.getFullYear(), quaterStart.getMonth(), quaterStart.getDate()))
                    normalizedDate = changeFromToDate(fromDateValue, fromDateValue, selectedFrequency)
                    console.log(`%c${normalizedDate[0]} ${normalizedDate[1]}`, 'background:yellow;color:green;')
                    filterObjNewRecord[0].values = normalizedDate

                }
                obj["link"] = `${UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(JSON.stringify(filterObjNewRecord)))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(JSON.stringify(props.requestBody)))}/?icp=${props.icpSelected||''}&segmentName=${props.segmentName}&tabVal=${props.tabVal}`
            })
        }
        const addContactLinkToDataPlot = (chartTempData) => {
            Object.values(chartTempData).forEach(obj => {
                let filterObjNewRecord = [{
                    "attributeId":"1232efsdqwe2",
                    "attribute": "",
                    'type':'contacts',
                    "relation": "between",
                    "values": []
                }]
                let normalizedDate = {}
                if (!(Array.isArray(props.requestBody) && !props.requestBody.length)) {
                    props.requestBody.forEach(reqBody => {
                        filterObjNewRecord.push(reqBody)
                    })
                }
                filterObjNewRecord[0].attribute = "contactCreatedAt"

                if (selectedFrequency === "Weekly") {

                    let newFromDate = new Date(obj.label)
                    var fromDateValue = new Date(Date.UTC(newFromDate.getFullYear(), newFromDate.getMonth(), newFromDate.getDate()))
                    console.log(`%c${fromDateValue}`, 'background:yellow;color:green;')
                    normalizedDate = changeFromToDate(fromDateValue, fromDateValue, selectedFrequency)
                    console.log(`%c${normalizedDate[0]} ${normalizedDate[1]}`, 'background:yellow;color:green;')
                    filterObjNewRecord[0].values = normalizedDate

                } else if (selectedFrequency === "Monthly") {

                    var monthStart = "01-" + obj.label.substring(0, 3) + "-" + (parseInt(obj.label.substring(5, 7)) + 2000)
                    monthStart = new Date(monthStart)
                    console.log(`%c ${monthStart}`, 'background:yellow;color:green;')
                    const fromDateValue = new Date(Date.UTC(monthStart.getFullYear(), monthStart.getMonth(), monthStart.getDate()))
                    normalizedDate = changeFromToDate(fromDateValue, fromDateValue, selectedFrequency)
                    console.log(`%c${normalizedDate[0]} ${normalizedDate[1]}`, 'background:yellow;color:green;')
                    filterObjNewRecord[0].values = normalizedDate

                } else {

                    var quaterStart = "01-" + obj.label.substring(0, 3) + "-" + (parseInt(obj.label.substring(8, 10)) + 2000)
                    quaterStart = new Date(quaterStart)
                    const fromDateValue = new Date(Date.UTC(quaterStart.getFullYear(), quaterStart.getMonth(), quaterStart.getDate()))
                    normalizedDate = changeFromToDate(fromDateValue, fromDateValue, selectedFrequency)
                    console.log(`%c${normalizedDate[0]} ${normalizedDate[1]}`, 'background:yellow;color:green;')
                    filterObjNewRecord[0].values = normalizedDate

                }
                obj["link"] = `${UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(JSON.stringify(filterObjNewRecord)))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(JSON.stringify(props.requestBody)))}/?icp=${props.icpSelected||''}&segmentName=${props.segmentName}&tabVal=${props.tabVal}&tab=contact`;
            })
        }

        const addHoverText = (chartTempData) => {
            Object.values(chartTempData).forEach(obj => {
                let durationForWeekly = calOfWeekDuration(obj.label)
                obj["tooltext"] = `<div style="padding: 5px">New ${selectedType}s<br><b style="font-size: 14px">${obj.value}</b> <br><br>Duration<br> <b style="font-size: 14px">${selectedFrequency === "Weekly" ? durationForWeekly : obj.label}</b><br><br>Total New ${selectedType}s<br> <b style="font-size: 14px">${sumOfNewAccount}</b></div>`
            })

        }

        if (selectedFrequency === "Weekly") {
            chartTempData = transformToWeekly(newAccChartData)

            if (selectedType === "Account") {
                addLinkToDataPlot(chartTempData)
            }
            else{
                addContactLinkToDataPlot(chartTempData)
            }

            addHoverText(chartTempData)
        } else if (selectedFrequency === "Monthly") {
            chartTempData = transformToMonthly(newAccChartData);
            if (selectedType === "Account") {
                addLinkToDataPlot(chartTempData)
            }
            else{
                addContactLinkToDataPlot(chartTempData)
            }
            addHoverText(chartTempData)
        } else {
            chartTempData = transformToQuaterly(newAccChartData);
            if (selectedType === "Account") {
                addLinkToDataPlot(chartTempData)
            }
            else{
                addContactLinkToDataPlot(chartTempData)
            }
            addHoverText(chartTempData)
        }

        console.log("columns jsx processing data", chartTempData)

        setChartConfigs({
            type: "column2d",
            width: "40%",
            height: "400",
            dataFormat: "json",
            dataSource: {
                chart: {
                    yaxisname: `NUMBER OF ${selectedType.toUpperCase()}S`,
                    xAxisName: "DAY RANGE",
                    theme: "fusion",
                    showValues: "1",
                    placeValuesInside: "0",
                    palettecolors: "#53F9B2",
                    usePlotGradientColor: "1",
                    plotGradientColor: "#21DA8C",
                    chartTopMargin: "20",
                    "toolTipBorderRadius": "10",
                    "toolTipBgColor": "#FFFFFF",
                    "toolTipBgAlpha": "100",
                    "showToolTipShadow": "1",
                    "xAxisNameFontSize": "12",
                    "yAxisNameFontSize": "12",
                    "valueFontSize": "12",
                    "outCnvBaseFontSize": "12",
                    "xAxisNameFontColor": "#A0A0A0",
                    "yAxisNameFontColor": "#A0A0A0",
                    "xAxisNameFontBold": "1",
                    
                },
                data: chartTempData,
            },
            // events: {
            //     'chartClick': function (eventObj, args) {
            //         console.log(eventObj.eventType + " was raised by the chart whose ID is " + eventObj.sender.id);

            //         let filterObjNewRecord = [{
            //             "attribute": "",
            //             "relation": "includes",
            //             "values": []
            //         }]
            //         let normalizedDate = {}



            //         if (selectedType === "Account") {
            //             filterObjNewRecord[0].attribute = "Account Created At"
            //             normalizedDate = changeFromToDate(fromDate, toDate, selectedFrequency)
            //             filterObjNewRecord[0].values = normalizedDate
            //             console.log(normalizedDate)
            //             if (!(Array.isArray(props.requestBody) && !props.requestBody.length)) {
            //                 props.requestBody.forEach(reqBody => {
            //                     filterObjNewRecord.push(reqBody)
            //                 })
            //             }
            //             console.log(filterObjNewRecord)
            //             let newRecordAccountUrl = `${UNIFIED_FRONTEND_URL}/#/filter/${escape(JSON.stringify(filterObjNewRecord))}/analytics_dashboard/${escape(JSON.stringify(props.requestBody))}`
            //             window.location.href = newRecordAccountUrl

            //         }

            // }
            //}
        });
    };

    function getQuarter(d) {
        console.log(d);
        let newMonth = parseInt(d.split(" ")[0]) - 1  // If no date supplied, use today
        console.log(newMonth);
        let quaterArray = ["Jan-Mar", "Apr-Jun", "Jul-Sep", "Oct-Dec"];
        return `${quaterArray[Math.floor(newMonth / 3)]} ${d.substring(3, 7).toString().substring(2, 4)}`;
    }

    function transformToWeekly(data) {
        data = data.map((d) => [getWeekStartDate(d.label), d.value]);
        let data2 = data.reduce(
            (m, [week, value]) => m.set(week, (m.get(week) || 0) + parseInt(value)),
            new Map()
        );
        data2 = Array.from(data2, ([label, value]) => ({ label, value }));
        return data2;
    }

    function transformToMonthly(data) {
        let monthArray = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        data = data.map((d) => [
            `${monthArray[parseInt(d.label.substring(5, 7)) - 1]}, ${d.label.substring(
                0,
                4
            ).toString().substring(2, 4)}`,
            d.value,
        ]);
        let data2 = data.reduce(
            (m, [month, value]) =>
                m.set(month, (m.get(month) || 0) + parseInt(value)),
            new Map()
        );
        data2 = Array.from(data2, ([label, value]) => ({ label, value }));
        return data2;
    }

    function transformToQuaterly(data) {
        data = data.map((d) => [
            `${d.label.substring(5, 7)} ${parseInt(d.label.substring(0, 4))}`,
            d.value,
        ]);
        data = data.map((d) => [getQuarter(d[0]), d[1]]);
        var data2 = data.reduce(
            (m, [quater, value]) =>
                m.set(quater, (m.get(quater) || 0) + parseInt(value)),
            new Map()
        );
        data2 = Array.from(data2, ([label, value]) => ({ label, value }));
        return data2;
    }

    function getWeekStartDate(dateString) {
        let monthArray = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        let d = new Date(dateString);
        let dayNo = d.getDay();
        if (dayNo == 0) dayNo = 7;
        d.setDate(d.getDate() - (dayNo - 1));
        return `${d.getDate()} ${monthArray[d.getMonth()]}, ${d.getFullYear().toString().substring(2, 4)}`;
    }

    React.useEffect(() => {
        let [fromDateProcessed, toDateProcessed] = changeFromToDate(fromDate, toDate, selectedFrequency)
        if (props.icpSelected !== null && props.segmentName !== '') setAttributeData(fromDateProcessed, toDateProcessed);
        console.log("columns jsx getting data from api", ChartData)

        console.log("columns jsx processing data of api")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.requestBody, props.icpSelected,props.segmentName, selectedType, fromDate, toDate, selectedFrequency]);

    // React.useEffect(() => {
    // if (!(Array.isArray(ChartData) && !ChartData.length))
    // processData(ChartData);
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedFrequency]);

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: "rgb(236, 247, 254)",
            color: UItheme.palette.primary.dark,
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);

    return (
        <MuiThemeProvider theme={UItheme}>
            <div className="container-align">
            <Paper elevation={3} className='chart-container'  style={{ borderRadius:"18px", minHeight: "400px", height:"480px", background: "#FFFFFF", boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",}}>
                    <div className="row" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div className="column" style={{ display: "flex", height: "56px" }}>
                            <Typography  style={{ display:"flex", marginRight:"auto", height: "50%", gap:"8px", fontWeight:"bold", color:"#595959", fontSize:"16px",width:"230px", marginTop:"18px"}}>
                                New Records<LightTooltip
                                    style={{ lineHeight: "inherit" }}
                                    title={`You can see number of New ${selectedType}s added`}>
                                    <InfoOutlinedIcon style={{fill: "#A0A0A0"}}/>
                                </LightTooltip>
                            </Typography>
                                
                            </div>
                            <TimeSpanComponent className="column" 
                                frequencySelected={selectedFrequency}
                                setToDate={setToDate}
                                setFromDate={setFromDate}
                                enableToRange={true}
                                fromDate={fromDate} toDate={toDate}
                            />
                        </div>
                    <div className="row" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <AccountContactToggler className="column" style={{marginRight: "auto"}} setSelectedType={setSelectedType} tabVal={tabVal} setTabVal={setTabVal} />
                        <FrequencyToggler className="column" style={{marginLeft: "auto"}} tabVal2={tabVal2} setTabVal2={setTabVal2} setSelectedFrequency={setSelectedFrequency} fromDate={changeFromToDate(fromDate, toDate, selectedFrequency)[0]} toDate={changeFromToDate(fromDate, toDate, selectedFrequency)[1]} />
                    </div>
                        {/* <div style={{ display: "flex", alignItems: "center", width: "fit-content" }}> */}
                            
                        {/* </div> */}
                    
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        {Array.isArray(chartConfigs) && !chartConfigs.length ? (
                            <React.Fragment>Loading...</React.Fragment>
                        ) : (
                            <React.Fragment>
                                <ReactFC {...chartConfigs} />
                            </React.Fragment>
                        )}
                    </div>
                </Paper>
            </div>
        </MuiThemeProvider>
    );
};

export default ColumnChart;