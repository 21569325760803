export const pastChartData = [
  {
    label: "Unreached",
    color: "#E0E0E0",
  },
  {
    label: "Unengaged",
    color: "#c7c7c7",
  },
  {
    label: "Aware",
    color: "#b3b3b3",
  },
  {
    label: "Engaged",
    color: "#a19f9f",
  },
  {
    label: "Potential Opportunity",
    color: "#828282",
  },
  {
    label: "Active Opportunity",
     color: "#757575",
  }  
]


export const chartData = [
    {
      label: "Unreached",
      color: "#FB8BFE,#CF99F4",
      link: "newchart-json-Unreached"
    },
    {
      label: "Unengaged",
      color: "#6E8DFF,#AFA3ED",
      link: "newchart-json-Unengaged"
    },
    {
      label: "Aware",
      color: "#AAA5EC,#8CAEE6",
      link: "newchart-json-Aware"
    },
    {
      label: "Engaged",
      color: "#88B0E5,#6AB9DE",
      link: "newchart-json-Engaged"
    },
    {
      label: "Potential Opportunity",
      color: "#67BADD,#48C4D6",
      link: "newchart-json-Potential Opportunity"
    },
    {
      label: "Active Opportunity",
       color: "#45C5D5,#38C9D3",
      link: "newchart-json-Active Opportunity"
    }
  ];