import './App.css';
import React from "react";
import FunnelReportPage from "./FunnelReportPage";
import {
  HashRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

function App() {
  return (
  
    <div>
       <Router>
            <Switch>
                <Route exact path="/" component={FunnelReportPage} ></Route>
                <Route exact path="/filter/:id" component={FunnelReportPage} ></Route>
            </Switch>
        </Router>
    </div>
  );
}

export default App;
