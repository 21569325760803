import React from 'react';
import { useEffect, useState, useRef } from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import HeatMap from "fusioncharts/fusioncharts.charts";
import Widgets from "fusioncharts/fusioncharts.widgets";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
import { UNIFIED_URL, UNIFIED_FRONTEND_URL } from "./Constant";
import { Skeleton } from '@mui/material';
import _ from "lodash";

ReactFC.fcRoot(FusionCharts, HeatMap, Widgets, PowerCharts, FusionTheme);

const HeatMapChart = (props) => {
    const controllerRef = useRef();
    const [loading, setLoading] = useState(false)
    const [chartConfigs, setChartConfigs] = useState(undefined)
    const [count,setCount] = React.useState(1);
    const getIntentVsIcp = () => {
      setLoading(true)
      console.log("stageValue",props.stageValue)

      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      const controller = new AbortController();
      controllerRef.current = controller;
  
      let values = []
                    console.log("props.stageValue",props.stageValue)
                    if(props.stageValue==="All")
                       {
                           values = ["Unreached","Unengaged","Aware","Engaged","Potential Opportunity","Active Opportunity"]
                       }
                       else
                       {
                           values = [props.stageValue]
                       }
      const stagefilter=  [{attrId: "044sDVD7BPpJ",attribute: `b2AccountStage`,relation: "includes",values: values,type: "ACCOUNT"}]
    //   const clonedBody = cloneFilter(props.reqBody);
        fetch(
            `${UNIFIED_URL}/analytics/intent/intent/intent-vs-icp/${props.tenantId}/${props.icpSelected}?filterName=${props.segmentName}`,
            {
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({"filters": [...props.reqBody,...stagefilter]}),
                signal:controllerRef.current?.signal,
                credentials: "include",
            }
        )
            .then((data) => data.json())
            .then((data) => {
                setLoading(false)
                let tempArray = [
                    {
                        rowid:"VERY HIGH",
                        columnid:"VERY HIGH",
                        colorrangelabel:"VeryVeryHigh"
                    },
                    {
                        rowid:"VERY HIGH",
                        columnid:"HIGH",
                        colorrangelabel:"VERYHIGH"
                    },
                    {
                        rowid:"VERY HIGH",
                        columnid:"MEDIUM",
                        colorrangelabel:"MEDIUM"
                    },
                    {
                        rowid: "HIGH",
                        columnid: "MEDIUM",
                        colorrangelabel: "Medium"
                    },
                    {
                        rowid: "HIGH",
                        columnid: "HIGH",
                        colorrangelabel: "High"
                    },
                    {
                        rowid: "HIGH",
                        columnid: "VERY HIGH",
                        colorrangelabel: "VeryHigh"
                    },
                    {
                        rowid: "MEDIUM",
                        columnid: "MEDIUM",
                        colorrangelabel: "Low"
                    },
                    {
                        rowid: "MEDIUM",
                        columnid: "HIGH",
                        colorrangelabel: "Medium"
                    },
                    {
                        rowid: "MEDIUM",
                        columnid: "VERY HIGH",
                        colorrangelabel: "High"
                    },
                    {
                        rowid: "LOW",
                        columnid: "MEDIUM",
                        colorrangelabel: "VeryLow"
                    },
                    {
                        rowid: "LOW",
                        columnid: "HIGH",
                        colorrangelabel: "Low"
                    },
                    {
                        rowid: "LOW",
                        columnid: "VERY HIGH",
                        colorrangelabel: "Medium"
                    }
                ]
                let intentvsicpData = []
                tempArray.forEach(obj => {
                    let tempResult = data.find(object => {
                        return object.icp_level === obj.rowid && object.b2_intent_level === obj.columnid
                    })


                    let intentIcpObj = {
                        "rowid": "",
                        "columnid": "",
                        "displayvalue": "",
                        "colorrangelabel": "",
                        "link": ""
                    }
                    let values = []
                    console.log("props.stageValue",props.stageValue)
                    if(props.stageValue==="All")
                       {
                           values = ["Unreached","Unengaged","Aware","Engaged","Potential Opportunity","Active Opportunity"]
                       }
                       else
                       {
                           values = [props.stageValue]
                       }
                     
                       
                    console.log("values",values)
                    let dynamicFilterObject = [
                        {
                        "attrId": "4sDVD7BPpJ",    
                        "attribute":"icp_level",
                        "type":"accounts","relation":"includes",
                        "values":[obj.rowid]},
                       {
                        attrId: "044sDVD7BPpJ",
                        attribute: `b2AccountStage`,
                        relation: "includes",
                        values: values,
                        type: "ACCOUNT"
                      },
                    { "attrId": "DVD7BPpJ","attribute": "b2_intent_level", "type": "intent", "relation": "includes", "values": [obj.columnid] },...props.reqBody]


                    intentIcpObj.rowid = obj.rowid
                    intentIcpObj.columnid = obj.columnid
                    intentIcpObj.displayvalue = tempResult.num_of_acc
                    intentIcpObj.colorrangelabel = obj.colorrangelabel
                    intentIcpObj.link = `${UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(
                        JSON.stringify(dynamicFilterObject,...props.reqBody)
                      ))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(
                        JSON.stringify(props.reqBody)
                      ))}/?icp=${props.icpSelected}&segmentName=${props.segmentName}&tabVal=${props.tabVal}&attribute=${props.stageValue}`;


                    console.log("reqBody",`${UNIFIED_FRONTEND_URL}/#/filter/${
                        JSON.stringify(dynamicFilterObject,...props.reqBody)
                      }/analytics_dashboard/${
                        JSON.stringify(props.reqBody)
                      }/?icp=${props.icpSelected}&segmentName=${props.segmentName}&tabVal=${props.tabVal}&attribute=${props.stageValue}`)
                    intentvsicpData.push(intentIcpObj)

                })
                let chartConfiguration = {
                    type: "heatmap",
                    // width: "495",
                    // height: "408",
                    width: "90%",
                    height: "380",
                    dataFormat: "JSON",
                    dataSource: {
                        colorrange: {
                            
                            gradient: "0",
                            minvalue: "0",
                            code: "F28C8C",
                            startlabel: "VeryLow",
                            endlabel: "VerHigh",
                            color: [
                                {
                                    code: "FB63FE",
                                    minvalue: "0",
                                    maxvalue: "50",
                                    label: "VeryLow"
                                },
                                {
                                    code: "79ACE9",
                                    minvalue: "50",
                                    maxvalue: "70",
                                    label: "Low"
                                },
                                {
                                    code: "3DC7D4",
                                    minvalue: "70",
                                    maxvalue: "85",
                                    label: "Medium"
                                },
                                {
                                    code: "3DC7D4",
                                    minvalue: "70",
                                    maxvalue: "85",
                                    label: "High"

                                },
                                {
                                    code: "21DAB8",
                                    minvalue: "70",
                                    maxvalue: "85",
                                    label: "VeryHigh"
                                },{
                                    code: "52F8B1",
                                    minvalue: "70",
                                    maxvalue: "85",
                                    label: "VeryVeryHigh"
                                }
                            ]
                        },
                        dataset: [
                            {
                                data: intentvsicpData
                            }
                        ],
                        columns: {
                            column: [
                                {
                                    id: "MEDIUM",
                                    label: "Medium"
                                },
                                {
                                    id: "HIGH",
                                    label: "High"
                                },
                                {
                                    id: "VERY HIGH",
                                    label: "Very High"
                                }
                            ]
                        },
                        rows: {
                            row: [
                                {
                                    id: "VERY HIGH",
                                    label:"VERY HIGH"
                                },
                                {
                                    id: "HIGH",
                                    label: "High"
                                },
                                {
                                    id: "MEDIUM",
                                    label: "Medium"
                                },
                                {
                                    id: "LOW",
                                    label: "Low"
                                }
                            ]
                        },
                        chart: {
                            theme: "fusion",
                            showLegend: "0",
                            showvalues: "1",
                            xAxisName: "INTENT LEVEL",
                            yAxisName: "ICP RANGE",
                            mapByCategory: "1",
                            plottooltext:
                            '<div style="padding: 5px">Number of Accounts<br><b style="font-size: 14px">$displayvalue</b> <br><br>ICP Level<br> <b style="font-size: 14px">$rowlabel</b><br><br>Intent Level<br> <b style="font-size: 14px">$columnlabel</b></div>',
                                // "$rowlabel's average temperature in $columnlabel is $displayvalue °F"   
                            "toolTipBorderRadius": "10",
                            "toolTipBgColor": "#FFFFFF",
                            "toolTipBgAlpha": "100",
                            "showToolTipShadow": "1",
                            valueFontColor:"#FFFFFF",
                            valueFontSize:"16"
                        }
                    }
                }
                console.log("chartConfiguration", chartConfiguration)
                setChartConfigs(chartConfiguration)

            }).catch((error) => {
                
                if(error?.message !== "The user aborted a request."){
                    const chartConfiguration = {
                        type: "heatmap",
                        width: "90%",
                        height: "408",
                        dataFormat: "JSON",
                        dataSource: {
                            colorrange: {
                                gradient: "0",
                                minvalue: "0",
                                code: "F28C8C",
                                startlabel: "VeryLow",
                                endlabel: "VerHigh",
                                color: [
                                    {
                                        code: "FB63FE",
                                        minvalue: "0",
                                        maxvalue: "50",
                                        label: "VeryLow"
                                    },
                                    {
                                        code: "79ACE9",
                                        minvalue: "50",
                                        maxvalue: "70",
                                        label: "Low"
                                    },
                                    {
                                        code: "3DC7D4",
                                        minvalue: "70",
                                        maxvalue: "85",
                                        label: "Medium"
                                    },
                                    {
                                        code: "3DC7D4",
                                        minvalue: "70",
                                        maxvalue: "85",
                                        label: "High"
    
                                    },
                                    {
                                        code: "21DAB8",
                                        minvalue: "70",
                                        maxvalue: "85",
                                        label: "VeryHigh"
                                    },{
                                        code: "52F8B1",
                                        minvalue: "70",
                                        maxvalue: "85",
                                        label: "VeryVeryHigh"
                                    }
                                ]
                            },
                            dataset: [
                                {
                                    data: [
                                        {
                                            rowid: "VERY HIGH",
                                            columnid: "MEDIUM",
                                            displayvalue: "0",
                                            colorrangelabel: "High"
                                        }, {
                                            rowid: "VERY HIGH",
                                            columnid: "HIGH",
                                            displayvalue: "0",
                                            colorrangelabel: "VeryHigh"
                                        }, {
                                            rowid: "VERY HIGH",
                                            columnid: "VERY HIGH",
                                            displayvalue: "0",
                                            colorrangelabel: "VeryVeryHigh"
                                        },
                                        {
                                            rowid: "HIGH",
                                            columnid: "MEDIUM",
                                            displayvalue: "0",
                                            colorrangelabel: "Medium"
                                        },
                                        {
                                            rowid: "HIGH",
                                            columnid: "HIGH",
                                            displayvalue: "0",
                                            colorrangelabel: "High"
                                        },
                                        {
                                            rowid: "HIGH",
                                            columnid: "VERY HIGH",
                                            displayvalue: "0",
                                            colorrangelabel: "VeryHigh"
                                        },
                                        {
                                            rowid: "MEDIUM",
                                            columnid: "MEDIUM",
                                            displayvalue: "0",
                                            colorrangelabel: "Low"
                                        },
                                        {
                                            rowid: "MEDIUM",
                                            columnid: "HIGH",
                                            displayvalue: "0",
                                            colorrangelabel: "Medium"
                                        },
                                        {
                                            rowid: "MEDIUM",
                                            columnid: "VERY HIGH",
                                            displayvalue: "0",
                                            colorrangelabel: "High"
                                        },
                                        {
                                            rowid: "LOW",
                                            columnid: "MEDIUM",
                                            displayvalue: "0",
                                            colorrangelabel: "VeryLow"
                                        },
                                        {
                                            rowid: "LOW",
                                            columnid: "HIGH",
                                            displayvalue: "0",
                                            colorrangelabel: "Low"
                                        },
                                        {
                                            rowid: "LOW",
                                            columnid: "VERY HIGH",
                                            displayvalue: "0",
                                            colorrangelabel: "Medium"
                                        }
                                    ]
                                }
                            ],
                            columns: {
                                column: [
                                    {
                                        id: "MEDIUM",
                                        label: "Medium"
                                    },
                                    {
                                        id: "HIGH",
                                        label: "High"
                                    },
                                    {
                                        id: "VERY HIGH",
                                        label: "Very High"
                                    }
                                ]
                            },
                            rows: {
                                row: [
                                    {
                                        id: "VERY HIGH",
                                        label: "VERY High"
                                    },
                                    {
                                        id: "HIGH",
                                        label: "High"
                                    },
                                    {
                                        id: "MEDIUM",
                                        label: "Medium"
                                    },
                                    {
                                        id: "LOW",
                                        label: "Low"
                                    }
                                ]
                            },
                            chart: {
                                theme: "fusion",
                                showLegend: "0",
                                showvalues: "1",
                                xAxisName: "INTENT",
                                yAxisName: "ICP",
                                mapByCategory: "1",
                                plottooltext:
                                "Number of Accounts: $displayvalue</br> ICP Level: $rowlabel</br> Intent Level: $columnlabel",
                                
    
                            }
                        }
                    }
                    setChartConfigs(chartConfiguration)
                    // toast.warn("No pending requests available", {
                    //     closeOnClick: true,
                    //     autoClose: true,
                    //     closeButton: true,
                    //     newestOnTop: true
                    // })
                    // setLoading(false);
                }
            })
    }

    useEffect(() => {
        //setLoading(true)
        if (props.icpSelected !== null && props.segmentName !== '') {
        console.log("stageValue",props.stageValue)
        getIntentVsIcp();
        }

    }, [
        props.icpSelected,
        props.segmentName,
        props.reqBody, 
        props.stageValue,
        props.count
    ]);

    window.addEventListener('resize',()=> setCount(count+1))
    return (
        
         loading ? (<Skeleton variant="rectangular" style={{ margin:"5%",borderRadius:"16px" }} width={500} height={296} />):(
            <div>
                <ReactFC {...chartConfigs} />
            </div>
    )) 
}

export default HeatMapChart

// [{"key":{"index":0},"attrId":"E63kyu2eapde6KvhvnJ","attribute":"Icp Level","type":"ACCOUNT","relation":"includes","values":["MEDIUM"]},
// {"attribute":"Intent Level","type":"INTENT","relation":"includes","values":["MEDIUM"]}
// ]
