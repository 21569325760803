import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {useEffect, useState} from "react";
import { Box, withStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    toggleContainer: {
        backgroundColor: theme.palette.primary.background,
        height: "38px",
        margin: theme.spacing(2, 0),
        borderRadius: "4px",
        border: `1px solid ${theme.palette.primary.light}`,
        selected: theme.palette.primary.light,
        marginLeft:"50px"
    },
    root: {
        selected: {
            backgroundColor: "#109CF1",
        }
    }
}));

export default function AccountContactToggler({tabVal,requestBody,icpSelected,tenantId, ...props}){

    const CustomTab = withStyles({
        root: {
          textTransform: "none",
          minWidth: "8vw",
          width: "8vw",
        }
      })(Tab);

    const [type, setType] = React.useState("Account");
    const [position, setPosition] = React.useState("left")

    function handleChange(e,newPosition) {
        props.setTabVal(newPosition);
        handleFrequency(newPosition)
      }

      const handleFrequency = (newOptionSelected) => {

        if (newOptionSelected !== null && newOptionSelected !== undefined) {
            if (newOptionSelected === 0) {
                setPosition(0)
                setType("Account")
                 props.setSelectedType("Account")
                
            }
            else if (newOptionSelected === 1) {
                setPosition(1)
                setType("Contact")
                props.setSelectedType("Contact")
            }           
        } 
        else {
            setType("Account")
            setPosition(0)
        }
        
    };

    const classes = useStyles();

    return (
        <Box sx={{ maxWidth: "20vw", borderBottomWidth:'0.2px', borderBottomColor: "rgb(211, 211, 211)"}}>
                <Tabs 
                style={{ borderBottom:'1px solid #D3D3D3', color:"#595959" }}
                indicatorColor="primary"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#21DA8C",
                    height:"6px",
                    borderRadius: "3px"
                   }
                  }}
                  //position
                  value={(Number(tabVal) || 0)}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
                variant="standard"
                >
                    
                    <CustomTab label="Account" ></CustomTab>
                    <CustomTab label="Contact" ></CustomTab>

                </Tabs>
                </Box>
        // <ToggleButtonGroup
        //     className={classes.toggleContainer}
        //     value={position}
        //     exclusive
        //     onChange={handleAccountContactSelector}
        //     aria-label="text alignment"
        // >
        //     <ToggleButton value="left" aria-label="left aligned">
        //         Account
        //     </ToggleButton>
        //     <ToggleButton value="right" aria-label="right aligned">
        //         Contact
        //     </ToggleButton>
        // </ToggleButtonGroup>
    );
}