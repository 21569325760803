import React from 'react';
import './TaskBarContainer.css';
import TaskBar from "./TaskBar";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { Typography } from '@material-ui/core';

const TaskBarContainer = (props) => {
    return (
        <div className='task-container'>
            
            <div className='sub-task-container'>
                <div style={{ paddingTop: '10px' , }}>
                    {props.TaskMapper.tasks.map((task) => {
                        return <TaskBar task={task} reqBody={props.reqBody} icpSelected={props.icpSelected} setIntentNewAccount={props.setIntentNewAccount} intentNewAccount={props.intentNewAccount}/>
                    })}
                </div>
        
            </div>
        </div>
    )
}

export default TaskBarContainer
