export const AttributeList = [
    {
    label: "Account Classification",
    value: "accountClassification",
    },
    {
    label: "Account Type",
    value: "accountType",
    },
    { 
    label: "Industry",
    value: "industry",
    },
    {
    label: "Sub Industry",
    value: "subIndustry",
    },
    {
    label: "Geography",
    value: "geography",
    },
    {
    label: "Account Region",
    value: "accountRegion",
    },
    {
    label: "Company Type",
    value: "companyType",
    },
    {
    label: "Company Hierarchy",
    value: "companyHierarchy",
    },
    {
    label: "Business Format",
    value: "businessFormat",
    } ,
    {
    label: "FS Executive",
    value: "fieldSalesExec",
    } ,
    {
    label: "IS Executive",
    value: "ISExec",
    },
    {
    label: "Account Owner",
    value: "accountOwner",
    },
    {
        label: "Technograhic Details",
        value: "technograhicDetails",
    },
    {
        label: "Crm Account Stage",
        value: "crmAccountStage",
    },
    {
        label: "Group Name",
        value: "groupName",
    },
    {
        label: "Fb URL",
        value: "fbURL",
    },
    {
        label: "Account Desc",
        value: "accountDesc",
    },
    {
        label: "City",
        value: "city",
    },
    {
        label: "State",
        value: "state",
    },
    {
        label: "Group Website",
        value: "groupWebsite",
    },
    {
        label: "Country",
        value: "country",
    },
    {
        label: "LinkedIn URL",
        value: "linkedInURL",
    },

] ;
    
    // export const DEFAULT_ATTRIBUTES_FOR_SUPERUSER = [
    // "Industry",  
    // "Account Classification",
    // "Geography",
    // ];
    
    // export const DEFAULT_ATTRIBUTES_FOR_OTHER_USER = [
    // "Industry",
    // "Account Classification",
    // "Account Type",
    // ];