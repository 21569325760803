import React, { useState, useEffect } from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import stackedcolumn2d from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { useAuthState } from "@bamboobox/b2logincheck";
import { UNIFIED_URL, UNIFIED_FRONTEND_URL } from "./Constant";

import { Skeleton } from "@mui/material";
// import { Default } from "react-toastify/dist/utils";
ReactFC.fcRoot(FusionCharts, stackedcolumn2d, FusionTheme);



const StackChart = (props) => {
  const [depts,setDepts] = useState()
  const [jobs,setJobs] = useState()
  const [stackData, setStackData] = useState([{}]);
  const [chartConfigs, setChartConfigs] = useState();
  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;

  const fetchAttributeValues = async() => {
    try {

      const tenantConfigResponse = await fetch(`${window._env_.TENANT_CONFIG}/tenant/field/mapper/get/${tenantId}/Journey-Analytics`,{credentials:"include"});
      const mappings_data = await tenantConfigResponse.json();
      setDepts(mappings_data.AllDepartments)
      setJobs(mappings_data.personaLevel)

    }
    catch (err) {
      console.log("error",err);
    }
  }

  useEffect(() => {
    fetchAttributeValues();
  },[depts,jobs]);

  const getLink = (persona, SeriesName) => {
    console.log("name", SeriesName);
    console.log("stacks", props.stackValue);


    if (props.stackValue === depts) {
      console.log("stacks inside department");
      if (props.stageValue !== "All") {
        var body = [
          {
            attrId: "jQzh72N_ZFcz4QiVmiR",
            values: [SeriesName],
            type: "contacts",
            attribute: `engscore_level`,
            relation: "includes",
          },
          {
            attrId: "jQzh72N_ZFcz4QiVmiR",
            attribute: `b2AccountStage`,
            relation: "includes",
            type: "accounts",
            values: [props.stageValue],
          },
          {
            attrId: "jQzh72N_ssdfZFcz4Qljfif",
            attribute: "contacts|personalevel",
            relation: "includes",
            type: "contacts",
            values: [persona],
          },
        ];
      } else {
        var body = [
          {
            attrId: "jQzh72N_ZFcz4QiVmiR",
            values: [SeriesName],
            type: "contacts",
            attribute: `engscore_level`,
            relation: "includes",
          },
          {
            attrId: "jQzh72N_ZFcz4QiVmiR",
            values: [
              "Unreached",
              "Unengaged",
              "Aware",
              "Engaged",
              "Potential Opportunity",
              "Active Opportunity",
            ],
            type: "ACCOUNT",
            attribute: `b2AccountStage`,
            relation: "includes",
          },
          {
            attrId: "jQzh72N_ssdfZFcz4Qljfif",
            attribute: "contacts|personalevel",
            relation: "includes",
            type: "contacts",
            values: (() => {
              if(persona.includes('/')) return [persona.replaceAll('/', '%2F')];
              return [persona];
            })(),
          },
        ];
      }
    } else {
  
      if (props.stageValue !== "All") {
        console.log("stacks outside department");

        var body = [
          {
            attrId: "jQzh72N_ZFcz4QiVmiR",
            values: [SeriesName],
            type: "contacts",
            attribute: `engscore_level`,
            relation: "includes",
          },
          {
            attrId: "jQzh72N_ZFcz4QiVmiR",
            attribute: `b2AccountStage`,
            relation: "includes",
            type: "ACCOUNT",
            values: [props.stageValue],
          },
          {
            attrId: "jQzh72N_ZFcz4Qljfif",
            attribute: "contacts|department",
            relation: "includes",
            type: "contacts",
            values: (() => {
              if(props.stackValue.includes('/')) return [props.stackValue.replaceAll('/', '%2F')];
              return [props.stackValue];
            })(),
          },
          {
            attrId: "jQzh72N_ssdfZFcz4Qljfif",
            attribute: "contacts|personalevel",
            type: "contacts",
            relation: "includes",
            values: (() => {
              if(persona.includes('/')) return [persona.replaceAll('/', '%2F')];
              return [persona];
            })(),
          },
        ];
      } else {
        var body = [
          {
            attrId: "jQzh72N_ZFcz4QiVmiR",
            values: [SeriesName],
            type: "contacts",
            attribute: `engscore_level`,
            relation: "includes",
          },
          {
            attrId: "jQzh72N_ZFcz4QiVmiR",
            attribute: `b2AccountStage`,
            relation: "includes",
            type: "accounts",
            values: [
              "Unreached",
              "Unengaged",
              "Aware",
              "Engaged",
              "Potential Opportunity",
              "Active Opportunity",
            ],
          },
          {
            attrId: "jQzh72N_ZFcz4Qljfif",
            attribute: "accounts|department",
            relation: "includes",
            type: "contacts",
            values: (() => {
              if(props.stackValue.includes('/')) return [props.stackValue.replaceAll('/', '%2F')];
              return [props.stackValue];
            })(),
          },
          {
            attrId: "jQzh72N_ssdfZFcz4Qljfif",
            attribute: "contacts|personalevel",
            type: "contacts",
            relation: "includes",
            values: (() => {
              if(persona.includes('/')) return [persona.replaceAll('/', '%2F')];
              return [persona];
            })(),
          },
        ];
      }
    }
    
    if (!(Array.isArray(props.reqBody) && !props.reqBody.length)) {
      props.reqBody.forEach((reqBody) => {
        body.push(reqBody);
      });
    }
    console.log(
      "link",
      `${UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(
        JSON.stringify(body)
      ))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(JSON.stringify(props.reqBody)))}/?icp=${
        props.icp || ""
      }&segmentName=${props.segmentName}&tabVal=${props.tabVal}&attribute=${props.stageValue}&tab=contact`
    );
    return `${UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(
      JSON.stringify(body)
    ))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(JSON.stringify(props.reqBody)))}/?icp=${
      props.icp || ""
    }&segmentName=${props.segmentName}&tabVal=${props.tabVal}&attribute=${props.stageValue}&tab=contact`;
  };
  const getData = async (stack) => {
    if (stack != null && stack != undefined && stack != {}) {
      let personas = stack.category;
      // console.log("persona",stack)
      for (let j = 0; j < stack.data?.length; j++) {
        for (let i = 0; i < personas?.length; i++) {
          let persona = personas[i].label;
          if (stack.data[j].data[i] != undefined) {
            console.log("persona", persona, stack.data[j].data[i]?.value);
            // console.log("stacking", stack.data[j].seriesname);
            stack.data[j].data[i].link = getLink(
              persona,
              stack.data[j].seriesname
            );
          }
        }
      }
    }

    let chartConfigsObject = {
      type: "stackedcolumn2d",
      renderAt: "chart-container",
      width: "100%",
      height: "380",
      dataFormat: "json",
      dataSource: {
        chart: {
          theme: "fusion",
          // "caption": "Revenue split by product category",

          xAxisname: jobs?.toUpperCase(),
          yAxisName: "NUMBER OF CONTACTS",
          showValues: "1",
          //Showing the Cumulative Sum of stacked data
          showSum: "1",
          showLegend: 0,
          reverseLegend: "1",
          theme: "fusion",
          plottooltext:
          '<div style="padding: 5px">Contact Count<br><b style="font-size: 14px">$value/$sum</b> <br><br> Score Range<br> <b style="font-size: 14px">$seriesname</b><br><br>Persona<br> <b style="font-size: 14px">$label</b></div>',
            "valueFontColor": "#595959",
            "valueFontSize": "12",
            "valueFontBold": "1",
            "valueFontAlpha": "100",
            "minPlotHeightForValue": "1",
            // "labelFont": "Proxima Nova",
           "labelFontColor": "595959",
           "labelFontSize": "15",
           "labelFontBold": "1",
           "labelAlpha": "100",
           "toolTipBorderRadius": "10",
          "toolTipBgColor": "#FFFFFF",
          "toolTipBgAlpha": "100",
          "showToolTipShadow": "1"



        },
        categories: [
          {
            category: stack.category,
          },
        ],
        dataset: stack.data,
      },
    };

    setChartConfigs(chartConfigsObject);
    console.log("stack", stack);
    // console.log("stack", stackData[0].data);
  };

  const getDummy = async () => {
    let chartConfigsObject = {
      type: "stackedcolumn2d",
      renderAt: "chart-container",
      width: "100%",
      height: "380",
      dataFormat: "json",
      dataSource: {
        chart: {
          theme: "fusion",
          xAxisname: jobs?.toUpperCase(),
          yAxisName: "NUMBER OF CONTACTS",
          showValues: "1",
          showSum: "1",
          showLegend: 0,
          reverseLegend: "1",
          theme: "fusion",
          plottooltext:
            '<div style="padding: 5px">Contact Count<br><b style="font-size: 14px">$value/$sum</b> <br><br> Score Range<br> <b style="font-size: 14px">$seriesname</b><br><br>Persona<br> <b style="font-size: 14px">$label</b></div>',

            "valueFontColor": "#595959",
            "valueFontSize": "12",
            "valueFontBold": "1",
            "valueFontAlpha": "100",
            "minPlotHeightForValue": "1",
            // "labelFont": "Proxima Nova",
           "labelFontColor": "595959",
           "labelFontSize": "15",
           "labelFontBold": "1",
           "labelAlpha": "100",
           "toolTipBorderRadius": "10",
          "toolTipBgColor": "#FFFFFF",
          "toolTipBgAlpha": "100",
          "showToolTipShadow": "1"
        },
        categories: [
          {
            category: [{}],
          },
        ],
        dataset: [{}],
      },
    };

    setChartConfigs(chartConfigsObject);

    // console.log("stack", stackData);
  };

  useEffect(() => {
    let index = -1;
    if (props.stackData.length > 0) {
      for (let i = 0; i < props.stackData.length; i++) {
        if (props.stackData[i].department == props.stackValue) {
          index = i;
        }
      }
    }
    if (index == -1) {
      getDummy();
    } else {
      getData(props.stackData[index]);
    }

    console.log("props1", props.stackData.length, props.stackValue);
  }, [props]);

  useEffect(() => {
    console.log("chartConfig",chartConfigs);
  }, chartConfigs);
  // setChartConfigs(chartConfigsObject);

  return(
  props.isLoading?   <Skeleton variant="rectangular" style={{ margin:"5%",borderRadius:"16px" }} width={700} height={296} />:(
  <ReactFC {...chartConfigs} />))
}
export default StackChart;
