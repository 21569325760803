import React, { useState, useEffect } from "react";
import "./Header.css";
import Typography from '@material-ui/core/Typography';
import { FormControlLabel, Switch, Select,MenuItem, FormControl } from '@material-ui/core';
 import { styled } from '@mui/styles';
 import {Task} from "./tasks/Task"
 import {Alert} from "./alert/alert"
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import {UNIFIED_URL, B2_CDPAPI, B2PROSPECT_URL} from "./Constant";
import { useAuthState } from '@bamboobox/b2logincheck';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Divider from '@mui/material/Divider';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import axios from "axios";
import { updateSelectedSegments, getSelectedSegments, Segments } from "./api";
import SegmentSelect from "./Components/SegmentSelect";
import _ from "lodash"


const useStyles = makeStyles((theme) => ({
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 170,
    },
    frame8: {
        /* Frame 8 */
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px",

        position: "static",
        width: "100%",
        height: "217px",
        left: "0%",
        top: "5%",
    },
    frame7: {
        /* Frame 7 */
        /* Auto layout */

        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px",

        position: "absolute",
        width: "97%",
        height: "32px",
        left: "3%",
        top: "5%",
    },
    frame6: {
        /* Frame 6 */
        /* Auto layout */

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",

        position: "absolute",
        width: "8vw",
        height: "32px",
        right: "5%",
        top: "0px",
        /* Inside auto layout */
        flex: "none",
        order: "1",
        flexGrow: "0",
        margin: "0px 289px 0 0",
    }, 
    titleDiv: {
        /* Title */
        position: "static",
        width: "217px",
        height: "24px",
        left: "0px",
        top: "6px",
        /* Inside auto layout */
        flex: "none",
        order: "0",
        flexGrow: "0",
        margin: "0px 390px",
    }, 
    accountCount: {
        /* (880 Accounts) */
        position: "absolute",
        width: "fit-content",
        height: "19px",
        left: "111px",
        top: "10px",

        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "19px",

        color: "#A0A0A0",
    },
    title: {
        /* Dashboard */
        position: "absolute",
        width: "100px",
        height: "24px",
        left: "0px",
        top: "8px",

        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "20px",
        lineHeight: "24px",

        color: "#595959",
    },
    frame10: {
        /* Frame 10 */
        /* Auto layout */

        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        padding: "0px",

        position: "absolute",
        width: "190px",
        height: "20px",
        left: "180px",
        top: "13px",
        /* Inside auto layout */
        flex: "none",
        order: "2",
        flexGrow: "0",
        margin: "0px 40px",
    },
    group27: {
        /* Group 27 */
        position: "absolute",
        width: "90px",
        height: "20px",
        left: "108px",
        top: "0px",
        /* Inside auto layout */
        flex: "none",
        order: "1",
        flexGrow: "0",
        margin: "0px 24px",
    },
    group28: {
        /* Group 28 */
        position: "absolute",
        width: "90px",
        height: "20px",
        left: "0px",
        top: "0px",
        /* Inside auto layout */
        flex: "none",
        order: "0",
        flexGrow: "0",
        margin: "0px 24px",
    },
    group29: {
        /* Group 28(29) */
        position: "absolute",
        width: "146px",
        height: "32px",
        left: "0px",
        top: "0px",
    },
    tabs: {
        /* tabs */
        position: "absolute",
        width: "146px",
        height: "32px",
        left: "0px",
        top: "0px",
        /* Inside auto layout */
        flex: "none",
        order: "0",
        flexGrow: "0",
        margin: "0px 24px",
    },
    line: {
        /* Line 2 */
        position: "static",
        width: "18px",
        height: "0px",
        top: "25px",
        border: "1px solid #D3D3D3",
        transform: [{ rotate: '-90deg'}],
        /* Inside auto layout */
        flex: "none",
        order: "1",
        flexGrow: "0",
        margin: "0px 25px",
    },
    drop: {
        /* drop */
        /* Auto layout */

        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "0px",
        zIndex: "9",
        position: "absolute",
        width: "90px",
        height: "20px",
        left: "0px",
        top: "0px",

    },
    Rect5: {
        position: "absolute",
        width: "136px",
        height: "32px",
        left: "0%",
        top: "0%",
        background: "#FFFFFF",
        // border: "1px solid #D2D2D2",
        boxSizing: "border-box",
        
    },
    rect5Text: {
        position: "absolute",
        width: "136px",
        height: "32px",
        left: "15%",
        top: "20%",
        maxWidth:"100%",

        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "15px",
        /* identical to box height */
        color: "#8A8A8A",
    },
    circle: {
        position: "absolute",
        width: "20px",
        height: "20px",
        left: "50px",
        borderRadius:"50px",
    },
    circleText: {
        padding: "2px 0",
        textAlign:"center",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "14.62px",
        color: "#FFFFFF",
        fontFamily:'Nunito Sans',
    },
    vector: {
        /* Vector */
        position: "absolute",
        left: "80%",
        right: "10%",
        color: "#BDBDBD",
        lineHeight:"middle"
    },
    grp29text:{
        // position: "absolute",
        top: "9px",
        fontFamily:'Nunito Sans',
    },
    text1: {
        /* Tasks */
        position: "absolute",
        width: "40px",
        height: "19px",
        left: "0px",
        top: "1.5px",

        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "19px",
        color: "#8A8A8A",
        /* Inside auto layout */
        flex: "none",
        order: "0",
        flexGrow: "0",
        margin: "0px 6px",
        fontFamily:'Nunito Sans',
    },
    vector1: {
        /* Vector */
        position: "absolute",
        left: "77%",
        right: "10%",
        color: "#BDBDBD",
        lineHeight:"middle"

    },
    floatRight: {
        display: "flex",
        justifyContent: "flex-end",
    }
    ,
  selected: {
    borderRadius:"16px",
    background:"#d2f8d2 !important" 
}
}));


const Header = (props) => {
    const [checked, setChecked] = React.useState(false);
    const [taskChecked, setTaskChecked] = React.useState(false);
    const [alertChecked, setAlertChecked] = React.useState(false);
    let { id } = useParams();
    const [body, setBody] = useState(id ? JSON.parse(decodeURIComponent(id)) : []);
    const [accountCount,SetAccountCount] = useState(null);
    const [totalAccount,setTotalAccount]= useState();
    const [totalTasks,SetTotalTasks]= useState(0);
    const [totalAlerts,SetTotalAlerts]= useState(0);
    // const[accSegment,setAccSegment]=useState("All Accounts");
    const userDetails = useAuthState();
    const permissions = userDetails?.permissions;
    const resourceNames=['dashboard_insights'];
    const tenantId = userDetails.user.tenantId;
    const userId = userDetails.user.userUuid;
    

    let location = useLocation();

    function useQuery() {
        return new URLSearchParams(location.search);
    }

    let query = useQuery();
    let params_url = query.get("icp") || "";
    const [icp, setIcp] = useState(params_url);

    const classes = useStyles();

    
    useEffect(() => {
        if(props.accSegment && props.icpSelected && body){
            setTotalAccount('')
            accountFilterCount()
        }  
    },[body, props.icpSelected, props.accSegment])
    
    useEffect(() => { 
        totalCount();
    }, [])


    const getFromSessionStorage = (key) => {    
        return JSON.parse(sessionStorage.getItem(`${tenantId}+${userId}+${key}`));
    }
    
    const setToSessionStorage = (key, value) => {
        sessionStorage.setItem(`${tenantId}+${userId}+${key}`, JSON.stringify(value));
    }

    useEffect( () => {

        console.log("going in getSelectedSegmentsAPICall")
        if(props.icpSelected?.trim()){
            async function getSelectedSegmentsAPICall() {
                var segmentFromDb = await getSelectedSegments(tenantId, userId, props.icpSelected, "account");
                let listOfSegments = props.loglist.length <= 1 ? await Segments(tenantId, userId): props.loglist
                if(listOfSegments){
                    props.setAccSegment(listOfSegments?.filter(data => data.listTitle === segmentFromDb)[0]?.listTitle || "All Accounts")
                }
                else{
                    props.setAccSegment("All Accounts");
                }
              } 
            getSelectedSegmentsAPICall();
        }
        
    }, [props.icpSelected])

    const totalCount=()=>{
        console.log("inside")
        const requestOptions = {
          credentials: 'include',
          method: 'GET',
      };
        fetch(`${UNIFIED_URL}/account/hub/${tenantId}/role-based/count?icp=${props.icpSelected}`,requestOptions)
        .then((result) => result.json())
        .then((data)=> SetAccountCount(data.totalCount))
        .catch(SetAccountCount(null))
      console.log("totalcount",accountCount)
    
    }
    const accountFilterCount = ()=>{
         fetch(
            `${UNIFIED_URL}/analytics/totalAccounts/${tenantId}?attr1=industry&icp=${props.icpSelected}&filterName=${props.accSegment}`,
            {
              method: "POST",
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({"filters": body}),
              credentials: "include",
            }
          )
            .then((response) => response.json())
            .then((data) => {
                setTotalAccount(data.totalCount)
                console.log("totalcount",data.totalCount, totalCount);
            })
    }

    function checkPermision(tenantId,permissions,resourceNames){
        for(var objIdx=0;objIdx<permissions.length;objIdx++){
        if(tenantId && permissions[objIdx]?.operations?.length>0 && resourceNames.includes(permissions[objIdx]?.resourceName))
           return true
         }
       return false
   }

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const handleTasksChange = () => {
        setAlertChecked(false);
        setTaskChecked(!taskChecked);
    };
    const handleAlertsChange = () => {
        console.log("alerts")
        setAlertChecked(!alertChecked);
        setTaskChecked(false);

    };
    const handleClickAwayTask = () => {
        setTaskChecked(false);
    }
    const handleClickAwayAlert = () => {
        setAlertChecked(false);
    }
    const TaskAlertSwitch = styled(Switch)(({ theme }) => ({
        
        '& .MuiSwitch-root': {
            width: "58px",
            height: "38px",
        
            display: "inline-flex",
            padding: "12px",

            zIndex: 0,
            overflow: "hidden",
            position: "relative",
            boxSizing:"border-box",
            flexShrink: 0,
            verticalAlign: "middle",
             background: "transparent",
             backgroundColor: '#f9f9f9'
        },
        '& .MuiSwitch-switchBase':{
            top:'8px',
            left:'10px',
        },
        
      }));

      const getCountApi = async() => {
        let tempTask=0
        let tempAlert=0  
        console.log("getting count of tasks and alerts")
      await axios
      .get(
        `${B2_CDPAPI}/intent/new/accounts/count/BOMBORA/${tenantId}/${props.icpSelected}`,
        { withCredentials: true }
      )
      .then((intentNewAccount) => {
        console.log("IntentNewAccount",intentNewAccount)
        tempTask +=  parseInt(intentNewAccount.data.count)
      });
      await axios
      .get(
        `${B2_CDPAPI}/campaignmanager/count/all/${tenantId}`,
        { withCredentials: true }
      )
      .then((pendingRequest) => {

        console.log("100 pending req",pendingRequest)
        tempTask += parseInt(pendingRequest.data.count)
        SetTotalTasks(tempTask)      
    });
    await axios
      .get(
        `${B2PROSPECT_URL}/staging/${tenantId}/getCounts`,
        { withCredentials: true }
      )
      .then((webformFill) => {
        console.log("webformFill",webformFill)
        tempAlert += parseInt(webformFill.data.size)
              
    });
    // await axios
    //   .get(
    //     `${B2PROSPECT_URL}/enrichment/${tenantId}/getCounts`,
    //     { withCredentials: true }
    //   )
    //   .then((enrichment) => {
    //     // console.log(`%c${JSON.stringify(enrichment.data)}`, 'color: blue')
    //     tempAlert += parseInt(enrichment.data.account)
    //     tempAlert +=  parseInt(enrichment.data.contact)
    
    //   });
        SetTotalAlerts(tempAlert)      
    }
    useEffect(() => {
        getCountApi();
      }, [props.icpSelected]);


      const insightsTabFinder = () => {
        if(checkPermision(tenantId,permissions,resourceNames)){
            if(props.tabVal !== 0){
                return true;
            }else{
                return false;
            }
        }else{
            return true;
        }
      }

      const handleSegmentChange = async (event, data) => {
        console.log("icp and chosen segment", props.icpSelected, event.target.value)
        const selectedSegment = event.target.value
        props.setAccSegment(selectedSegment);
        if(props?.icpSelected){
            await updateSelectedSegments(tenantId, userId, props.icpSelected, "account", selectedSegment);
        }
      }
      const accountCountHeader = () => {
        if(props.accSegment === 'All Accounts' || (props.accSegment !== 'All Accounts' && totalAccount) ){
            return ((!insightsTabFinder()) || (props.accSegment === 'All Accounts' && body.length === 0) || (totalAccount===undefined|| totalAccount===null) ) ?
                        (<Typography variant="ag" component="ag" className="total-account-typography">{accountCount || '--'} &nbsp;</Typography>):
                        (<Typography variant="ag" component="ag" className="total-account-typography">{totalAccount} of {accountCount} &nbsp;</Typography>)
        } else {
            return  (<Typography variant="ag" component="ag" className="total-account-typography">{'--'} &nbsp;</Typography>)
        }
      }

      return (
        <div className={classes.frame8}>
        <div className={classes.frame7}>
            <div className={classes.frame6}>
                <div className={classes.tabs}>
                    <div className={classes.group29} style={{ cursor:"pointer"}}>
                        {(props.tabVal!==0)&&<div>
                        <FormControl
                            size="small"
                            className="demo"
                        >
                            <SegmentSelect classes={classes} id={id} style={{zIndex: "9", cursor:"pointer"}} onChange={handleSegmentChange} loglist={props.loglist} value={props.accSegment} dataType={"account"}/>
                        </FormControl>
                        </div>}
                        </div>

                        
                </div>

                <div className={classes.frame10}>
                <Divider orientation="vertical" flexItem />

                    <div className={classes.group28}>
                    <ClickAwayListener onClickAway={handleClickAwayTask}>
                        <div className={classes.drop} onClick={handleTasksChange} style={{cursor:"pointer"}}>
                            <Typography className={classes.text1}>Tasks</Typography> 
                            <KeyboardArrowDownRoundedIcon className={classes.vector1} style={{left:"80%"}}/>
                            <div className={classes.circle} style={{backgroundColor:"#37CAD3", left:"55px"}}><Typography className={classes.circleText}>{totalTasks}</Typography></div>
                        </div>
                    </ClickAwayListener>
                        {taskChecked===true&& <Task className={classes.floatRight} requestBody={props.reqBody}  icpSelected={props.icpSelected} setIntentNewAccount={props.setIntentNewAccount} intentNewAccount={props.intentNewAccount}/>}

                    </div>
                    <div className={classes.group27}>
                        <ClickAwayListener onClickAway={handleClickAwayAlert}>
                        <div className={classes.drop} onClick={handleAlertsChange} style={{cursor:"pointer"}}>
                            <Typography className={classes.text1}>Alerts </Typography> 
                            <KeyboardArrowDownRoundedIcon className={classes.vector1}/>
                            <div className={classes.circle} style={{backgroundColor:"#E842EC"}}><Typography className={classes.circleText}>{totalAlerts}</Typography></div>

                        </div>
                        </ClickAwayListener>
                        {alertChecked===true&& <Alert className={classes.floatRight} requestBody={props.reqBody}  icpSelected={props.icpSelected} setIntentNewAccount={props.setIntentNewAccount} intentNewAccount={props.intentNewAccount}/>}

                    </div>

                </div>
                
            </div>
            <div className={classes.titleDiv}>
                <div className={classes.title}>
                    Dashboard
                </div>
                <div className={classes.accountCount} >
                    (
                    {
                        accountCountHeader()
                    }
                    <Typography variant="ag" component="ag" className="total-account-typography">Accounts</Typography>
                    )
                    
                </div>
            </div>
        </div>
                    
                    
                    
        <div>
            
        </div>
        </div>
    )
}

export default Header
