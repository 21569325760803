import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
// import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));

const AttributeList = [
    "Unreached",
    "Unengaged",
    "Aware",
    "Engaged",
    "Potential Opportunity",
    "Active Opportunity"
];


const Attribute = (props) => {

    const classes = useStyles();
    console.log(props.stageValue)
    const [attribute, setAttribute] = React.useState(AttributeList[0]);

    const handleChange = (event) => {
        let AttributeLabel = AttributeList.find(o => o === event.target.value);
        setAttribute(event.target.value)
        props.setStageValue(AttributeLabel)
    };

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            {/* <InputLabel id="demo-simple-select-outlined-label"></InputLabel> */}
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={attribute}
                onChange={handleChange}
                style={{width:"220px"}}
            >
                {AttributeList.map((attrList) => (
                    <MenuItem value={attrList}>{attrList}</MenuItem>
                ))
                }
            </Select>
        </FormControl>
    );
};

export default Attribute;
