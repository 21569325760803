// import Typography from "@bamboobox/b2-theme/typography";
import { Autocomplete } from "@material-ui/lab"
import { DialogActions } from "@material-ui/core";
import { InputAdornment, List, TextField,ListItem,ListItemIcon,ListItemText,Checkbox,Typography,Button, Alert } from '@mui/material';
import { Fragment, useLayoutEffect, useRef } from "react";
import "./settingpop.css"
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
const _ = require('lodash');


export const Searchuser=(params)=>{
  
    const [searchResult,setSearchResult] = useState([]);
    const [filterSearch,setFilterSearch] = useState("");

    // const originVal = useRef([]);
    const [originResult,setOriginResult] = useState([])
    const [deltaSharing,setDeltaSharing] = useState({})
    const handleSharing = ()=>{
      const body = {}
      const sharedWithUser = []
      const unsharedUser = []
      for(let i=0;i<originResult.length;i++){
        const bol = originResult[i].cardShared === searchResult[i].cardShared
        if(!bol)  {
          if(searchResult[i].cardShared== false){
            unsharedUser.push(searchResult[i].userUuid)
          }
          else  sharedWithUser.push(searchResult[i].userUuid)
        }
      }
      body.sharedWithUser = sharedWithUser;
      body.unsharedUser = unsharedUser;
      axios.post(`${window._env_.CARD_SERVICE}/card/share/${params.tenantId}/${params.cardId}`,body,{withCredentials:true}).then(e=>{
        if(e.data.code === 200){
          toast.success(<Alert severity="success">{`${params.sharingTitle} shared with ${sharedWithUser.length} user and unshared with ${unsharedUser.length} user`}</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
          console.log("errrorrrrr",params.backBotton)
          {params.sharingPage && params.handleSetting();}
          // params.handleSaveSetting();
        }else{
          toast.error(<Alert severity="error">{'Oops there was problem sharing the card. Try again in some time'}</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
        } 

        setOriginResult(_.cloneDeep(searchResult))
      })
    }
    const handleResults = ()=>{

      setSearchResult(searchResult.map(val=>{
        val['cardShared']=false;
        return val;
      }));
      // setSearchResult([])
      // setSearchResult(_.cloneDeep(originResult))

      // setSearchResult(original)
    }
    const handleSingListCheck=(e,userId)=>{
      try {
        const check = e.target.checked;
        setSearchResult(searchResult.map(val=>{
          if(val.userUuid===userId) val['cardShared'] =  e.target.checked
          return val;
        }

        ))
      } catch (error) {
        console.error(error)
      }
    }
    const fetchUserDetails = ()=>{
      try {
        axios(`${window._env_.CARD_SERVICE}/card/usersinfo/${params.tenantId}/${params.cardId}`,{withCredentials:true})
        .then(e=>{
        const allUsers = [...e.data.data.internalUsers,...e.data.data.externalUsers];
        setOriginResult(allUsers);
        setSearchResult(_.cloneDeep(allUsers));
        // setFilterSearch(e.data.data.externalUsers);

      }).catch(e=>{console.error("error in axios occurred",e); setSearchResult([])});
      } catch (error) {
        console.error("fetchUserDetails",error);
      }
    }
    useLayoutEffect(()=>{
      if(params.sharingTitle) fetchUserDetails()
    },[params.sharingTitle])
   const handleFilter = (e)=>{
    try {
      // e.prevent.default;
      setFilterSearch(e.target.value)

      
    } catch (error) {
      console.error("catch block error occurred in handleFilter",error);
    }
   }
    return (
        <Fragment>
            <div style={{alignItems:"center",width:"92%",position:"relative",left:"15px",top:"30px"}}>
                {/* <Autocomplete
                id="searchbox"
                // style={{padding:0,width:"105%"}}
                freeSolo
                size="small"
                autoComplete
                autoHighlight
                options={searchResult}
                // filterOptions={(x) => x?.name}
                getOptionLabel={(option,value)=>option.name}
                renderInput={(params) => ( */}
                    <TextField
                      {...params}
                      variant="outlined"
                      size = "small"
                      fullWidth
                      onChange={handleFilter}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8924 15.4209C6.54476 15.4209 3.02026 11.9696 3.02026 7.71204C3.02026 3.45451 6.54472 0.00317383 10.8924 0.00317383C15.2401 0.00317383 18.7646 3.45455 18.7646 7.71208C18.7646 11.9696 15.2401 15.4209 10.8924 15.4209ZM10.8924 2.20573C7.78693 2.20573 5.26946 4.67099 5.26946 7.71208C5.26946 10.7532 7.78693 13.2184 10.8924 13.2184C13.9979 13.2184 16.5154 10.7532 16.5154 7.71208C16.5154 4.67099 13.9979 2.20573 10.8924 2.20573Z" fill="#595959"/>
                            <path d="M1.89576 17.6234C1.59683 17.6251 1.30953 17.5102 1.09731 17.304C0.656314 16.8757 0.653406 16.1784 1.0908 15.7466C1.09297 15.7445 1.09514 15.7423 1.09731 15.7402L5.32577 11.5994C5.78227 11.1676 6.50983 11.1799 6.95082 11.627C7.39181 12.074 7.37922 12.7865 6.92272 13.2183L2.69422 17.304C2.482 17.5102 2.1947 17.6251 1.89576 17.6234Z" fill="#595959"/>
                            </svg>
                          </InputAdornment>
                        ),
                        placeholder: "Search people",
                      // style:{fontWeight: "normal",fontSize: "14px",lineHeight: "14px",height:"32px",position:"relative",padding:"0px 15px 36px 12px"},
                      }}
                    />
                  {/* )}
                /> */}
            </div>
            <div style={{margin:"34px auto auto auto",paddingLeft:"13px"}}>

            <ListUser listUsers={searchResult} setListUsers={setSearchResult} handleSingListCheck={handleSingListCheck} filterSearch={filterSearch} />

           
</div>
            
            
            <span 
            className="actionButtonBottom">
                    <p id='1' className="bottomCaption" >
              <span id='1' className="buttonCaption" type="Button" onClick={()=>handleResults()}>

              Clear All
              </span>
                    </p>
              <div style={{display:"flex",flexDirection:"row-reverse",padding:"16px"}}>
          <Button  sx={{borderRadius:"18px!important"}} variant="contained" onClick={()=>handleSharing()} >
            share
          </Button> 
                    <Button onClick={()=>{params.setSharingPage(false);params.handleSetting(); }} >cancel</Button>
          </div>
              
            </span>
            
        </Fragment>
    )
}

const ListUser = (params)=>{
  const handleAllResult =(ele)=>{
    try {
      const check = ele['target']['checked'] 

      params.setListUsers(params.listUsers.map(val=>{
        if(val.name.toLowerCase().includes(params.filterSearch.toLowerCase()))  val['cardShared']=check;
        return val;
      }));

    } catch (error) {
      console.error("handleAllResult err:",error);
    }
  }
  
  // const len = params.listUsers.filter(e=>e["cardShared"]==true).length
  console.log("...........",params.listUsers,params.listUsers.filter(f => 
    {console.log("hell",f.name, f.name.includes(params.filterSearch),params.filterSearch);
   
  } ),params.filterSearch);
  const all = params.listUsers.filter(f => f.name.toLowerCase().includes(params.filterSearch.toLowerCase()) || f === {}).filter(e=>e["cardShared"]==true).length=== params.listUsers.filter(f => f.name.toLowerCase().includes(params.filterSearch.toLowerCase()) || f === {}).length ; 

  useEffect(()=>{

  },[params.listUsers])
  return(
    <Fragment>

      <List sx={{display:"block",height:"294px",overflow:"auto" }}>
        <ListItem>

      <ListItemIcon >
                   <Checkbox
                  edge="start"
                  checked={all}
                  // tabIndex={}
                  onClick={handleAllResult}
                  disableRipple
                  inputProps={"aria-labelledby" }
                />

                   </ListItemIcon>
                {/* </ListItemButton> */}
              <ListItemText id={"All"} primary="All Result"  /> 
              </ListItem>
          {
            
            params.listUsers.filter(f =>  f.name.toLowerCase().includes(params.filterSearch.toLowerCase()) ).map(value=>(

              <ListItem key={value.userUuid} className={(value['cardShared']&&"highligt")||''} >
                {/* <ListItemButton  role={undefined} */}
              {/* // onClick={handleToggle(value)} */}
              {/* dense> */}
                   <ListItemIcon >
                   <Checkbox
                  edge="start"
                  checked={value['cardShared']}
                  tabIndex={-1}
                  onClick={(e)=>params.handleSingListCheck(e,value.userUuid)}
                  disableRipple
                  inputProps={{ "aria-labelledby": value.userUuid }}
                />

                   </ListItemIcon>
                {/* </ListItemButton> */}
              <ListItemText id={value.userUuid} primary={ <Fragment>
              {value.name}
             
            </Fragment>}  /> 
              </ListItem>
            ))
          }
        {/* </ListItem> */}
      </List>
    </Fragment>
  )
}