import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Box, withStyles} from "@material-ui/core";
import "./FreqToggleTab.css"
import { useLocation } from "react-router-dom";
import { changeFromToDate } from "./../dateTimeFunctionRepository"
import moment from "moment"
import React from "react";


// const location = useLocation();



export default function FreqToggleTab({tabVal2,requestBody,icpSelected,tenantId, ...props}){
    function useQuery() {
      return new URLSearchParams(useLocation().search);
    }
    let GetTabVal = () => {
      return useQuery().get("tabVal2");
    }
    const CustomTab = withStyles({
        root: {
          textTransform: "none",
          minWidth: "8vw",
          width: "8vw",
        }
      })(Tab);
   
    console.log('tab val in FreqToggleTab is', GetTabVal());
    
    const [frequency, setFrequency] = React.useState("Weekly");
    const [position, setPosition] = React.useState(0);

    
    const [disableMonthly, setDisableMonthly] = React.useState(true)
    const [disableQuarterly, setDisableQuarterly] = React.useState(true)

    function handleChange(e,newPosition) {
      props.setTabVal2(newPosition);
      handleFrequency(newPosition)
    }

    const handleFrequencyToggleDisable = () => {

      console.log("handleFrequencyToggleDisable just checking useffect", props?.fromDate, props?.toDate);

      let [fromDateMonthly, toDateMonthly] = changeFromToDate(props.fromDate, props.toDate, "Monthly");
      let [fromDateQuarterly, toDateQuarterly] = changeFromToDate(props.fromDate, props.toDate, "Quarterly");

      console.log("handleFrequencyToggleDisable before momentizing", fromDateMonthly, toDateMonthly, fromDateQuarterly, toDateQuarterly, props?.fromDate, props?.toDate);

      fromDateMonthly = moment(new Date(fromDateMonthly))
      toDateMonthly = moment(new Date(toDateMonthly))
      fromDateQuarterly = moment(new Date(fromDateQuarterly))
      toDateQuarterly = moment(new Date(toDateQuarterly))

      console.log("handleFrequencyToggleDisable", fromDateMonthly.toISOString(), toDateMonthly.toISOString(), fromDateQuarterly.toISOString(), toDateQuarterly.toISOString());

      if (props && props?.fromDate && props?.toDate && fromDateMonthly && toDateMonthly && fromDateQuarterly && toDateQuarterly) {
          console.log("handleFrequencyToggleDisable booleans",(toDateMonthly.diff(fromDateMonthly, 'months') > 2), Math.ceil(toDateQuarterly.diff(fromDateQuarterly, 'months') / 3), toDateQuarterly.diff(fromDateQuarterly, 'months'), (Math.ceil(toDateQuarterly.diff(fromDateQuarterly, 'months') / 3) > 2))
          if (toDateMonthly.diff(fromDateMonthly, 'months') > 2) {
              setDisableMonthly(false)
          } else {
              setDisableMonthly(true)
          }

          if (Math.ceil(toDateQuarterly.diff(fromDateQuarterly, 'months') / 3) > 2) {
              setDisableQuarterly(false)
          } else {
              setDisableQuarterly(true)
          }
      } else {
          setDisableMonthly(true)
          setDisableQuarterly(true)
      }
      if (toDateMonthly.diff(fromDateMonthly, 'months') <= 2 && frequency !== "Weekly") {
          setPosition(0)
          setFrequency("Weekly")
          props.setSelectedFrequency("Weekly")
          

      } else if (Math.ceil(toDateQuarterly.diff(fromDateQuarterly, 'months') / 3) <= 2 && frequency === "Quarterly") {
          setPosition(1)
              setFrequency("Monthly")
              props.setSelectedFrequency("Monthly")
      }


  }

    const handleFrequency = (newOptionSelected) => {

      if (newOptionSelected !== null && newOptionSelected !== undefined) {
          if (newOptionSelected === 0) {
              setPosition(0)
              setFrequency("Weekly")
               props.setSelectedFrequency("Weekly")
              
          }
          else if (newOptionSelected === 1) {
              setPosition(1)
              setFrequency("Monthly")
              props.setSelectedFrequency("Monthly")
          }
          else if (newOptionSelected === 2) {
              setPosition(2)
              setFrequency("Quarterly")
              props.setSelectedFrequency("Quarterly")
          }            
      } 
      else {
          setFrequency("Weekly")
          setPosition(0)
      }
      
  };

    React.useEffect(() => {
      handleFrequency()
    }, [])

    React.useEffect(() => {
        handleFrequencyToggleDisable()
    }, [new Date(props.fromDate), new Date(props.toDate)])


    React.useEffect(()=>{
        console.log("tabVal2 FreqToggleTab", tabVal2)
        setPosition(Number(tabVal2)||0)
      },[tabVal2])
      
    return(
      
             <div>
              <Box sx={{ maxWidth: "20vw", borderBottomWidth:'0.2px', borderBottomColor: "rgb(211, 211, 211)"}}>
                <Tabs 
                style={{ borderBottom:'1px solid #D3D3D3', color:"#595959"}}
                indicatorColor="primary"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#21DA8C",
                    height:"6px",
                    borderRadius: "3px"
                   }
                  }}
                  //position
                  value={(Number(tabVal2) || 0)}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
                variant="standard"
                >
                    
                    <CustomTab label="Weekly" ></CustomTab>
                    <CustomTab label="Monthly" disabled={disableMonthly}></CustomTab>
                    {props.showQuarterly &&
                      <CustomTab label="Quarterly" disabled={disableQuarterly}></CustomTab>
                    }

                </Tabs>
                </Box>
              </div>
    )
}
