
//
import axios from "axios";

export function saveFilters(mode,attributeData,filterName,tenantId,ui) {    
    const bodyData = {
        "tenantId" : `${tenantId}`,
        "filterName" : filterName,
        "page" : `${ui}`
    };
    const attributeArray = attributeData.map((object) => {
        const obj = {...object};
        delete obj.isValid;
        delete obj.index;
        return {...obj,...bodyData};
    })    
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({mode:mode,filterName:filterName , data: attributeArray}),
        credentials: "include"
    };

    return fetch(`${window._env_.FILTER_URL}/save-filter?tenantId=${tenantId}&&ui=${ui}`, requestOptions )
        .then(response => response.json())
        .catch(err => {
            console.error(err);
        })        
}

export function getFilterNames(tenantId,ui) {
    return fetch(`${window._env_.FILTER_URL}/get-filter?tenantId=${tenantId}&&ui=${ui}` , { credentials: "include"})
      .then(res => res.json())
      .catch(err => {
        console.error(err);
      })   
}

export function getIcpListName(tenantId) {
  return fetch(`${window._env_.ICP_URL}/scoredefinition/withoutFilters/${tenantId}`, { credentials: "include" })
    .then(res => res.json())
    .catch(err => {
      console.error(err);
    })   
}

export function getFilterDataByName(filterName,tenantId,ui) {
    return fetch(`${window._env_.FILTER_URL}/get-filter-byname?filterName=${filterName}&&tenantId=${tenantId}&&ui=${ui}`, { credentials: "include" })
    .then(res => res.json())
    .catch(err => {
      console.error(err);
    }) 
  }

  export function deleteFilters(name,tenantId,ui) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
         filterName: name,
         tenantId,
         ui: ui
      }),
      credentials: "include" 
    };
    return fetch(`${window._env_.FILTER_URL}/delete-filter`, requestOptions)
      .then(response => response.json())
      .catch(err => {
          console.error(err);
      })    
  }

  export async function Segments(tenantId, userUuid) {
    const FILTERS_URL = `${window._env_.FILTERS_BASE_URL}/filters/get-filter?tenantId=${tenantId}&ui=export&userUuid=${userUuid}`;
        const requestOptions = {
            credentials: 'include',
            method: 'GET',
        };
        
        const segments = fetch(FILTERS_URL,requestOptions)
          .then((result) => result.json())
          .then((data) => {
            
            if(data.success) {
                let logListArray = data.data.map((element,index)=>{
                    return {id:(index+2),listTitle:element.filterName,type:element.segmentType}
                }) 
                logListArray.push({id:0,listTitle:"All Accounts",type:'account'})
                console.log("loglist",logListArray);
                return logListArray
            }
        }).catch((e)=>console.log("fetch filter url error occur: ",e))
        return segments;
  }
 
  export async function getSelectedSegments (tenantId, userId, icp, type) {
    const selectedSegments = await axios.get(`${window._env_.LOGIN_API_URL}/users/get-last-used-segments/${tenantId}/${userId}/${icp}/${type}`, 
                             { 
                               withCredentials: true 
                             }).then(res => res.data)
    return selectedSegments?.data || ''
  }
  
  export async function updateSelectedSegments (tenantId, userId, icp, type, segments) {
    await axios.post(`${window._env_.LOGIN_API_URL}/users/update-segments/${tenantId}/${userId}/${icp}/${type}`, 
      {
          segmentArray: segments,
      },
      { 
        withCredentials: true 
      }).then(res => res)
  }