import React, { useContext, useState } from "react";
import { FilterContext } from "../index";
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
function Footer(props) {

  const { applyFilters, attributeList, filterName, actionType } = useContext(FilterContext);
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "flex-end",
        marginTop: "32px",
        marginBottom: "16px",
        gap:"20px"
        
      }}
    >
      
      <button
        style={{
          
          fontWeight: "800",
        
          cursor: "pointer"
          ,background:"white",border:"none",color:"#21DA8C"

        }}
        onClick={props.onModalClose}
      >
        
        <div style={{width:"80%", paddingTop:"4px" }}>CANCEL</div>

      

      </button>
      <button
       style={{
        width: "70px",
          height:"36px",
          paddingTop: 5,
          fontWeight: "800",
          fontFamily:"Nunito Sans",
        letterSpacing: "8%",
        verticalAlign: "Top",
        background: !(
          attributeList[attributeList.length-1]?.isValid ||
          attributeList[attributeList.length-1]?.isValid === undefined
        ) || attributeList[attributeList.length-1].attribute === ""? "#cccccc" : "#21DA8C",
        color: "#FFFFFF",
        border: "1px",
        borderRadius: "18px",
        marginLeft: 10,
        cursor:!(
          attributeList[attributeList.length-1]?.isValid ||
          attributeList[attributeList.length-1]?.isValid === undefined
        ) || attributeList[attributeList.length-1].attribute === "" ? "" : "pointer",
      }}
      disabled={!(
        attributeList[attributeList.length-1]?.isValid ||
        attributeList[attributeList.length-1]?.isValid === undefined
      ) || attributeList[attributeList.length-1].attribute === ""}
        onClick={() => {
          if (props.checked) {
            if(filterName === ''){
              document.getElementById("filter-name-header").focus();
              console.log('on click of button when add and checked and no name');
            }else{
              applyFilters("SAVE_APPLY");
              console.log('on click of button when add and checked and with name');
            }
            
          } 
          else {
            if (actionType === "ADD") {
              applyFilters("ONLY_APPLY");
              console.log('on click of button when add and not checked ');
            } else {
              console.log('on click of button when add and not checked in else ');
              applyFilters("APPLY");
            }
          }
          props.setActionType('')
        }}
      >
        APPLY
      </button>
    </div>
  );
}

export default Footer;