import React, { useEffect, useState, useRef } from "react";
import "./chart.css";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Funnel from "fusioncharts/fusioncharts.charts";
import Widgets from "fusioncharts/fusioncharts.widgets";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Divider from "@material-ui/core/Divider";
import ColumnChart from "./ColumnChart";
import { InfoRounded, } from "@material-ui/icons";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useAuthState } from "@bamboobox/b2logincheck";
import { UNIFIED_URL, UNIFIED_FRONTEND_URL } from "./Constant";
import { chartData,pastChartData } from "./FunnelChartData";
import HeatMapChart from "./HeatMapChart";
import { Paper, Typography, withStyles, Tooltip, Box, List, CircularProgress } from "@material-ui/core";
import UItheme from "@bamboobox/b2-theme"
import StageAgingSelector from "./StageAgingSelector";
import StackDeptSelector from "./StackDeptSelector";
import CryptoJS from "crypto-js";
import TimeSpanComponentForFunnel from "./TimeSpanComponentForFunnel";
import { LICENSE_STRING } from "./Constant";
import StackChart from "./StackChart";
import { useLocation } from "react-router-dom";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import axios from "axios";
import FunnelLoading from "./FunnelLoading";
import { Skeleton } from "@mui/material";
import _ from "lodash";
// import { Calculate } from "@mui/icons-material";
// import {up} from "./svg/up.svg";
// import {down} from "./svg/down.svg"

if (LICENSE_STRING != "") {
  var _0x49bc0e = _0x298e; function _0x298e(_0x7d727b, _0x38966f) { var _0x1b8773 = _0x1b87(); return _0x298e = function (_0x298eb3, _0x1414f4) { _0x298eb3 = _0x298eb3 - 0x1ea; var _0x32f714 = _0x1b8773[_0x298eb3]; return _0x32f714; }, _0x298e(_0x7d727b, _0x38966f); } (function (_0x5c9ab4, _0x59221e) { var _0xe05717 = _0x298e, _0x550b93 = _0x5c9ab4(); while (!![]) { try { var _0x3a14a4 = parseInt(_0xe05717(0x1f4)) / 0x1 + parseInt(_0xe05717(0x1f7)) / 0x2 + parseInt(_0xe05717(0x1ee)) / 0x3 + -parseInt(_0xe05717(0x1ea)) / 0x4 + parseInt(_0xe05717(0x1eb)) / 0x5 * (parseInt(_0xe05717(0x1ec)) / 0x6) + parseInt(_0xe05717(0x1f0)) / 0x7 * (-parseInt(_0xe05717(0x1ef)) / 0x8) + -parseInt(_0xe05717(0x1f6)) / 0x9 * (-parseInt(_0xe05717(0x1f5)) / 0xa); if (_0x3a14a4 === _0x59221e) break; else _0x550b93['push'](_0x550b93['shift']()); } catch (_0x3a3581) { _0x550b93['push'](_0x550b93['shift']()); } } }(_0x1b87, 0xed863)); function _0x1b87() { var _0x3cdb73 = ['Utf8', 'b2\x20cdp\x20uses\x20fustion\x20chart', '169152xDHSeA', '30SRtDAJ', '2786688ScKRos', '1604846jQeLkv', 'license', 'options', '3060620rKUxON', '3982685QzwgIi', '6quDyMF', 'enc', '1686396OqHHUo', '1738384mMIoTV', '49NMgycc', 'AES']; _0x1b87 = function () { return _0x3cdb73; }; return _0x1b87(); } var oooo0000 = CryptoJS[_0x49bc0e(0x1f1)]['decrypt'](LICENSE_STRING, _0x49bc0e(0x1f3))['toString'](CryptoJS[_0x49bc0e(0x1ed)][_0x49bc0e(0x1f2)]); FusionCharts[_0x49bc0e(0x1f9)][_0x49bc0e(0x1f8)]({ 'key': oooo0000, 'creditLabel': ![] });
}

//import axios from "axios";

ReactFC.fcRoot(FusionCharts, Funnel, Widgets, FusionTheme);

const AttributeList = [
  "All",
  "Unreached",
  "Unengaged",
  "Aware",
  "Engaged",
  "Potential Opportunity",
  "Active Opportunity"
];

const AttributeLists = [
  "Unreached",
  "Unengaged",
  "Aware",
  "Engaged",
  "Potential Opportunity",
  "Active Opportunity"
]


const BoxIcon = (props) => {
  let colorObj = {
    'All': '#A0A0A0',
    'Unreached': '#A0A0A0',
    'Unengaged': '#A0A0A0',
    'Aware': '#A0A0A0',
    'Engaged': '#A0A0A0',
    'Potential Opportunity': '#A0A0A0',
    'Active Opportunity': '#A0A0A0'
  }
  let styObj = { width: 20, height: 20 };
  styObj['backgroundColor'] = colorObj[props.stage]
  return (
    <>
      <Box style={styObj}>
      </Box>
    </>
  )
}

const FunnelChart = (props) => {
  let location = useLocation();
  const controllerRefFunnel = useRef();
  const controllerRefPastFunnel = useRef();
  const controllerRefStack = useRef()

  function useQuery() {
    return new URLSearchParams(location.search);
  }
  let GetAttributeValue = () => {
    const attribute = useQuery().get("attribute")
    if(attribute === '' || attribute === 'null' || !attribute || attribute === 'undefined') {
      return AttributeList[0]
    }
    return  attribute;
  }
  function GetDateFrom (){
    let date = sessionStorage.getItem("oldDate") || weeksOldDate 
    return new Date(date);
  }
  const [departments, setDepartments] = useState([]);
  const [account,setAccount] = useState(0)
  const [pastChart,setPastChart] = useState([])
  const [chartConfigs, setChartConfigs] = React.useState([]);
  const [pastChartAccount,setPastAccount] = React.useState([]);
  const [stageValue, setStageValue] = React.useState(GetAttributeValue())
  const [stageData, setStageData] = React.useState([]);
  const [stackData, setStackData] = React.useState([{}]);
  const [isLoadingPastFunnel,setIsLoadingPastFunnel] = React.useState(false);
  const [isLoading,setIsLoading] = React.useState(false);
  const [count,setCount] = React.useState(1);
  // const [stackDepartment,setStackDepartment] =React.useState([]);
  const [departmentValue, setDepartmentValue] = React.useState();
  const userDetails = useAuthState();
  let weeksOldDate = new Date();
  weeksOldDate.setDate(weeksOldDate.getDate() - 7);
  const [toDate, setToDate] = React.useState(new Date())
  const [selectedFrequency, setSelectedFrequency] = React.useState("Weekly")

  const [fromDate, setFromDate] = React.useState(GetDateFrom())
  
  console.log("Cookies for props",userDetails);
  const tenantId = userDetails.user.tenantId;
  const [allFlag,setAllFlag]=useState("All")
  const [iconUrl,setIconUrl]= useState('#');
  var funnelChartData = [];
  const funnelPastChartData = [];

  const Down = ()=>{
    return (
      <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.5 4L7.795 3.295L5 6.085L5 0H4L4 6.085L1.21 3.29L0.5 4L4.5 8L8.5 4Z" fill="#F37B6B"/>
</svg>

    )
  }
  const Up = ()=>{
    return (
      <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 4L1.205 4.705L4 1.915V8H5V1.915L7.79 4.71L8.5 4L4.5 0L0.5 4Z" fill="#21DA8C"/>
</svg>
    )
  }
  const updateFunnel = (stageValue) => {
    console.log("update funnel ??")
    let a = document?.getElementById('funnel-finder')?.getElementsByTagName("text");

    let length = document?.getElementById('funnel-finder')?.getElementsByTagName("text").length;

    let i = 0;
    while (i < length) {

      if (a[i].innerHTML === stageValue) {
        const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
        function simulateMouseClick(element) {
          mouseClickEvents.forEach(mouseEventType =>
            element.dispatchEvent(
              new MouseEvent(mouseEventType, {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1
              })
            )
          );
        }
        if (a)
          simulateMouseClick(a[i].nextSibling.nextSibling);
        break;
      }
      i++;
    }
  }
  const [array, setArray] = useState([])
  const [pastArray,setPastArray] = useState([])
  const [percentageResults,setPercentageResults] = useState([])
  
  
  // const changingColor=()=>{
  
  //   const TDs = document.getElementsByTagName('p12');
  //   for (var i=0; i<TDs.length; i++) {
  //           var temp = TDs[i];
  //          temp.className = "negativeNumber";
  //       }
  // }
  // useEffect(() => {
  //   changingColor()
  // }, [percentageResults])
  
  const [pastChartConfig, setPastChartConfig] = useState({})
  const calculatePercentage = (array,pastArray) =>{
    try{
      let percentageResult = []
      if(array.length === pastArray.length)
      for(let i=0;i<array.length;i++){
        if(array[i] === pastArray[i])  percentageResult.push(0)
        else if(pastArray[i]==0) percentageResult.push(array[i])
        else percentageResult.push(Math.round(((array[i]-pastArray[i])/pastArray[i])*100))
      }
      setPercentageResults(percentageResult);
      console.log("percentage result:",percentageResult);

    }
    catch(e){
      console.log("calculate percentage:",e);
    }
  }

  useEffect(()=>{
    calculatePercentage(array,pastArray)
  },[array,pastArray])
  
  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

  useEffect(async()=>{
    
    console.log("fromDate:",formatDate(fromDate));
    if (props.icpSelected !== null && props.segmentName !== '')
      await setPastChartData();
  },[fromDate,props.requestBody,props.segmentName,props.icpSelected])

  // const cloneFilter = (fitlerBody) => {
  //   let clonedBody = _.cloneDeep(fitlerBody)
  //   let pastObj = {Week: 7, Month: 30, Quarter: 90, Year: 365}
  //   for(let i = 0; i < clonedBody.length; i++){
      
  //     if(clonedBody[i]?.relation.toLowerCase() === 'in the past'){
  //       clonedBody[i].relation = 'after'

  //       let fromDate = pastObj[clonedBody[i]?.values[0]]
  //       let dateTime = (new Date(Date.now() - fromDate * 24 * 60 * 60 * 1000)).toISOString()
  //       clonedBody[i].values[0] = dateTime.substring(0, 11) + `00:00:00.000Z`;

  //     }
  //   }
  //   return clonedBody;
  // }

  const setPastChartData = async() =>{
    setIsLoadingPastFunnel(true)
    const body = {"date":formatDate(fromDate),"filters": props.requestBody}
    if (controllerRefPastFunnel.current) {
      controllerRefPastFunnel.current.abort();
    }
    const controller = new AbortController();
    controllerRefPastFunnel.current = controller;
    
   fetch(`${UNIFIED_URL}/analytics/funnel/past/funnelview/${tenantId}/${props.icpSelected}?filterName=${props.segmentName}`,{
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    signal:controllerRefPastFunnel.current?.signal,
    credentials: "include"
  }).then(response => response.json())
    .then(data => {
      setIsLoadingPastFunnel(false)
      setPastChart(data)
      const arrayTOPush=[]
      let sumOfTotalPastAccount = 0;
      const arrayTOPushPast = []
      // Object.values(data).forEach((stage) => {
      //   sumOfTotalAccount += parseInt(stage.count);
      // });
      for(let obj of data) sumOfTotalPastAccount += parseInt(obj.count)

      pastChartData.forEach((chartDataStage) => {
        let result = data.find((obj) =>  obj.accountStage === chartDataStage.label);
        let funnelChartDataObject = {
          label: "",
          value: "",
          color: "",
          tooltext: "",
          displayValue:"",
        };
        if (result !== undefined) {
          funnelChartDataObject.label = chartDataStage.label;
          funnelChartDataObject.value = 1;
           funnelChartDataObject.color = chartDataStage.color;
           funnelChartDataObject.displayValue = result.count;
           arrayTOPush.push(parseInt(result.count))
          // funnelChartDataObject.link = `${UNIFIED_FRONTEND_URL}/#/filter/${escape(
          //   JSON.stringify(dynamicFilterObject)
          // )}/analytics_dashboard/${escape(
          //   JSON.stringify(props.requestBody)
          // )}`;
          // funnelChartDataObject.link = `${UNIFIED_FRONTEND_URL}/#/filter/${escape(
          //   JSON.stringify(dynamicFilterObject)
          // )}/analytics_dashboard/${escape(
          //   JSON.stringify(props.requestBody)
          // )}/?icp=${props.icpSelected || ''}&segmentName=${props.segmentName}&tabVal=${props.tabVal}&attribute=${stageValue}`;
          funnelChartDataObject.tooltext = `<div style="padding: 8px">${chartDataStage.label}<br><b>${result.count
            }</b><br><br>% of Total Accounts<br><b>${Math.round(
              (parseInt(result.count) / sumOfTotalPastAccount) * 100
            )}</div>`;
            // '${chartDataStage.label}<br><b style="font-size: 14px">$result.count</b><br><br>% of Total Accounts<br> <b style="font-size: 14px">${Math.round((parseInt(result.count) / sumOfTotalAccount) * 100</b><br><br>Average Stage Velocity<br> <b style="font-size: 14px">${result.velocity}</b></div>';

          funnelPastChartData.push(funnelChartDataObject);
          
        } else {
          funnelChartDataObject.label = chartDataStage.label;
          funnelChartDataObject.value = 1;
          funnelChartDataObject.displayValue = "0";
          arrayTOPush.push(0)
          funnelChartDataObject.color = chartDataStage.color;
        
          funnelChartDataObject.tooltext = `<div style="position:relative;z-index:1500">${chartDataStage.label}: 0 <br> 0 % of Total Accounts </div>`;
          funnelPastChartData.push(funnelChartDataObject);
        }
      })
      setPastArray(arrayTOPush)
    
    const newChartConfigs = {
      type: "funnel",
      width: "16%",
      height: "363",
      dataFormat: "json",
      dataSource: {
        chart: {
          alignCaptionWithCanvas: 0,
          //subcaption: "Click on any plot to see the days spent by the accounts in 'Stage Ageing' chart",
          theme: "fusion",
          showlabels: 0,
          showValues: 1,
          showColors:1,
          showLegend: 0,
          showLabelsAtCenter: "1",
          minPlotHeightForValue: 20,
          enableSmartLabels: 1,
          legendPosition: "absolute",
          showpercentvalues: "0",
          streamlineddata: "0",
          plottooltext: "$label: <b>$dataValue</b>",
          showPlotBorder: "1",
          plotBorderThickness: 2,
          plotBorderColor: "#ffffff",
          plotBorderAlpha: 100,
          "usePlotGradientColor": "1",
          "toolTipBorderRadius": "10",
          "toolTipBgColor": "#FFFFFF",
          "toolTipBgAlpha": "100",
          "showToolTipShadow": "1",
          //  "toolTipBgColor": "#000000",
          // toolTipAlpha:100   
          useEllipsesWhenOverflow:"1",
          labelFontColor:"#FFFFFF",
          labelFontSize:"16"
        },
        
        data: funnelPastChartData,
        // linkeddata: funnelChartLinkedDataArray
      },
      // events: {
        

      //   "rendered": function (eventObj, dataObj) {
      //     console.log('in here after render');
      //     updateFunnel(stageValue)
      //   },

      //   "ready": function (eventObj, dataObj) {
      //     console.log('in here after ready');
      //     updateFunnel(stageValue)
      //   },

      //   "dataUpdated": function (eventObj, dataObj) {
      //     console.log('in here after ready');
      //     updateFunnel(stageValue)
      //   },
      // }

    };

    setPastChartConfig(newChartConfigs)
  }).catch((error)=>{
    if(error?.message === "The user aborted a request.") return;
  });
  
}
   
  const setChartData = () => {
    setIsLoading(true)
    if (controllerRefFunnel.current) {
      controllerRefFunnel.current.abort();
    }
    const controller = new AbortController();
    controllerRefFunnel.current = controller;
    fetch(
      `${UNIFIED_URL}/analytics/funnel/funnelview/${tenantId}/${props.icpSelected}?filterName=${props.segmentName}`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({"filters":props.requestBody}),
        signal:controllerRefFunnel.current?.signal,
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false)
        
        setStageData(data);
        
        let sumOfTotalAccount = 0;
        const arrayTOPush = []

        Object.values(data).forEach((stage) => {
          sumOfTotalAccount += parseInt(stage.count);
        });
       
        chartData.forEach((chartDataStage) => {
          var result = data.find((obj) => {
            return obj.accountStage === chartDataStage.label;
          });
          var funnelChartDataObject = {
            label: "",
            value: "",
            color: "",
            link: "",
            tooltext: "",
            displayValue:"",
          };

          var stageVelocityDataObject = {
            label: "",
            velocity: "",
          };

          let dynamicFilterObject = [
            {
              attribute: `b2AccountStage`,
              relation: "includes",
              values: [],
              type: "ACCOUNTS"
            },
          ];
          dynamicFilterObject[0].values.push(chartDataStage.label);
          if (
            !(Array.isArray(props.requestBody)  && !props.requestBody.length)
          ) {
            props.requestBody.forEach((reqBody) => {
              dynamicFilterObject.push(reqBody);
            });
          }
          if (result !== undefined) {
            funnelChartDataObject.label = chartDataStage.label;
            funnelChartDataObject.value = 1;
             funnelChartDataObject.color = chartDataStage.color;
             funnelChartDataObject.displayValue = result.count;
             arrayTOPush.push(parseInt(result.count))
            // funnelChartDataObject.link = `${UNIFIED_FRONTEND_URL}/#/filter/${escape(
            //   JSON.stringify(dynamicFilterObject)
            // )}/analytics_dashboard/${escape(
            //   JSON.stringify(props.requestBody)
            // )}`;
            funnelChartDataObject.link = `${UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(
              JSON.stringify(dynamicFilterObject)
            ))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(
              JSON.stringify(props.requestBody)
            ))}/?icp=${props.icpSelected || ''}&segmentName=${props.segmentName}&tabVal=${props.tabVal}&attribute=${stageValue}`;
            funnelChartDataObject.tooltext = `<div style="padding: 8px;">${chartDataStage.label}<br><b>${result.count
              }</b><br><br>% of Total Accounts<br><b>${Math.round(
                (parseInt(result.count) / sumOfTotalAccount) * 100
              )}</b><br><br> Average Stage Velocity<br><b>${result.velocity
              }</b> days</div>`;
              // '${chartDataStage.label}<br><b style="font-size: 14px">$result.count</b><br><br>% of Total Accounts<br> <b style="font-size: 14px">${Math.round((parseInt(result.count) / sumOfTotalAccount) * 100</b><br><br>Average Stage Velocity<br> <b style="font-size: 14px">${result.velocity}</b></div>';

            funnelChartData.push(funnelChartDataObject);
            
          } else {
            funnelChartDataObject.label = chartDataStage.label;
            funnelChartDataObject.value = 1;
            funnelChartDataObject.displayValue = "0";
            arrayTOPush.push(0)
            funnelChartDataObject.color = chartDataStage.color;
            funnelChartDataObject.link = `${UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(
              JSON.stringify(dynamicFilterObject)
            ))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(
              JSON.stringify(props.requestBody)
            ))}/?icp=${props.icpSelected || ''}&segmentName=${props.segmentName}&tabVal=${props.tabVal}&attribute=${stageValue}`;
            funnelChartDataObject.tooltext = `<div style="position:relative;z-index:1500">${chartDataStage.label}: 0 <br> 0 % of Total Accounts <br> Average Stage Velocity: <b>0</b> days</div>`;
            funnelChartData.push(funnelChartDataObject);

          }
         
          if(chartDataStage.label===stageValue)
          {
            setIconUrl(
              `${UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(
                JSON.stringify(dynamicFilterObject)
              ))}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(
                JSON.stringify(props.requestBody)
              ))}/?icp=${props.icpSelected || ''}&segmentName=${props.segmentName}&tabVal=${props.tabVal}&attribute=${stageValue}`
            )
          }

        });

        setArray(arrayTOPush)
        const newChartConfigs = {
          type: "funnel",
          width: "16%",
          height: "360",
          dataFormat: "json",
          dataSource: {
            chart: {
              alignCaptionWithCanvas: 0,
              //subcaption: "Click on any plot to see the days spent by the accounts in 'Stage Ageing' chart",
              theme: "fusion",
              showlabels: 0,
              showValues: 1,
              showColors:1,
              showLegend: 0,
              showLabelsAtCenter: "1",
              minPlotHeightForValue: 20,
              enableSmartLabels: 1,
              legendPosition: "absolute",
              showpercentvalues: "0",
              streamlineddata: "0",
              plottooltext: "$label: <b>$dataValue</b>",
              showPlotBorder: "1",
              plotBorderThickness: 2,
              plotBorderColor: "#ffffff",
              plotBorderAlpha: 100,
              "usePlotGradientColor": "1",
              "toolTipBorderRadius": "10",
              "toolTipBgColor": "#FFFFFF",
              "showToolTipShadow": "1",
              //  "toolTipBgColor": "#000000",
              // toolTipAlpha:100   
              useEllipsesWhenOverflow:"1",
              labelFontColor:"#FFFFFF",
              labelFontSize:"16"
            },
            
            data: funnelChartData,
            // linkeddata: funnelChartLinkedDataArray
          },
          events: {
            
    
            "rendered": function (eventObj, dataObj) {
              console.log('in here after render');
              updateFunnel(stageValue)
            },

            "ready": function (eventObj, dataObj) {
              console.log('in here after ready');
              updateFunnel(stageValue)
            },

            "dataUpdated": function (eventObj, dataObj) {
              console.log('in here after ready');
              updateFunnel(stageValue)
            },



            //    "legendItemClicked" : function(eventObj, dataObj) {

            //     if(stageValue !== eventObj.data.label){
            //       updateFunnel(stageValue);
            //     }
            //     let indexOfvel = eventObj.data.toolText?.indexOf('Average Stage Velocity: <b>') +27
            //     let div1 = document.getElementById('funnel-para-1-1')
            //     let div2 = document.getElementById('funnel-para-1-2')
            //     div1.innerHTML='Accounts: '
            //     div2.innerHTML=eventObj.data.value
            //     div2.href=eventObj.data.link
            //     console.log('eveobj is',eventObj);
            //     let div3 = document.getElementById('funnel-para-2-1')
            //     let div4 = document.getElementById('funnel-para-2-2')
            //     let div5 = document.getElementById('funnel-para-2-3')
            //     let velarr = eventObj.data.toolText?.slice(indexOfvel)?.split("</b>")

            //     // let velarr='Stage Velocity: '+eventObj.data.toolText.slice(indexOfvel).split("</b>")
            //     div3.innerHTML = 'Stage Velocity: '
            //     if(velarr != undefined){
            //     div4.innerHTML = velarr[0]!== undefined ?  velarr[0] : '0'
            //     if(parseInt(velarr[0])===1){
            //       div5.innerHTML = 'Day'
            //     }else{
            //       div5.innerHTML = 'Days'
            //     }
            //   }
            // }
            //   "legendItemClicked" : function(eventObj, dataObj) {
            //     console.log("evente",stageValue)
            //     console.log("evente",eventObj.data.label,stageValue,eventObj,dataObj);
            //     if(stageValue !== eventObj.data.label){
            //       updateFunnel(stageValue);
            //     }
            //     let indexOfvel = eventObj.data.toolText?.indexOf('Average Stage Velocity: <b>') +27
            //     let div1 = document.getElementById('funnel-para-1-1')
            //     let div2 = document.getElementById('funnel-para-1-2')
            //     div1.innerHTML='Accounts: '
            //     div2.innerHTML=eventObj.data.value
            //     div2.href=eventObj.data.link

            //     console.log('eveobj is',eventObj);
            //     let div3 = document.getElementById('funnel-para-2-1')
            //     let div4 = document.getElementById('funnel-para-2-2')
            //     let div5 = document.getElementById('funnel-para-2-3')
            //     let velarr = eventObj.data.toolText?.slice(indexOfvel)?.split("</b>")

            //     // let velarr='Stage Velocity: '+eventObj.data.toolText.slice(indexOfvel).split("</b>")
            //     div3.innerHTML = 'Stage Velocity: '
            //     if(velarr != undefined){
            //     div4.innerHTML = velarr[0]!== undefined ?  velarr[0] : '0'
            //     if(parseInt(velarr[0])===1){
            //       div5.innerHTML = 'Day'
            //     }else{
            //       div5.innerHTML = 'Days'
            //     }
            //   }
            // }


          }
        };
        // legendUpdate("untouch");   
        console.log("stageval", stageValue, stageData)
        setChartConfigs(newChartConfigs);
      }).catch((error)=>{
        if(error?.message === "The user aborted a request.") return;
      });   
    // console.log(chartConfigs)
  };
  
  const [stackLoading, setStackLoading] = useState(false)
  // 
  const getStackData = async () => {
    setStackLoading(true)

    if (controllerRefStack.current) {
      controllerRefStack.current.abort();
    }
    const controller = new AbortController();
    controllerRefStack.current = controller;

    let dynamicFilterbody = []
    if (stageValue !== 'All') {
      let b2AccStgFilterBody = [
        {
          "attrId": "jQzh72N_ZFcz4QiVmiR",
          "values": [],
          "type": "ACCOUNT",
          "attribute": `b2AccountStage`,
          "relation": "includes"
        }
      ]
      b2AccStgFilterBody[0].values.push(stageValue);
      dynamicFilterbody = b2AccStgFilterBody.concat(props.requestBody)
    }
    else {
      let b2AccStgFilterBody = [
        {
          "attrId": "jQzh72N_ZFcz4QiVmiR",
          "values": [
            "Unreached",
            "Unengaged",
            "Aware",
            "Engaged",
            "Potential Opportunity",
            "Active Opportunity"
          ],
          "type": "ACCOUNT",
          "attribute": `b2AccountStage`,
          "relation": "includes"
        },

      ]

      dynamicFilterbody = b2AccStgFilterBody.concat(props.requestBody)
    }

    // const clonedBody = cloneFilter(dynamicFilterbody)

    fetch(`${UNIFIED_URL}/analytics/contact/engagement/${tenantId}/${props.icpSelected}?filterName=${props.segmentName}`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({"filters":dynamicFilterbody}),
      signal: controllerRefStack.current?.signal,
      credentials: "include",
    }).then((response) => response.json(dynamicFilterbody))
      .then((res) => {
        // for(var i=0;i<res.length;i++){
        //   ;
        //   if(res[i].data.length>0) 
        //     res[i].data[0].color="#59D6E2,#4DAFB9" 
        //   if(res[i].data.length>1) if(res[i].data[1].hasOwnProperty("color"))
        //     res[i].data[1].color="#6E8DFF,#5166B0"
        //   if(res[i].data.length>2) if(res[i].data[2].hasOwnProperty("color")) 
        //     res[i].data[2].color="#FC6EFF,#C144C3"
        //   if(res[i].data.length>3) if(res[i].data[2].hasOwnProperty("color")) res[i].data[2].color="#FB63FE,#79ACE9"
        //   }
        setStackLoading(false)
        setStackData(res);
        console.log("stackChart", res);

      }).catch((error)=>{
        if(error?.message === "The user aborted a request.") return;
      })
  }
  var stringToHTML = function (str) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, 'text/html');
    return doc.body;
  };
  
  const legendUpdate = (e) => {
    setAllFlag(e)
    if (e != "All") {
      let flag = true;
      let pastFlag = true;
      console.log("evente", stageValue)
      console.log("evente", stageValue, e);
      
        // updateFunnel(e);
      // let indexOfvel = eventObj.data.toolText?.indexOf('Average Stage Velocity: <b>') +27
      // let div0=document.getElementById('funnel-para-1-3')
      for(let i=0;i<pastChart.length;i++){
        if(e==stageData[i].accountStage){
          pastFlag = false
          setPastAccount(pastChart[i].count)
        }
        
      }
      if(pastFlag) setPastAccount(0)
      for (let i = 0; i < stageData.length; i++) {

        if (e == stageData[i].accountStage) {
          flag = false;
          // div0.innerHTML = stringToHTML("<OpenInNewIcon />")
          // div1.innerHTML = 'Accounts: '
          // div2.innerHTML = stageData[i].count;
          setAccount(stageData[i].count)
          // div2.href=eventObj.data.link

          // console.log('eveobj is',eventObj);
          // let div3 = document.getElementById('funnel-para-2-1')
          // let div4 = document.getElementById('funnel-para-2-2')
          // let div5 = document.getElementById('funnel-para-2-3')
          // let velarr = stageData[i].velocity;
          // div3.style.color = "#A0A0A0"
          // div4.style.color = "#595959"
          // div5.style.color = "#A0A0A0"
          // let velarr='Stage Velocity: '+eventObj.data.toolText.slice(indexOfvel).split("</b>")

          // div3.innerHTML = 'Stage Velocity: '
          // if (velarr != undefined) {
          //   div4.innerHTML = velarr !== undefined ? velarr : '0'
          //   if (parseInt(velarr) > 1) {
          //     div5.innerHTML = 'Days'
          //   } else {
          //     div5.innerHTML = 'Day'
          //   }
          // }
        }

      }
      if (flag == true) {

        flag = false;
        // div0.innerHTML=stringToHTML("<OpenInNewIcon />")
        // div1.innerHTML = 'Accounts: '
        // div2.innerHTML = 0
        setAccount(0)
        // div2.href=eventObj.data.link

        // console.log('eveobj is',eventObj);
        // let div3 = document.getElementById('funnel-para-2-1')
        // let div4 = document.getElementById('funnel-para-2-2')
        // let div5 = document.getElementById('funnel-para-2-3')
        // let velarr = 0;
        // div3.style.color = "#A0A0A0"
        // div4.style.color = "#595959"
        // div5.style.color = "#A0A0A0"

        // // let velarr='Stage Velocity: '+eventObj.data.toolText.slice(indexOfvel).split("</b>")
        // div3.innerHTML = 'Stage Velocity: '
        // if (velarr != undefined) {
        //   div4.innerHTML = velarr[0] !== undefined ? velarr[0] : '0'

        //   div5.innerHTML = 'Day'
        // }
        
      // }
    } else {

      // let div0= document.getElementById('funnel-para-1-3')
      // let div1 = document.getElementById('funnel-para-1-1')
      // let div2 = document.getElementById('funnel-para-1-2')
      // if(div1){
      //   div1.style.color = "#A0A0A0"
      //   div1.innerHTML = ''
      //   div1.style.color = "#A0A0A0"
      // }
      // if(div2){
      //   div2.innerHTML = '';
      //   div2.style.color = "#595959"
      }
      // if(div0){
      //   div0.innerHTML = '';
      // }
      // div2.href=eventObj.data.link

      // console.log('eveobj is',eventObj);
      // let div3 = document.getElementById('funnel-para-2-1')
      // let div4 = document.getElementById('funnel-para-2-2')
      // let div5 = document.getElementById('funnel-para-2-3')

      
      
     
      // let velarr = stageData[i].velocity;

      // let velarr='Stage Velocity: '+eventObj.data.toolText.slice(indexOfvel).split("</b>")

      // if(div3){
      //   div3.innerHTML = ''
      //   div3.style.color = "#A0A0A0"
      // }
      // if(div4){
      //   div4.innerHTML = ''
      //   div4.style.color = "#595959"
      // }
      // if(div5){
      //   div5.innerHTML = ''
      //   div5.style.color = "#A0A0A0"
      // }
    }
  }
  const addsum =(arr)=>{
    let sudd=0;
    for(let i =0; i<arr.length;i++) sudd+=arr[i]
    return sudd;
  }
  React.useEffect(() => {
    console.log("stageValue", stageValue)
    setTimeout(() => {

      if (stageData.length > 0) {
          
        let dynamicFilterObject = [
          {
            attribute: `b2AccountStage`,
            relation: "includes",
            values: [],
            type: "ACCOUNT"
          },
        ];
        if(stageValue=="All"){
          dynamicFilterObject[0].values.push(...AttributeLists)
        }
        else{
        dynamicFilterObject[0].values.push(stageValue);
        }
        if (
          !(Array.isArray(props.requestBody)  && !props.requestBody.length)
        ) {
          props.requestBody.forEach((reqBody) => {
            dynamicFilterObject.push(reqBody);
          });
        }
            setIconUrl(
              `${UNIFIED_FRONTEND_URL}/#/filter/${encodeURIComponent(encodeURIComponent(
                JSON.stringify(dynamicFilterObject))
              )}/analytics_dashboard/${encodeURIComponent(encodeURIComponent(
                JSON.stringify(props.requestBody)
              ))}/?icp=${props.icpSelected || ''}&segmentName=${props.segmentName}&tabVal=${props.tabVal}&attribute=${stageValue}`
            )
          
        console.log("length", stageData.length, stageValue);
        legendUpdate(stageValue)

      }
    }, 500);
  }, [stageData, stageValue,pastChart,fromDate,count])

  useEffect(() => {
    if (props.icpSelected !== null && props.segmentName !== '') {
    setChartData();
    }
  }, [props.requestBody, props.icpSelected, props.segmentName, fromDate,count])
  
  React.useEffect(() => {

    if (props.icpSelected !== null && props.segmentName !== '') {
      
      getStackData();

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.requestBody, props.icpSelected, props.segmentName, stageValue,count]);


  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "rgb(236, 247, 254)",
      color: UItheme.palette.primary.dark,
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);





  return (
    <div className="container-align">
      {/* <Paper elevation={0} className="" style={{backgroundColor:"",paddingTop:"0px"}}> */}

        <div className="funnel-ageing-container">
          {Array.isArray(chartConfigs) && Array.isArray(pastChartConfig) && !chartConfigs.length && !pastChartConfig.length ? (
            <React.Fragment>Loading...</React.Fragment>
          ) : (

            <div style={{ display: "flex", flexDirection: "column", width: "100%",gap:"24px" }}>


              <div style={{ display: "flex", flexWrap:"wrap", paddingBottom: "23px",justifyContent: "space-between", zIndex:"1",fontFamily:'Nunito sans'}}>
                {AttributeList.map((e) => (
                  <>

                    <Box style={stageValue === e ? { marginBottom:"15px", paddingRight: "8px", paddingLeft: "8px", paddingTop: "5px", paddingBottom: '3px', background: 'none', borderRadius: '5px' } : {marginBottom:"15px", paddingLeft: "8px", paddingBottom: '3px', paddingTop: "5px", paddingRight: "8px",fontFamily:'Nunito sans' }}>
                      <Typography style={ stageValue==e?({ color:"#595959", fontSize:"14px",cursor: "pointer", display: 'flex',fontFamily:'Nunito sans' }):({ color:"#A0A0A0", fontSize:"14px",cursor: "pointer", display: 'flex',fontFamily:'Nunito sans' })}  onClick={() => {  setStageValue(e); legendUpdate(e) }} >{stageValue==e?<RadioButtonCheckedIcon stage={e} sx={{color:"#21DA8C"}} />:<RadioButtonUncheckedIcon stage={e} />}<span style={stageValue === e ? { fontWeight: '500', paddingLeft: '3px',fontFamily:'Nunito sans' } : { paddingLeft: '3px' , fontFamily:'Nunito sans' }}>{e}</span></Typography>

                    </Box>
                  </>
                ))}
              </div>
              <div style={{ display: "flex" ,gap:"2vw",justifyContent:"center" }}>
                <div
                  style={{ display: "flex", flexDirection: "column", width:'55vw',justifyContent: "space-around"}}>
                    <Paper elevation={10} style={{borderRadius:"16px",height: "474px"}}>
                  <div className="card-header" style={{ display: "flex", alignItems: "center", height: "56px",padding:'24px 24px 0px 24px',justifyContent:"space-between"}}><Typography
                    variant="h6"
                    component="h6"
                    style={{color:"#595959",width: "50%", fontWeight: 700, alignContent: "center", display: "flex",fontSize:"18px" ,alignItems: "center",gap:"5px", fontFamily:'Nunito Sans, sans-serif'
                  }}>
                    Account Funnel
                    <LightTooltip
                      style={{ lineHeight: "inherit" }}
                      title={`Click on any plot to see the details of Accounts in that Stage`}>
                      <InfoOutlinedIcon style={{"color":"#A0A0A0"}}/>
                    </LightTooltip>
                    
                  </Typography>
                  <Typography className="textfont" variant="Ag" component={'Ag'}>                  
                  Compare with 
                  </Typography>
                  <TimeSpanComponentForFunnel frequencySelected={selectedFrequency} setFromDate={setFromDate} fromDate={fromDate} input='1'/>

                  </div>
                  <div id='funnel-finder' style={{display: 'flex',flexDirection: 'row',justifyContent: 'space-evenly',alignItems:"center"}}>

                  {/* <div> */}
                  { isLoading ?
                    <Skeleton variant="rectangular" style={{ margin:"5%",borderRadius:"16px" }} width={800} height={296} />:(
                      <div style={{display: 'flex',flexDirection: 'row',justifyContent: 'space-evenly'}}>
                      <div className="funnel-align"> 
                      <ReactFC {...chartConfigs} />
                      
                         {/* {allFlag!=="All"&&<a className="algin" href={iconUrl}>({account} Accounts)</a>}
                           {allFlag==="All"&&<a className="algin" href={iconUrl}>({addsum(array)} Accounts)</a>} */}

                      </div>
                      
                      <div style={{display:"flex"}}>
                       
                        <div className="percentageList">
                          {percentageResults.map((element,index) => <p12 id={"percentage"+index} className={element<0&&'negativeNumber'} > {element<0?<Down/>:element>0?<Up/>:''} {Math.abs(element)}% </p12>
                          )}
                          </div>
                        
                          <div className="attributeList">
                      {AttributeLists.map((element,index) => (
                      <List id={'attribute'+index} className={percentageResults[index]<0&&'negative'}> {element} </List>
                      ))}
                      </div>
                      </div>
                   <div >
                      </div>
                      {isLoadingPastFunnel?
                      <CircularProgress style={{color:"rgba(33, 218, 140, 1)",position:"relative",top:"8rem",left:"4vw"}} />
                      :
                      <div className="funnel-align">
                      <ReactFC {...pastChartConfig} />
                      {/* {allFlag!=="All"&& <p className="algin">({pastChartAccount} Accounts)</p>}
                      {allFlag==="All"&& <p className="algin">({addsum(pastArray)} Accounts)</p>} */}
                      </div>
                      }
                      </div>
                    )

                  }

                    {/* <div style={{ paddingTop:"17%"}} >
                      <div id='funnel-para-1'>
                        <div id='funnel-para-1-1'></div>
                        <div style={{ display: 'flex',flexDirection: 'row'}}>
                        <a id='funnel-para-1-2' style={{ fontWeight: 600, paddingLeft: '3px',fontSize: '52px',lineHeight: '63px',color: "#595959"}}>
                        </a>
                        
                        {allFlag!=="All"&&<a id="funnel-para-1-2" href={iconUrl}><OpenInNewIcon style={{paddingTop:"15px", color:"#A0A0A0"}} /></a>}
                  
                        </div>
                      </div>
                      <Divider></Divider>
                      <div id='funnel-para-2' style={{ display: 'flex',flexDirection: 'column',paddingTop: '20px'}}>
                        <div id='funnel-para-2-1'></div> 
                        <span style={{display: 'flex',flexDirection: 'row'}}>
                        <div id='funnel-para-2-2' style={{ fontWeight: 600, paddingLeft: '3px', paddingRight: '3px',fontSize: '52px',lineHeight: '63px',color: "#595959"}}></div>
                        <div id='funnel-para-2-3' style={{paddingTop:"30px"}}></div>
                        </span>
                      </div>
                     </div> */}
                    <div >
                    </div>
                  </div>
                  </Paper>
                </div>

                <div
                  style={{ display: "flex", flexDirection: "column", width: "36vw"}}>
                    <Paper elevation={10} style={{borderRadius:"16px",height: "474px"}}>
                  <div>
                    <div className="card-header" style={{ display: "flex", width: "100%",  height: "56px" ,padding:'24px 24px 0px 24px'}}>
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{ color:"#595959",width: "50%", fontWeight: 700,  display: "flex",fontSize:"18px",gap:"5px", fontFamily:'Nunito Sans, sans-serif' }}>
                      Stage Aging 
                      <LightTooltip
                        style={{ lineHeight: "inherit" }}
                        title={`This Chart shows the age of the Accounts in a particular Stage as on today`}>
                        <InfoOutlinedIcon style={{"color":"#A0A0A0"}} />
                      </LightTooltip></Typography>
                    {/* <StageAgingSelector stageValue={stageValue} setStageValue={setStageValue} /> */}
                  
                  </div>
                  </div>

                  <ColumnChart  style={{width:"inherit"}} stageData={stageData} reqBody={props.requestBody} stageValue={stageValue} icp={props.icpSelected || ''} segmentName={props.segmentName} tabVal={props.tabVal} isLoading={isLoading}/>
                  
                  </Paper>
                </div>
              </div>
              <div style={{ display: 'flex' ,gap:"2vw",justifyContent:"center",paddingBottom: "30px" }}>
              <Paper elevation={10} style={{borderRadius:"16px",height: "474px"}}>
                    <div className="card-header" style={{ display: "flex",  justifyContent: "space-between", height: "56px",width:'52vw',padding:'24px 24px 0px 24px',  }}>
                      <Typography
                        variant="h6"
                        component="h6"
                        style={{ color:"#595959",width: "50%", fontWeight: 700, alignContent: "center", display: "flex",fontSize:"18px",alignItems: "center", fontFamily:'Nunito Sans, sans-serif'}}>
                        Contact Engagement
                        <LightTooltip
                          style={{ lineHeight: "inherit" }}
                          title={`This chart shows how well engaged the contacts belonging to a particular department and persona level combination are`}>
                          <InfoOutlinedIcon  style={{"color":"#A0A0A0"}}/>
                        </LightTooltip></Typography>
                       <div>
                      <StackDeptSelector stageValue={departmentValue} setStageValue={setDepartmentValue} setAlldepartment={setDepartments} />
                    </div>
          
                  </div>
                  {/* <ColumnChart stageData={stageData} reqBody={props.requestBody} stageValue={stageValue} icp={props.icpSelected || ''} segmentName={props.segmentName} tabVal={props.tabVal} /> */}
                  {/* <div style={{width:"100%"}}> */}
                  <StackChart style={{width:"inherit", paddingLeft: "20px"}} reqBody={props.requestBody} icp={props.icpSelected || ''} segmentName={props.segmentName} tabVal={props.tabVal} stackValue={departmentValue} stackData={stackData} AllDepartments={departments} stageValue={stageValue} isLoading={stackLoading} />
                  {/* </div> */}
                  </Paper>
                <div
                  style={{ display: "flex", flexDirection: "column" , width: "36vw"}}>
                <Paper elevation={10} style={{borderRadius:"16px",height: "474px",justifyContent:"center"}}>
                <div
                  style={{ display: "flex", flexDirection: "column" , width: "36vw"}}>
                  <div className="card-header" 
style={{ display: "flex", width: "100%",  height: "56px" ,padding:'24px 24px 0px 24px'}}                  >
                    <Typography
                    variant="h6"
                    component="h6"
                    style={{ color:"#595959",width: "50%", fontWeight: 700, alignContent: "center", display: "flex",fontSize:"18px",alignItems: "center",gap:"5px" , fontFamily:'Nunito Sans, sans-serif'}}
                    >
                    ICP vs Intent
                    <LightTooltip
                      style={{ lineHeight: "inherit" }}
                      title={`Click on any plot to see the details of Accounts in that Stage`}>
                      <InfoOutlinedIcon  style={{"color":"#A0A0A0"}}/>
                    </LightTooltip>
                  </Typography></div>
                  <div>
                  <HeatMapChart style={{width:"inherit"}} tabVal={props.tabVal} tenantId={tenantId} reqBody={props.requestBody} icpSelected={props.icpSelected || ''} segmentName={props.segmentName} stageValue={stageValue} count={count}/>
                  </div>
                </div>
                </Paper>   
                </div>
              </div>
            </div>

          )}
        </div>
      {/* </Paper > */}
    </div >
  );
};

export default FunnelChart;
