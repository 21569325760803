import axios from "axios";

async function GetTenantConfig (tenantId){
    try {
        const body ={
            "tenantId": tenantId,
            "tool": "CardsScheduler",
            "keys": [
              "SchedulerRunForCardCache"
            ]
          }
           
        const response = await axios.post(`${window._env_.TENANT_CONFIG}/tenant/get-tenant-config`,body,{withCredentials:true})
          return response.data[0]['configVal']
        
    } catch (error) {
        console.log("Get",error)
    }
}
export function DateDifference1(refreshTime,card='') {
    try {
        ;
        if(refreshTime === undefined || refreshTime === null) return ''
        const LastRefresh = new Date(refreshTime);
        const timeNow = new Date();
        const minute = diffMinutes(timeNow, LastRefresh);
        if (minute <= 1)
            return 'Updated Now';
        else if(minute <=59)
            return `${card} last updated ${minute} minutes ago`.toUpperCase();
        else if (minute > 59 && minute < 120)
            return `${card} last updated ${Math.round(minute / 60)} hour ago`.toUpperCase();
        else if (minute > 120)
            return `${card} last updated ${Math.round(minute / 60)} hours ago`.toUpperCase();
    }

    catch (error) {
        console.error("Error in DateDiffernce Function", error);
    }
}
export async function DateDifference(refreshTime,card='',localName='',tenantId="") {
    try {
        let configTime;
        var result = ''
        let time = refreshTime;
        if(card !== ''){
            configTime=await GetTenantConfig(tenantId)
            if(time === undefined || time === null) time = configTime
            if(new Date(time)<new Date(configTime)) 
            {
                time = configTime
                localStorage.setItem(localName,time)
                // localStorage.setItem(localName,time)
        }
        }
        if(time === undefined || time === null) result = ''

        const LastRefresh = new Date(time);
        const timeNow = new Date();
        const minute = diffMinutes(timeNow, LastRefresh);
        if (minute <= 1)
            result = 'UPDATED NOW';
        else if(minute<=59)
            result = `${card} last updated ${minute} minutes ago`;
        else if (minute > 59 && minute < 120)
            result = `${card} last updated ${Math.round(minute / 60)} hour ago`;
        else if (minute > 120)
            result = `${card} last updated ${Math.round(minute / 60)} hours ago`;

        return result.toUpperCase();
    }
    catch (error) {
        console.error("Error in DateDiffernce Function", error);
    }
}
function diffMinutes(dt2, dt1) 
 {

  var diff =(dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.ceil(diff));
  
 }
