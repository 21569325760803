import React, { useContext, useState } from "react";
import { FilterContext } from "../index";
import Dialog from "@material-ui/core/Dialog";
import {
  Divider,
  TextField,
  DialogContent,
  IconButton,
  Checkbox,
} from "@material-ui/core/";
import Tooltip from "@material-ui/core/Tooltip";
import AttributeList from "../attributelist";
import Footer from "../footer";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import "./index.css";
import { styled } from "@mui/system";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

const Dialog1 = styled(Dialog)(({ theme }) => ({
  [`.MuiPaper-rounded`]: {
    borderRadius: "18px",
  },
}));
function Modal({
  showModal,
  onModalClose,
  changeFilterName,
  filterName,
  showErrors,
  typoLabel,
  action,
  setActionType,
  chipClicked,
}) {
  const { applyFilters } = useContext(FilterContext);

  const [checked, setChecked] = useState(false);
  console.log("props inside modal is", chipClicked);

  return (
    <Dialog1
      maxWidth="784px"
      open={showModal}
      onClose={onModalClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick={true}
      style={{ width: "784px", height: "auto", left: "23vw" }}
      className="modal"
    >
      <DialogContent
        id="customized-dialog-title"
        draggable
        style={{ position: "relative", paddingTop: "16px" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: "720px",
            width: "672px",
            height: "fit-content",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
              marginBottom: "10px",
            }}
          >
            <TextField
              disabled={action === "EDIT" ? true : false}
              className="text"
              style={{
                width: "220px",
                height: "32px",
                borderRadius: 4,
                borderWidth: 1,
                backgroundColor:
                  action === "EDIT" ? "rgba(0, 0, 0, 0.12)" : "#FFFFFF",
                fontStyle: "Nunito Sans",
                color: action === "EDIT" ? "#6E6E6E" : "#8A8A8A",
              }}
              placeholder="Title"
              variant="outlined"
              value={filterName}
              id="filter-name-header"
              // error={showErrors && !filterName}
              // error={showErrors}
              onInput={(e) => {
                changeFilterName(e.target.value);
              }}
            />
            {action === "ADD" || chipClicked ? (
              <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                <Checkbox
                  size="small"
                  color="primary"
                  checked={checked}
                  onChange={(e) => {
                    setChecked(e.target.checked);
                  }}
                />
                <label
                  style={{
                    color: "#000000",
                    fontWeight: 400,
                    fontSize: "16px",
                  }}
                >
                  Save
                </label>
              </div>
            ) : (
              <Button
                style={{
                  width: 100,
                  padding: 10,
                  background: filterName === "" ? "#6E6E6E" : "#21DA8C",
                  color: "#FFFFFF",
                  height: "32px",
                  paddingTop: "3px",
                  top: "3px",
                }}
                onClick={() => {
                  if (filterName?.trim() === "") {
                    document.getElementById("filter-name-header").focus();
                    console.log("in edit and saving without name");
                  } else {
                    applyFilters("SAVE");
                    console.log("in edit and saving with name", filterName);
                  }
                  setActionType("");
                }}
              >
                SAVE
              </Button>
            )}
          </div>
          <Tooltip disableFocusListener title="Close">
            <IconButton
              aria-label="close"
              style={{ padding: "0", height: "20px", marginTop: "-10px" }}
            >
              <CloseSharpIcon
                style={{ color: "#A0A0A0" }}
                onClick={onModalClose}
              />
            </IconButton>
          </Tooltip>
        </div>
        <Divider />
        {typoLabel === "Account Hub" ? (
          <AttributeList />
        ) : typoLabel === "Contact Hub" ? (
          <AttributeList />
        ) : (
          <AttributeList />
        )}
        <Footer
          checked={checked}
          setChecked={setChecked}
          setActionType={setActionType}
          showModal={showModal}
          onModalClose={onModalClose}
        />
      </DialogContent>
    </Dialog1>
  );
}

export default React.memo(Modal);
