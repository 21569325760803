import React from "react";
import "./alert.css";
import AlertBarContainer from "./AlertBarContainer";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import { B2PROSPECT_URL, B2_CDPAPI } from "../Constant";
import { useAuthState } from "@bamboobox/b2logincheck";



export function Alert (props)  {
  const userData = useAuthState();
  const [loading,setLoading] = React.useState(true);
let tenantId = userData.user.tenantId;
  const [alertData, setAlertData] = React.useState([]);
  const getCountApi = async () => {
    var temporaryAlertData = [
      {
        heading: "Alerts",
        alerts: [
          {
            label: "Unassigned Web Leads",
            value: "",
          },
          // {
          //   label: "Accounts to be Enriched",
          //   value: "",
          // },{
          //   label: "Contacts to be Enriched",
          //   value: "",
          // },
        ],
      },
    ];
    await axios
      .get(
        `${B2PROSPECT_URL}/staging/${tenantId}/getCounts`,
        { withCredentials: true }
      )
      .then((webformFill) => {
        // console.log(`%c${JSON.stringify(webformFill.data)}`, 'color: red')
        temporaryAlertData[0].alerts[0].value = webformFill.data.size;
      });
    // await axios
    //   .get(
    //     `${B2PROSPECT_URL}/enrichment/${tenantId}/getCounts`,
    //     { withCredentials: true }
    //   )
    //   .then((enrichment) => {
    //     // console.log(`%c${JSON.stringify(enrichment.data)}`, 'color: blue')
    //     temporaryAlertData[0].alerts[1].value = enrichment.data.account;
    //     temporaryAlertData[0].alerts[2].value = enrichment.data.contact;
    //   });
      
    setLoading(false)
    setAlertData(temporaryAlertData);
  };

  React.useEffect(() => {
    getCountApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
    {loading? (<div style={{textAlign:"center",padding:"30px"}}><CircularProgress style={{color:'#21DA8C'}} size="20px"/></div>):
      ( 
      
      <div
      
        
        style={{
          backgroundColor: "rgba(0, 0, 0, 0)",
          display: "flex",
          justifyContent: "flex-end",
          marginLeft: "auto",
          marginRight: "0px",
          paddingBottom:"18px"
        }}
      >
        {Array.isArray(alertData) && !alertData ? (
          <React.Fragment>Loading...</React.Fragment>
        ) : (
          
          alertData.map((alertMapper, index) => {
            console.log("Alert", alertMapper);
            return <AlertBarContainer alertMapper={alertMapper} reqBody={props.requestBody} icpSelected={props.icpSelected} setIntentNewAccount={props.setIntentNewAccount} intentNewAccount={props.intentNewAccount} />;
          })
        )}
      </div>
      )
  }
    </div>
  );
};


