import React from "react";
//import { AttributeList } from "./AttributeList";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useAuthState } from "@bamboobox/b2logincheck";
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';

const useStyles = makeStyles((theme) => ({
  root:{
    color:"#8A8A8A"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  icon: {
    fill: '#21DA8C',
    fontSize: '28px'
  },
  selected: {
  borderRadius:"16px",
  background:"#d2f8d2 !important" 
},
select: {
    outline: "none",
    height: "42px !important",
    marginBottom:"2px !important"
},
    listItem:{
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    /* identical to box height */
    color: '#8A8A8A',
    fontFamily:'Nunito Sans'
    }
}));


const Attribute = (props) => {
  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;
  const [AttributeList, setAttributeList] = useState([]);
  const classes = useStyles();
  console.log(props.attributeValue)
  const [attribute, setAttribute] = React.useState(props.attributeValue.value);

  const handleChange = (event) => {
    let AttributeLabel = AttributeList.find(o => o.value === event.target.value);
    setAttribute(event.target.value)
    props.setAttribute(AttributeLabel)
  };

  useEffect(() => {
 
    fetch(`${window._env_.TENANT_CONFIG}/tenant/field/mapper/get/${tenantId}/data-quality`, { credentials: "include" })
        .then((response) => response.json())
        .then((mapper) => {
            let Transformed_Data = [];
            for (let element in mapper){
              Transformed_Data.push({ label: mapper[element].label,value: mapper[element].value, })
            }
            Transformed_Data.reverse();
            setAttributeList(Transformed_Data);
        })
        .catch((error) => {
            console.log("error", error)
        });  
  }, [])

  return (
    <FormControl variant="outlined" className={classes.formControl} className={classes.select}>
      {/* <InputLabel id="demo-simple-select-outlined-label"></InputLabel> */}
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
      <Typography  style={{ display:"flex", marginRight:"auto", height: "50%",fontWeight:"bold", color:"#595959", fontSize:"16px",width:"230px"}}>
      Total Accounts by
      </Typography>
      {/* <Select
      style={{height:40}}
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={attribute}
        onChange={handleChange}
        
      >
        {AttributeList.map((attrList) => (
          <MenuItem value={attrList.value}>{attrList.label}</MenuItem>
        ))
        }
      </Select> */}
      <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={attribute}
                className={classes.select}
                inputProps={{
                    classes: {
                        icon: classes.icon,
                        root: classes.root,
                    },
                }}
                IconComponent ={ExpandMoreOutlinedIcon}
                onChange={handleChange}
                defaultValue={0}
                style={{width:"220px",borderRadius:"4px", }}
                MenuProps={{
                  PaperProps: {
                      style: {
                        borderRadius: "16px"
                      }
                    },
                       getContentAnchorEl: () => null,
                       anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                        transformOrigin: { vertical: 'top', horizontal: 'center' },
                         }}
            >
                {AttributeList.map((attrList) => (
          <MenuItem className={classes.listItem} value={attrList.value} classes={{ selected: classes.selected }}>{attrList.label}</MenuItem>
        ))
        }
            </Select>
      </div>
    </FormControl>
  );
};

export default Attribute;
