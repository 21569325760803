import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// import SwipeableViews from '';
import { useEffect, useState,lazy,Suspense } from "react";
import { useAuthState } from "@bamboobox/b2logincheck";
import { Box, CircularProgress, Tooltip, withStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { TabPanel } from "@material-ui/lab";
// import { KeySegments } from  "./KeySegment";
import { styled } from "@mui/styles";
import "./createTab.css"
import { ButtonUnstyled } from "@mui/core";
import Badge from '@mui/material/Badge';
import Chip from "@material-ui/core/Chip";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { HashRouter as Router, Switch, Route, useLocation } from "react-router-dom";
const IntentSegment = lazy(async() =>{ 
  let obj = await import("./IntentSegment")
  return typeof obj.default === 'function' ? obj : obj.default
})

const AccountOverviewTab = lazy(async() =>{ 
  let obj = await import("./AccountOveriewTab")
  return typeof obj.default === 'function' ? obj : obj.default
});

const FunnelChart = lazy(async() =>{ 
  let obj = await import("./FunnelChart")
  return typeof obj.default === 'function' ? obj : obj.default
})
const TrendViewChart = lazy(async() =>{ 
  let obj = await import("./TrendViewChart")
  return typeof obj.default === 'function' ? obj : obj.default
} )

const Insights = lazy(async() =>{ 
  let obj = await import("./cards/Insights")
  return typeof obj.default === 'function' ? obj : obj.default
} )


const KeySegments =  lazy(async() =>{ 
  let obj = await import("./KeySegment")
  return typeof obj.default === 'function' ? obj : obj.default
}
)


// const location = useLocation();

const CreateTab = ({ tabVal, requestBody, icpSelected, tenantId, segmentName, ...props }) => {
  console.log('props in createtab', props);
  let location = useLocation();
  const userDetails = useAuthState();
  // const operations = userDetails?.permissions[0]?.operations
  //  const resourceName = userDetails?.permissions[0]?.resourceName
  const permissionsOfUser = userDetails?.permissions.map((obj)=>obj.resourceName.toLowerCase());
  console.log("permissions of user",permissionsOfUser);
  const resources=new Map([["dashboard_insights","INSIGHTS"],["dashboard_keysegments","KEY SEGMENT"],["dashboard_journeyanalytics","JOURNEY ANALYTICS"],["dashboard_intent","INTENT"],["dashboard_trends","TRENDS"],["dashboard_dataquality","DATA QUALITY"]]) 
  // const resourcesAllowed=permissionsOfUser.includes('dashboard_all')?resources:resources.filter((obj)=>(permissionsOfUser.includes(obj)));
  let resourcesAllowed=[]
  if(permissionsOfUser.includes('account_dashboard')){
    resourcesAllowed=Array.from(resources.values());
  }else{
    Array.from(resources.keys()).forEach(obj => {
      if(permissionsOfUser.includes(obj)){
        resourcesAllowed.push(resources.get(obj));
      }
    })
  }
  if(resourcesAllowed.length===0){
    resourcesAllowed=Array.from(resources.values());
  }
  
  function useQuery() {
    return new URLSearchParams(location.search);
  }
  let GetTabVal = () => {
    return useQuery().get("tabVal");
  }
  let GetIcpVal = ()=>{
    return useQuery().get("icp")
  }

  console.log("create icp",GetIcpVal())
  const handleDeleteAll = (chipToDelete) => {
    console.log("inside the handle delete", chipToDelete)
    props.setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
    if (chipToDelete.key === props.chipToFocus?.key) {
      props.setChipToFocus('');
    }
    props.setBody((body) => body.filter((e) => e.attrId !== chipToDelete.key));
    props.setAppliedFilterAttr((attrs) => attrs.filter(attr => attr.attrId !== chipToDelete.key));
  };

  const handleDelete = (chipToDelete) => () => {
    console.log("inside the handle delete", chipToDelete)
    props.setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
    if (chipToDelete.key === props.chipToFocus?.key) {
      props.setChipToFocus('');
    }
    props.setBody((body) => body.filter((e) => e.attrId !== chipToDelete.key));
    props.setAppliedFilterAttr((attrs) => attrs.filter(attr => attr.attrId !== chipToDelete.key));
  };

  const handleChipClick = (chipToEdit) => () => {
    props.setShowModal(true);
    props.setChipToFocus(chipToEdit);
    props.setAttributeData(props.appliedFilterAttr);
    props.setChipClicked(true);

  }

  const handleRemoveAll = () => {
    for (let i = 0; i < props.chipData.length; i++) {
      handleDeleteAll(props.chipData[i])
    }
  }
  const CustomTab = withStyles({
    root: {
      textTransform: "none",
      minWidth: "13.8vw",
      width: "13.8vw",
      lineHeight: "15.83px",
      fontFamily:'Nunito Sans !important'
    },

  })(Tab);
  //;
  console.log('tab val in createtab is', GetTabVal());

  const [value, setValue] = useState()

  function checkPermision(tenantId,permissions,resourceNames){
       for(var objIdx=0;objIdx<permissions.length;objIdx++){
       if(tenantId && permissions[objIdx]?.operations?.length>0 && resourceNames.includes(permissions[objIdx]?.resourceName)){
          props.setShowCreateTab(true)
          return true
        }
        }
        props.setShowCreateTab(false)
      return false
  }

  function handleChange(e, newValue) {
    props.setTabVal(newValue);
  }

  useEffect(() => {
    console.log("intentNewAccount tabVal CreateTab", tabVal)
    setValue(Number(tabVal) || 0)
  }, [tabVal])

  // const insightsTabFinder = () => {
  //   if(checkPermision(tenantId,permissions,resourceNames)){
  //       if(tabVal !== 0){
  //           return true;
  //       }else{
  //           return false;
  //       }
  //   }else{
  //       return true;
  //   }
  // }
  // useEffect(() => {
  //   props.setDoRefresh(1);
  // }, [tabVal,GetIcpVal()])

  const componentMap={
    'INSIGHTS':<Insights tabVal={(tabVal || 0)} segmentName={segmentName} tenantId={tenantId} softRefresh={props.softRefresh} requestBody={requestBody} icpSelected={GetIcpVal()} tenantConfigMapper={props.tenantConfigMapper} contTenantConfigMapper={props.contTenantConfigMapper} 
    downldRequiredFieldsAcc={props.downldRequiredFieldsAcc} downldRequiredFieldsCont={props.downldRequiredFieldsCont} setShowCreateTab={props.setCreateCardPopup} 
    showCreateTab={props.createCardPopup}  doRefresh={props.doRefresh} userDetails={userDetails}/>,
    'KEY SEGMENT':<KeySegments tabVal={(tabVal || 0)} segmentName={segmentName} tenantId={tenantId} requestBody={requestBody} icpSelected={icpSelected} />,
    'JOURNEY ANALYTICS': <FunnelChart tabVal={(tabVal || 0)} segmentName={segmentName} requestBody={requestBody} icpSelected={GetIcpVal()} />,
    'INTENT':<IntentSegment tabVal={(tabVal || 0)} segmentName={segmentName} tenantId={tenantId} requestBody={requestBody} icpSelected={GetIcpVal()} setIntentNewAccount={props.setIntentNewAccount} intentNewAccount={props.intentNewAccount} />,
    'TRENDS': <TrendViewChart mainTabVal={(tabVal || 0)} segmentName={segmentName} requestBody={requestBody} icpSelected={GetIcpVal()} />,
    'DATA QUALITY':<AccountOverviewTab tabVal={(tabVal || 0)} segmentName={segmentName} requestBody={requestBody} icpSelected={GetIcpVal()} />
  }

  

 
  
  return (

    <div style={{width: `97%`}}>
      <Box sx={{ maxWidth: "fit-content", borderBottomWidth: '0.2px', borderBottomColor: "rgb(211, 211, 211)" }}>
        {/* {checkPermision(tenantId,permissions,resourceNames)? */}
        {/* <Tabs
          style={{ borderBottom: '1px solid #D3D3D3', color: "#A0A0A0" }}
          indicatorColor="primary"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#21DA8C",
              height: "6px",
              borderRadius: "3px"
            }
          }}
          value={(Number(tabVal) || 0)}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
          variant="standard"
        >

          <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content", margin:"0px 10px" }} 
          label = "INSIGHTS"
          ></CustomTab>
          <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content", margin:"0px 10px" }} label="KEY SEGMENT" ></CustomTab>
          <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content", margin:"0px 10px"  }} label="JOURNEY ANALYTICS"></CustomTab>
          <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content", margin:"0px 10px"  }} label="INTENT"></CustomTab>
          <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content", margin:"0px 10px"  }} label="TRENDS"></CustomTab>
          <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content", margin:"0px 10px" }} label="DATA QUALITY" />

        </Tabs>
        {/* :
        <Tabs
        style={{ borderBottom: '1px solid #D3D3D3', color: "#A0A0A0" }}
        indicatorColor="primary"
        TabIndicatorProps={{
          style: {
            backgroundColor: "#21DA8C",
            height: "6px",
            borderRadius: "3px"
          }
        }}
        value={(Number(tabVal) || 0)}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        variant="standard"
      >
        <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content", margin:"0px 10px" }} label="KEY SEGMENT" ></CustomTab>
        <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content", margin:"0px 10px"  }} label="JOURNEY ANALYTICS"></CustomTab>
        <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content", margin:"0px 10px"  }} label="INTENT"></CustomTab>
        <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content", margin:"0px 10px"  }} label="TRENDS"></CustomTab>
        <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content", margin:"0px 10px" }} label="DATA QUALITY" />

      </Tabs>
        
          */}
          <Tabs
        style={{ borderBottom: '1px solid #D3D3D3', color: "#A0A0A0" }}
        indicatorColor="primary"
        TabIndicatorProps={{
          style: {
            backgroundColor: "#21DA8C",
            height: "6px",
            borderRadius: "3px"
          }
        }}
        value={(Number(tabVal) || 0)}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        variant="standard"
      >
        {/* <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content", margin:"0px 10px" }} label="KEY SEGMENT" ></CustomTab>
        <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content", margin:"0px 10px"  }} label="JOURNEY ANALYTICS"></CustomTab>
        <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content", margin:"0px 10px"  }} label="INTENT"></CustomTab>
        <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content", margin:"0px 10px"  }} label="TRENDS"></CustomTab>
        <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content", margin:"0px 10px" }} label="DATA QUALITY" /> */}
        {resourcesAllowed.map((label)=>{
          return (
            <CustomTab style={{ display: "inline", minWidth: "100px", width: "fit-content", margin:"0px 10px" }} label={label} ></CustomTab>
          )
        })}

      </Tabs>

        {
          tabVal!==0?
          <div style={{ display: "flex", flexWrap: "wrap", width: "inherit", alignItems: "center" }}>
          {props.chipData.length > 0}
          {props.chipData.map((data, index) => {
            return (
              <div key={data.key} style={{ margin: "0px" }}>
                <Tooltip title={data.value}>
                  <Chip
                    style={{ margin: "10px 10px 10px 0px", backgroundColor: "#8A8A8A", color: "#FFFFFF", fontSize: "small" }}
                    variant="outlined"
                    // backgroundColor="#8A8A8A"
                    size="medium"
                    label={data.label}
                    onDelete={handleDelete(data)}
                    onClick={handleChipClick(data)}
                    deleteIcon={<CloseRoundedIcon style={{ color: "#FFFFFF", fontSize: "medium", fontWeight: "700" }} />}
                  />
                </Tooltip>
              </div>
            );
          })}
          {props.chipData.length > 0 && <h4 style={{ fontSize: "12px", color: "#21DA8C", cursor: "pointer" }} onClick={() => handleRemoveAll()}><u>REMOVE ALL</u></h4>}
        </div>
          :
          ""
        }
        
      </Box>
      <br></br>
      {/* <Divider/> */}
      {/* {checkPermision(tenantId,permissions,resourceNames)? */}
      <Box sx={{ width: "100%" }}>
        {/* {value === 0 && <KeySegments tabVal={value}  segmentName={segmentName} tenantId={tenantId} requestBody={requestBody} icpSelected={icpSelected} />}
                {value === 1 && <FunnelChart tabVal={value}  segmentName={segmentName} requestBody={requestBody} icpSelected={icpSelected} />}
                {value === 2 && <h1>{' '}</h1>}
                {value === 3 && <TrendViewChart tabVal={value}  segmentName={segmentName} requestBody={requestBody} icpSelected={icpSelected}/>}
                {value === 4 && <AccountOverviewTab tabVal={value}  segmentName={segmentName} requestBody={requestBody} icpSelected={icpSelected}  />} */}
        {/* {(tabVal || 0) === 1 && 
        (
          <Suspense fallback={ <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"57vh"}}>
        <CircularProgress style={{color:"#21DA8C"}}/>
        </div>}>
        <KeySegments tabVal={(tabVal || 0)} segmentName={segmentName} tenantId={tenantId} requestBody={requestBody} icpSelected={icpSelected} />
        </Suspense>
        )
        }
          
        {(tabVal || 0) === 0 && 
                  <Suspense fallback={ <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"57vh"}}>
        <CircularProgress style={{color:"#21DA8C"}}/>
        </div>}>

        <Insights tabVal={(tabVal || 0)} segmentName={segmentName} tenantId={tenantId} softRefresh={props.softRefresh} requestBody={requestBody} icpSelected={GetIcpVal()} tenantConfigMapper={props.tenantConfigMapper} contTenantConfigMapper={props.contTenantConfigMapper} 
        downldRequiredFieldsAcc={props.downldRequiredFieldsAcc} downldRequiredFieldsCont={props.downldRequiredFieldsCont} setShowCreateTab={props.setCreateCardPopup} 
        showCreateTab={props.createCardPopup}  doRefresh={props.doRefresh} userDetails={userDetails}/>

        </Suspense>
        }
        {(tabVal || 0) === 2 && 
                  <Suspense fallback={ <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"57vh"}}>
        <CircularProgress style={{color:"#21DA8C"}}/>
        </div>}>
                  <FunnelChart tabVal={(tabVal || 0)} segmentName={segmentName} requestBody={requestBody} icpSelected={GetIcpVal()}
                  />
                  </Suspense>
                  }
        {(tabVal || 0) === 3 &&
                          <Suspense fallback={ <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"57vh"}}>
        <CircularProgress style={{color:"#21DA8C"}}/>
        </div>}>

        <IntentSegment tabVal={(tabVal || 0)} segmentName={segmentName} tenantId={tenantId} requestBody={requestBody} icpSelected={GetIcpVal()} setIntentNewAccount={props.setIntentNewAccount} intentNewAccount={props.intentNewAccount} />
</Suspense>
        }
        {(tabVal || 0) === 4 &&
                          <Suspense fallback={ <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"57vh"}}>
        <CircularProgress style={{color:"#21DA8C"}}/>
        </div>}>

        <TrendViewChart tabVal={(tabVal || 0)} segmentName={segmentName} requestBody={requestBody} icpSelected={GetIcpVal()} />
        </Suspense>
        }
        {(tabVal || 0) === 5 && 
                          <Suspense fallback={ <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"57vh"}}>
        <CircularProgress style={{color:"#21DA8C"}}/>
        </div>}>

        <AccountOverviewTab tabVal={(tabVal || 0)} segmentName={segmentName} requestBody={requestBody} icpSelected={GetIcpVal()} />
        </Suspense>
        } */}

        {
          resourcesAllowed.map((label,index)=>{
            return(
              (tabVal || 0) === index && 
                  <Suspense 
                  fallback= 
                    { <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"57vh"}}>
                      <CircularProgress style={{color:"#21DA8C"}}/>
                      </div>
                    }
                  >
                  {componentMap[label]}
                  </Suspense>
            )
          })
        }

      </Box>
      {/* <Box sx={{ width: "100%" }}>
      {(tabVal || 0) === 0 && <KeySegments tabVal={(tabVal || 0)} segmentName={segmentName} tenantId={tenantId} requestBody={requestBody} icpSelected={icpSelected} />}
      {(tabVal || 0) === 1 && <FunnelChart tabVal={(tabVal || 0)} segmentName={segmentName} requestBody={requestBody} icpSelected={GetIcpVal()} />}
      {(tabVal || 0) === 2 && <IntentSegment tabVal={(tabVal || 0)} segmentName={segmentName} tenantId={tenantId} requestBody={requestBody} icpSelected={GetIcpVal()} setIntentNewAccount={props.setIntentNewAccount} intentNewAccount={props.intentNewAccount} />}
      {(tabVal || 0) === 3 && <TrendViewChart tabVal={(tabVal || 0)} segmentName={segmentName} requestBody={requestBody} icpSelected={GetIcpVal()} />}
      {(tabVal || 0) === 4 && <AccountOverviewTab tabVal={(tabVal || 0)} segmentName={segmentName} requestBody={requestBody} icpSelected={GetIcpVal()} />}
    </Box> */}
   

    </div>
  )
}
export default CreateTab;
