
// import { Dialog, DialogTitle, List, DialogActions, styled, ThemeProvider } from "@material-ui/core";

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

// import CancelIcon from '@mui/icons-material/Cancel';
// import "./card.css"
// import "./card.css"
// import CheckIcon from '@mui/icons-material/Check';
// import OutlinedInput from "@mui/material/OutlinedInput";
import "./cardCreation.css"
import custheme from '@bamboobox/b2-theme' 
// import { Autocomplete, createMuiTheme, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';

// import { ThemeProvider } from '@mui/system';

// import { styled, ThemeProvider } from "@mui/styles";
import { Fragment } from "react"
import PropTypes from 'prop-types';
import {  IconButton, ListItem, ListItemText, Paper, Tooltip, Typography, Button, List, Alert, createMuiTheme, createTheme, ThemeProvider } from "@mui/material";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import b2theme from '@bamboobox/b2-theme'
import "@fontsource/nunito-sans";
import { ArrowBack, ArrowBackIos, Cancel, DragIndicator, RemoveRedEye, Share, Visibility, VisibilityOff } from "@material-ui/icons";
import "./settingpop.css";

import { useState } from "react";
import { Searchuser } from "./Searchuser";
import axios from "axios";
import { useEffect } from "react";
import { toast } from 'react-toastify';
// import { Searchuser } from "./Searchuser";
const SettingDialogBox = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogTitle-root':{
      padding:theme.spacing(0.8),
      paddingBottom:theme.spacing(2)
  
    },
    "& .MuiDialog-paperWidthSm":{
      minWidth: "840px!important",
      maxWidth:"840px!important",
      overflow:"hidden",
      minHeight:"482px",
      maxHeight:"482px",
      padding:"9px",
      borderRadius:"24px"
  },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(10),
      
    },
    '& .MuiDialogActions-root': {
      justifyContent:"space-between",
      padding: theme.spacing(10),
      paddingTop:theme.spacing(2),
      marginTop:theme.spacing(5)
    },
   
}));
const theme1 = createTheme({
  palette: {
     primary: {
        main: "#21DA8C" // This is an orange looking color
               },
     secondary: {
        main: "#E842EC" //Another orange-ish color
                }
           },
    fontFamily: 'Nunito Sans' // as an aside, highly recommend importing roboto font for Material UI projects! Looks really nice
});

const CusTypography = styled(Typography)(({theme})=>({
  fontFamily:"Nunito Sans",
}))



const BootstrapDialogTitle = (props) => {

    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle  sx={{ m: 0, p: 0 }} {...other}>
        {children}
        {onClose ? (
                  <Tooltip title={'Close'}>
  
          <IconButton
            aria-label="close"
            onClick={()=>props.onClose()}
            sx={{
              position: 'absolute',
              right: 24,
              top: 31
              ,
             
              color: (theme) => theme.palette.grey[500],
             
            }}
          >
            <Cancel />
          </IconButton>
          </Tooltip>
        ) : null}
      </DialogTitle>
    );
};
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

const ListTitle =()=>{
    return (
    <Fragment>
        <div className='listHeader'>
            <CusTypography variant="overline" >
                TITLE
            </CusTypography>
            
            <CusTypography variant="overline"  >
                ACTIONS
            </CusTypography>

        </div>
    </Fragment>
    )
}

const listItems = (params)=>{
    return(
        <Fragment>

        </Fragment>
    )
}

const LogicComponent = (params)=>{
  return(
    <DragDropContext
    onDragEnd={(param) => {
      const srcI = param.source.index;
      const desI = param.destination.index;
     

      params.columnsOrder.splice(
        desI,
        0,
        params.columnsOrder.splice(srcI, 1)[0]
      );
    }}
    >
    <Droppable droppableId="droppable-00">
      {(propvided, _) => (
    <div style={{display:'flex'}}
    ref={propvided.innerRef}
    {...propvided.droppableProps}
  >
    <div 
    style={{ maxHeight: "330px",minHeight: "330px", overflowY: "auto",overflowX:"hidden",width:"100%",padding:"0px 25px",marginTop:"0px",paddingRight:"44px"}}
  >
    <List
     
    >
      {params.columnsOrder.map((value, key) => {
        const labelId = key;
        // console.log("odering12",params.columnsOrder);
        const labelValue = value.label;
        let caption = "Created by "+value.createdBy;

        return (

          <Draggable
            key={key}
            draggableId={"draggable-" + key}
            index={key}
          >
            {(propvided, snapshot) => (

              <ListItem
                key={key}
                divider
                className={value['hide']&&'hidenColor'}
                style={{padding:"0px 10px",margin:"0px 18px"}}
                ref={propvided.innerRef}
                {...propvided.draggableProps}
                {...propvided.dragHandleProps}
                
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="comments"
                  ></IconButton>
                }
                disablePadding
              >

                {/* <Checkbox          
                    key={key}
                    checked={value.visible}
                    name={labelValue}
                    onChange={(e) => {
                     checkFun(e)
                      sortColumns();
                    }}
                  /> */}

                <DragIndicator/>
                <IconButton sx={{margin:"auto 9px"}}
              
                onClick={()=>params.handleVisibility(value['cardId'])}>
                {value['hide']?<VisibilityOff />:<RemoveRedEye/>}
                </IconButton>
                <ListItemText
                  sx={{padding:"8px"}}
                  id={labelId}
                  secondary={
                    <Typography variant="caption" paragraph={true} className={value['hide']?'hidenColor':'color'}  >
                      {caption}
                    </Typography>
                  }
                  primary={
                  <Typography variant="body1" noWrap>
                    {labelValue}
                  </Typography>  
                  }
                />
                {value.sharedType === "private"?(
                <IconButton sx={{margin:"auto 10px"}} onClick={()=>{params.setcardtitle(labelValue); params.setCardId(value.cardId); params.setSharingPage(true)}}>

                <Share/>
                </IconButton>):(<CusTypography variant='caption' sx={{color:"rgba(0, 0, 0, 0.38)",minWidth:"60px"}} > No Access </CusTypography>)}

                
                    
               {/* <DragIndicatorIcon /> */}
               
              </ListItem>
            )}
          </Draggable>
        );
      })}
      {propvided.placeholder}
    </List>
  </div>
  </div>
      )}
      </Droppable>
    </DragDropContext>
  )
}






export const SettingPop=(params)=>{
  const [cardDetails,setCardDetails] = useState([]);

  const [sharingPage,setSharingPage] = useState(params.sharingPage||false);
  const [sharingTitle,setSharingTitle] = useState(params.sharingTitle||"");
  const handleCloseSetting = ()=>{
    console.log("inside handleCloseSetting")
    params.setSettingpopUp(false);
}
  const [cardId,setCardId] = useState(params.cardId||null);
  const handleVisibility = (cardId)=>{
    try {
      console.log("hello handleVisibility")
      setCardDetails(cardDetails.map(e=>{
        if(e.cardId == cardId){
          e.hide = !e.hide
         }
         return e
      }))
    } catch (error) {
      console.log("handleVisibility:",error);
    }
  }
  const fetchCardDetails=()=>{
    try {
      console.log("fetching")
      axios.get(`${window._env_.CARD_SERVICE}/card/listCards/${params.tenantId}`,{withCredentials:true}).then(e=>
      {
        setCardDetails(e.data.data)
      }
      )
    } catch (error) {
      console.error("fetchCardDetails-->",error)
    }
  }
  const HandleSave = ()=>{
    console.log("HandleSave:",cardDetails)
    const cardLen = cardDetails.length
    const body = cardDetails.map((e,index)=>(  {
      cardUuid :e.cardId,
      hidden :e?.hide || false,
      displayorder : cardLen -index,
      notified: true
      }))

      axios.post(`${window._env_.CARD_SERVICE}/action/set/order/hide/${params.tenantId}`,body,{withCredentials:true})
          .then(e=>{
            
            console.log("save call",e);
            if(e.data.code === 200){
            toast.success(<Alert severity="success">{'Order and Visibility set successfully!!'}</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
            params.handleSaveSetting();
          }else{
            toast.error(<Alert severity="error">Oops there was problem. Try again in some time</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
          }
        });
      // params.handleSetting()
  }

  useEffect(()=>{
    
    if(params.settingpopUp )  fetchCardDetails();
  },[params.settingpopUp])
    return(

        <ThemeProvider theme={theme1}>
            <SettingDialogBox aria-labelledby="customized-dialog-title" open={params.settingpopUp} >

            <BootstrapDialogTitle id="customized-dialog-title" className="dialogTitle"  onClose={()=>{setSharingPage(false); params.handleSetting();  }}>
              {(params?.sharingPage||sharingPage)?<Fragment>
              <div style={{display:"flex", "lineHeight":"15px",paddingLeft:"20px"}} >
                <IconButton onClick={()=>setSharingPage(false)}>
                { !params?.backBotton &&  <ArrowBackIos />}
                </IconButton>
                    <CusTypography variant='h6' sx={{fontWeight: 'bold',fontFamily:"Nunito Sans",}}>
                    Sharing {sharingTitle}
                </CusTypography >
                
                </div>
                <Searchuser sharingTitle={sharingTitle} cardId={cardId}  tenantId={params.tenantId} handleSetting={params.handleSetting} setSharingPage={setSharingPage} sharingPage={params.sharingPage||false}/>
              </Fragment>:
              <Fragment>
                <div style={{"lineHeight":"15px",paddingLeft:"24px"}} >
                    <CusTypography variant='h6' sx={{fontWeight: 'bold',fontFamily:"Nunito Sans",}}>
                Customise your Favorites Dashboard
                </CusTypography >
                <CusTypography variant="caption">
                Drag Items up or down to sort them, or switch visibilty
                </CusTypography>
                </div>
                <ListTitle/>
               
                    <LogicComponent  columnsOrder={cardDetails} setcardtitle={setSharingTitle} setCardId = {setCardId}  setSharingPage={setSharingPage}  handleVisibility={handleVisibility} />
                    
                    <div style={{display:"flex",flexDirection:"row-reverse",padding:"10px 16px 17px"}}>
                  <Button  sx={{borderRadius:"18px"}} variant="contained" onClick={()=>HandleSave()} >
                    save
                  </Button > 
                    <Button onClick={()=>{setSharingPage(false);  params.handleSetting();}} >cancel</Button>
          </div>
              </Fragment>
              }
            </BootstrapDialogTitle>
            
          {/* 
          <Button >cancel</Button>
          <Button  autoFocus variant="contained" >
            save
          </Button> 
          */}
          
            </SettingDialogBox>
        </ThemeProvider >

    )
}

