import moment from "moment"

function changeFromToDate(fromDateProcessed, toDateProcessed, frequency) {
    console.log(" changeFromToDate ", fromDateProcessed, toDateProcessed, frequency);
    moment.locale('en', {
      week: {
        dow : 1, // Monday is the first day of the week.
      }
    });
    moment.weekdays(true)
    console.log(" changeFromToDate to moment", moment(new Date(fromDateProcessed)).toISOString(), moment(new Date(toDateProcessed)).toISOString(), frequency)
    if (frequency === "Weekly"){
      
      fromDateProcessed = moment(new Date(fromDateProcessed)).weekday(0);
      toDateProcessed = moment(new Date(toDateProcessed)).weekday(6);
  
      fromDateProcessed = fromDateProcessed.toISOString();
    toDateProcessed = toDateProcessed.toISOString();
      
    } else if (frequency === "Monthly"){
      fromDateProcessed = moment(new Date(fromDateProcessed)).startOf('month')
      toDateProcessed = moment(new Date(toDateProcessed)).endOf('month')
  
      fromDateProcessed = fromDateProcessed.add(1, 'days').toISOString();
      toDateProcessed = toDateProcessed.toISOString();
      
    } else if (frequency === "Quarterly"){
      fromDateProcessed = moment(new Date(fromDateProcessed)).startOf('quarter')
      toDateProcessed = moment(new Date(toDateProcessed)).endOf('quarter')
  
      fromDateProcessed = fromDateProcessed.add(1, 'days').toISOString();
    toDateProcessed = toDateProcessed.toISOString();
      
    } else if (frequency === "Yearly"){
      fromDateProcessed = moment(new Date(fromDateProcessed)).startOf('year')
      toDateProcessed = moment(new Date(toDateProcessed)).endOf('year')
  
      fromDateProcessed = fromDateProcessed.add(1, 'days').toISOString();
    toDateProcessed = toDateProcessed.toISOString();
      
    }
    
    console.log("checking frequency", frequency)
    console.log("checking start date",  fromDateProcessed)
    console.log("checking end date", toDateProcessed)

    console.log("changeFromToDate after processing", fromDateProcessed, toDateProcessed);

    return [fromDateProcessed, toDateProcessed];
  
  }


export {changeFromToDate};