import React from 'react';
import './AlertBar.css';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import {  B2ENRICHMENT_URL, B2FRONTEND_PROSPECT_URL, B2_WEBFORMFILL_FRONTEND, LOGIN_URL } from "../Constant";

const AlertBar = (props) => {

    // var accWithoutContUrl = `${B2FRONTEND_PROSPECT_URL}/#/filter/%5B%7B%22attribute%22%3A%22Number%20of%20Contacts%22%2C%22relation%22%3A%22less%20than%28%3C%29%22%2C%22values%22%3A%5B%221%22%5D%7D%5D`
    // var accWithLessThanTwoContUrl = `${B2FRONTEND_PROSPECT_URL}/#/filter/%5B%7B%22attribute%22%3A%22Number%20of%20Contacts%22%2C%22relation%22%3A%22less%20than%28%3C%29%22%2C%22values%22%3A%5B%222%22%5D%7D%5D`
    // var contWithoutPhnUrl = `${B2FRONTEND_PROSPECT_URL}/#/filter/%5B%7B%22attribute%22%3A%22Mobile%22%2C%22relation%22%3A%22Is%20Empty%22%2C%22values%22%3A%5B%22%22%5D%7D%5D`

    const [accountWithoutContact, setAccountWithoutContact] = React.useState('')
    const [accountWithLessThan2Contact, setAccountWithLessThan2Contact] = React.useState('')
    const [contactWithoutPhone, setContactWithoutPhone] = React.useState('')
    const [filterBody,setFilterBody]=React.useState(props.filterBody)
    
    const createUrls = () => {
        var filterBodyForAccountWithoutContact = [{
            "attribute": "Number of Contacts",
            "relation": "less than(<)",
            "values": ['1']
        }]

        var filterBodyForAccountWithLessThan2Contact = [{
            "attribute": "Number of Contacts",
            "relation": "less than(<)",
            "values": ["2"]
        }]

        var filterBodyForContactWithoutPhone = [{
            "attribute": "Contact Mobile",
            "relation": "Is Empty",
            "values": []
        }]

        console.log("filter Body", props.reqBody, Array.isArray(props?.reqBody), props?.reqBody.length, props?.reqBody.length!==0)

        // if (!(Array.isArray(props?.reqBody) && !props?.reqBody.length  && !props?.reqBody.length===0)) {
        if (Array.isArray(props?.reqBody) && props?.reqBody.length  && props?.reqBody.length!==0) {
            let temp = props.reqBody;
            console.log("filter Body inside if condition",temp)
            for (let i=0; i < props.reqBody.length; i++) {
                let reqBody = props.reqBody[i]
                filterBodyForAccountWithoutContact.push(reqBody)
                filterBodyForAccountWithLessThan2Contact.push(reqBody)
                filterBodyForContactWithoutPhone.push(reqBody)
            }
        }

        setAccountWithoutContact(`${B2FRONTEND_PROSPECT_URL}/#/filter/${encodeURIComponent(encodeURIComponent(JSON.stringify(filterBodyForAccountWithoutContact)))}/account_management/${encodeURIComponent(encodeURIComponent(JSON.stringify(props.reqBody)))}`)
        setAccountWithLessThan2Contact(`${B2FRONTEND_PROSPECT_URL}/#/filter/${encodeURIComponent(encodeURIComponent(JSON.stringify(filterBodyForAccountWithLessThan2Contact)))}/account_management/${encodeURIComponent(encodeURIComponent(JSON.stringify(props.reqBody)))}`)
        setContactWithoutPhone(`${B2FRONTEND_PROSPECT_URL}/#/filter/${encodeURIComponent(encodeURIComponent(JSON.stringify(filterBodyForContactWithoutPhone)))}/account_management/${encodeURIComponent(encodeURIComponent(JSON.stringify(props.reqBody)))}`)
    }

    React.useEffect(() => {
        createUrls()
    }, [props.reqBody])
    
    var renderAlertBar = () => {
        return (
            <div className='alert-bar'>
                <div className='alert-bar-content'>
                    <div className='alert-text'>
                        {props.alert.label}<span style={{ color: "rgb(64, 64, 64)" }}> ({props.alert.value})</span>
                    </div>
                    <ArrowForwardIosRoundedIcon style={{ fontSize: '15px',  color: "#C4C4C4" }} />
                </div>
            </div>)
    }
    if (props.alert.label === "Accounts to be Enriched") {
        return (
            <a href={`${B2ENRICHMENT_URL}/#/account?filter=${encodeURIComponent(JSON.stringify(props.reqBody))}&icp=${props.icpSelected}`} >
                {renderAlertBar()}
            </a>
        )
    } else if (props.alert.label === "Contacts to be Enriched") {
        return (
            <a href={`${B2ENRICHMENT_URL}/#/contact?filter=${encodeURIComponent(JSON.stringify(props.reqBody))}&icp=${props.icpSelected}`} >
                {renderAlertBar()}
            </a>
        )
    }  else if (props.alert.label === "Unassigned Web Leads") {
        return (
            <a href={`${B2_WEBFORMFILL_FRONTEND}/#/?filter=${encodeURIComponent(JSON.stringify(props.reqBody))}&icp=${props.icpSelected}`}>
                {renderAlertBar()}
            </a>
        )
    } else {
        return (
            renderAlertBar()
        )
    }
}

export default AlertBar
