import React from 'react';
import './AlertBarContainer.css';
import AlertBar from "./AlertBar";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';

const AlertBarContainer = (props) => {
    return (
        <div className='alert-container'>
            
            <div className='sub-alert-container'>
                <div style={{ paddingTop: '10px' , }}>
                    {props.alertMapper.alerts.map((alert) => {
                        return <AlertBar alert={alert} reqBody={props.reqBody} icpSelected={props.icpSelected} setIntentNewAccount={props.setIntentNewAccount} intentNewAccount={props.intentNewAccount}/>
                    })}
                </div>
        
            </div>
        </div>
    )
}

export default AlertBarContainer
